import './ScheduleGrowPopup.scss';
import React from 'react';

import { useSelector, useDispatch} from 'react-redux'

import Walkthrough from '../../../components/Walkthrough';
import ScheduleGrow_ReviewNewGrowWalkthroughStep from './ScheduleGrow_ReviewNewGrowWalkthroughStep';
import ScheduleGrow_SelectGrowTimingWalkthroughStep from './ScheduleGrow_SelectGrowTimingWalkthroughStep';
import ScheduleGrow_SelectGrowInfoWalkthroughStep from './ScheduleGrow_SelectGrowInfoWalkthroughStep';
import ScheduleGrow_SelectGrowPlanWalkthroughStep from './ScheduleGrow_SelectGrowPlanWalkthroughStep';
  

const ScheduleGrowPopup = ({selectedFacility, growCompleteCallback, growGroupCompleteCallback, closeCallback}) => {
    const closePopup = (force) =>  {
        if (closeCallback !== undefined && (force || currentTimelineStep === "grow_plan"))  {
            closeCallback()
        }
    }

    const dispatch = useDispatch()

    const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("grow_plan")
    const timelineSteps = {
        select_grow_plan: { render: (<span>Grow Plan</span>) },
        select_grow_info: { render: (<span>Info</span>) },
        select_grow_timing: { render: (<span>Timing</span>) },
        review_grow: { render: (<span>Review</span>) },
    }
    
    const [selectedGrowPlan, SetSelectedGrowPlan] = React.useState(null)
    const growPlanConfirmed = ({growPlan}) => {
        SetSelectedGrowPlan(growPlan)
    }

    const [selectedBladeTypeId, SetSelectedBladeTypeId] = React.useState(1)
    const [selectedNumberOfUnits, SetSelectedNumberOfUnits] = React.useState(0)
    const [selectedUnitSelectionType, SetSelectedUnitSelectionType] = React.useState("by_zone")
    const growInfoConfirmed = ({bladeTypeId, numberOfUnits, unitSelectionType}) => {
        SetSelectedBladeTypeId(bladeTypeId)
        SetSelectedNumberOfUnits(numberOfUnits)
        SetSelectedUnitSelectionType(unitSelectionType)
    }

    const [selectedTimingType, SetSelectedTimingType] = React.useState("start_date")
    const [selectedCalendarDate, SetSelectedCalendarDate] = React.useState(null)
    const growTimingConfirmed = ({timingType, selectedCalendarDate}) =>   {
        SetSelectedTimingType(timingType)
        SetSelectedCalendarDate(selectedCalendarDate)
    }




    const [currentStepIndex, SetCurrentStepIndex] = React.useState(1)
    
    //Animation
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["select_grow_plan", null])
    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }



    React.useEffect(() => {
        const stepKey = currentWalkthroughStep[0]
        switch (stepKey)  {
            case "select_grow_plan":
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
            case "select_grow_info":
                if (currentStepIndex !== 2) {
                    SetCurrentStepIndex(2)
                }
                break
            case "select_grow_timing":
                if (currentStepIndex !== 3) {
                    SetCurrentStepIndex(3)
                }
                break
            case "review_grow":
                if (currentStepIndex !== 4) {
                    SetCurrentStepIndex(4)
                }
                break
            default:
                if (currentStepIndex !== 1) {
                    SetCurrentStepIndex(1)
                }
                break
        }
    }, [currentWalkthroughStep, currentStepIndex])


    
    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]
        switch (stepKey)    {
            case "select_grow_plan":
                return (<ScheduleGrow_SelectGrowPlanWalkthroughStep
                    initialSelectedGrowPlan={selectedGrowPlan}
                    growPlanConfirmedCallback={growPlanConfirmed} 
                    cancelScheduelGrowCallback={closeCallback}
                    transitionToPreviousStep={transitionToPreviousStep} 
                    transitionToNextStep={transitionToNextStep} />) 
            case "select_grow_info":
                return (<ScheduleGrow_SelectGrowInfoWalkthroughStep
                    selectedGrowPlan={selectedGrowPlan}
                    initialSelectedBladeTypeId={selectedBladeTypeId}
                    initialUnitSelectionType={selectedUnitSelectionType}
                    initialSelectedUnits={selectedNumberOfUnits}
                    growInfoConfirmedCallback={growInfoConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep} 
                    transitionToNextStep={transitionToNextStep} />) 
            case "select_grow_timing":
                return (<ScheduleGrow_SelectGrowTimingWalkthroughStep
                    selectedGrowPlan={selectedGrowPlan}
                    initialSelectedTimingType={selectedTimingType}
                    initialSelectedCalendarDate={selectedCalendarDate}
                    growTimingConfirmedCallback={growTimingConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep} 
                    transitionToNextStep={transitionToNextStep} />) 
            case "review_grow":
                return (<ScheduleGrow_ReviewNewGrowWalkthroughStep
                    selectedFacility={selectedFacility}
                    selectedGrowPlan={selectedGrowPlan}
                    selectedBladeTypeId={selectedBladeTypeId}
                    selectedNumberOfUnits={selectedNumberOfUnits}
                    selectedTimingType={selectedTimingType}
                    selectedCalendarDate={selectedCalendarDate}
                    transitionToPreviousStep={transitionToPreviousStep} 
                    completeWalkthrough={closeCallback} />) 
            default:
                return <></>
        }
    }

    let reviewInProgressDisplayProps = {style:{}}
    if (currentTimelineStep !== "grow_plan")    {
        reviewInProgressDisplayProps.style.borderBottomWidth = 1
        reviewInProgressDisplayProps.style.paddingBottom = 8
    }


    

    
    return (
        <Walkthrough 
            title="Plan a Grow"
            size="full"
            closeCallback={closePopup}
            hideMainSeparators={false}
            timelineSteps={timelineSteps} 
            timelineStep={currentWalkthroughStep[0]}
            initialWalkthroughStep={["select_grow_plan", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}
        />
    )
} 

export default ScheduleGrowPopup