import '../GrowAnalyticsPage.scss';
import _ from 'underscore';

import React from 'react'
import { useMediaQuery } from 'react-responsive';
import useMeasure from '../../../useMeasure';
import { selectAllChartAxisTypes, selectAllDataRecordingTimePeriodTypes } from '../../../redux/AppInfo';

import { useDispatch, useSelector } from 'react-redux';
import { makeFlatTheme } from '@arction/lcjs-themes';
import { AutoCursorModes, AxisTickStrategies, ColorHEX, ColorRGBA, emptyLine, FontSettings, SolidFill, SolidLine, Themes, UIElementBuilders } from '@arction/lcjs';
import { FormatTime, RoundedTimeToNearestLastDay, RoundedTimeToNearestLastHour, RoundedTimeToNearestLastMinute, RoundedTimeToNearestNextDay, RoundedTimeToNearestNextHour, RoundedTimeToNearestNextMinute, RoundToNearest } from '../../../helpers';
import { createLightningChart } from '../../../LC';
import { GrowAnalyticsPage_ActiveTimeTooltip, GrowAnalyticsPage_ChartPointCrosshair, GrowAnalyticsPage_DataLegendTooltip, GrowAnalyticsPage_OverviewChartMainChartSubselection } from './GrowAnalyticsPage_ChartingOverlayComponents';
import Button from '../../../components/Button';
import { InitializeDataRecordingTimePeriodTypes, MaintainGrowAnalyticsData, selectGrowDurationsForGrowIds } from '../../../redux/entities/Grow';



const mainChartTheme = makeFlatTheme({
  isDark: false,
  fontFamily: 'Segoe UI, -apple-system, Verdana, Helvetica',
  backgroundColor: ColorRGBA(255, 255, 255, 0),
  textColor: ColorHEX('#191C22FF'),
  dataColors: [ColorHEX('#ffff5b'), ColorHEX('#ffcd5b'), ColorHEX('#ff9b5b')],
  axisColor: ColorHEX('#858585FF'),
  gridLineColor: ColorHEX('#303030ff'),
  uiBackgroundColor: ColorRGBA(255, 255, 255, 0),
  uiBorderColor: ColorRGBA(255, 255, 255, 0),
  dashboardSplitterColor: ColorRGBA(255, 255, 255, 0),
})




const GrowAnalyticsPage_Charting = ({
  activeGrowIds,
  onSelectGrows,
  
  dataTypeToggles,
  activeDataTypeToggles
}) => {

  const isTouchDevice = window.matchMedia("(pointer: coarse)").matches
  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const dispatch = useDispatch()

  /* Data */
  const haveAppInfo = useSelector((state) => state.appInfo.haveAppInfo)
  const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)
  const chartAxisTypes = useSelector(selectAllChartAxisTypes)


  const growDurations = useSelector((state) => selectGrowDurationsForGrowIds(state, activeGrowIds), _.isEqual)

  const [mainChartAxisHeight, SetMainChartAxisHeight] = React.useState(25)
  const [overviewChartAxisHeight, SetOverviewChartAxisHeight] = React.useState(20)


  const chartRef = React.useRef(undefined)

  const [chartReady, SetChartReady] = React.useState(false)
  const [activeDataSeries, SetActiveDataSeries] = React.useState({});


  const [mainChartContainerRef, { height: mainChartContainerHeight, width: mainChartContainerWidth, documentTop: mainChartContainerTop, documentLeft: mainChartContainerLeft }] = useMeasure()
  const [mainChartAreaRef, { ele: mainChartAreaEle, height: mainChartAreaHeight, width: mainChartAreaWidth, documentTop: mainChartAreaTop, documentLeft: mainChartAreaLeft }] = useMeasure()

  const [overviewChartContainerRef, { height: overviewChartContainerHeight, width: overviewChartContainerWidth, documentTop: overviewChartContainerTop, documentLeft: overviewChartContainerLeft }] = useMeasure()
  const [overviewChartAreaRef, { ele: overviewChartAreaEle, height: overviewChartAreaHeight, width: overviewChartAreaWidth, documentTop: overviewChartAreaTop, documentLeft: overviewChartAreaLeft }] = useMeasure()


  const [overviewCursor, SetOverviewCursor] = React.useState("default")








  const [mainChartArea, SetMainChartArea] = React.useState({ top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0 })
  const [mainChartAreaPadding, SetMainChartAreaPadding] = React.useState({ top: 0, left: 0, right: 0, bottom: 0 })
  const [mainChartTimeAxisThickness, SetMainChartTimeAxisThickness] = React.useState(0)
  React.useLayoutEffect(() => {
    let chartArea = {
      top: mainChartAreaTop,
      left: mainChartAreaLeft,
      x1: mainChartAreaPadding.left,
      x2: mainChartAreaWidth - mainChartAreaPadding.right,
      y1: mainChartAreaPadding.top,
      y2: mainChartAreaHeight - mainChartAreaPadding.bottom - mainChartTimeAxisThickness
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1
    SetMainChartArea(chartArea)
  }, [mainChartAreaTop, mainChartAreaLeft, mainChartAreaWidth, mainChartAreaHeight, mainChartAreaPadding, mainChartTimeAxisThickness])

  const [overviewChartArea, SetOverviewChartArea] = React.useState({ top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0 })
  const [overviewChartAreaPadding, SetOverviewChartAreaPadding] = React.useState({ top: 0, left: 0, right: 0, bottom: 0 })
  const [overviewChartTimeAxisThickness, SetOverviewChartTimeAxisThickness] = React.useState(0)
  React.useLayoutEffect(() => {
    let chartArea = {
      top: overviewChartAreaTop,
      left: overviewChartAreaLeft,
      x1: overviewChartAreaPadding.left,
      x2: overviewChartAreaWidth - overviewChartAreaPadding.right,
      y1: overviewChartAreaPadding.top,
      y2: overviewChartAreaHeight - overviewChartAreaPadding.bottom - overviewChartTimeAxisThickness
    }
    chartArea.width = chartArea.x2 - chartArea.x1
    chartArea.height = chartArea.y2 - chartArea.y1
    SetOverviewChartArea(chartArea)
  }, [overviewChartAreaTop, overviewChartAreaLeft, overviewChartAreaWidth, overviewChartAreaHeight, overviewChartAreaPadding, overviewChartTimeAxisThickness])





  /* Main Chart And Overview Chart Visible Range Management */
  const defaultMainChartDuration = 1000 * 60 * 60 * 24 * 1
  const defaultMainChartInterval = { start: 0, end: defaultMainChartDuration }
  const [pendingManualMainChartVisibleRangeChange, SetPendingManualMainChartVisibleRangeChange] = React.useState(false)
  const [mainChartVisibleRange, SetMainChartVisibleRange] = React.useState(defaultMainChartInterval)
  const getMainChartVisibleRange = () => {
    if (chartRef.current) {
      const interval = chartRef.current.mainChartTimeAxis.getInterval()
      return { start: interval.start, end: interval.end }
    }
    return defaultMainChartInterval
  }
  const MainChartVisibleRangeChanged = (range) => {
    if (chartRef.current !== undefined) {
      chartRef.current.mainChartTimeAxis.setInterval({ start: range.start, end: range.end })
      chartRef.current.mainChartTimeTicks = updateChartAxisTicks(chartRef.current.mainChartTimeAxis, chartRef.current.mainChartTimeTicks, chartRef.current.lastMainChartTickRange, range.start, range.end)
    }
    const timeDelta = (range.end - range.start) / 1000
    let targetDataRecordingTimePeriodType = null
    for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes) {
      if (targetDataRecordingTimePeriodType === null) {
        targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
      } else {
        if (timeDelta > dataRecordingTimePeriodType.duration) {
          targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
        }
      }
    }
    SetMainDataRecordingTimePeriodType(targetDataRecordingTimePeriodType)

    if (lastMainChartingAreaPointerLocation !== null) {
      calculateActiveDataPoints(lastMainChartingAreaPointerLocation)
    }
  }


  const [mainDataRecordingTimePeriodType, SetMainDataRecordingTimePeriodType] = React.useState(null)
  const SetMainChartVisibleRangeInstantly = (range) => {
    MainChartVisibleRangeChanged(range)
  }
  React.useLayoutEffect(() => {
    MainChartVisibleRangeChanged(mainChartVisibleRange)
  }, [chartRef, mainChartVisibleRange, dataRecordingTimePeriodTypes])

  const defaultOverviewChartDuration = 1000 * 60 * 60 * 24 * 7
  const defaultOverviewChartInterval = { start: 0, end: defaultOverviewChartDuration }
  const [overviewChartVisibleRange, SetOverviewChartVisibleRange] = React.useState(defaultOverviewChartInterval)
  const [overviewDataRecordingTimePeriodType, SetOverviewDataRecordingTimePeriodType] = React.useState(null)
  const getOverviewChartVisibleRange = () => {
    if (chartRef.current) {
      const interval = chartRef.current.overviewChartTimeAxis.getInterval()
      return { start: interval.start, end: interval.end }
    }
    return defaultOverviewChartInterval
  }
  React.useLayoutEffect(() => {
    if (chartRef.current !== undefined) {
      chartRef.current.overviewChartVisibleRange = overviewChartVisibleRange
      chartRef.current.overviewChartTimeAxis.setInterval({ start: overviewChartVisibleRange.start, end: overviewChartVisibleRange.end })
      chartRef.current.overviewChartTimeTicks = updateOverviewChartAxisTicks(chartRef.current.overviewChartTimeAxis, chartRef.current.overviewChartTimeTicks, chartRef.current.lastOverviewChartTickRange, overviewChartVisibleRange.start, overviewChartVisibleRange.end)

    }
    const timeDelta = overviewChartVisibleRange.end - overviewChartVisibleRange.start

    let targetDataRecordingTimePeriodType = null
    for (let dataRecordingTimePeriodType of dataRecordingTimePeriodTypes) {
      if (targetDataRecordingTimePeriodType === null) {
        targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
      } else {
        if (timeDelta > dataRecordingTimePeriodType.duration) {
          targetDataRecordingTimePeriodType = dataRecordingTimePeriodType
        }
      }
    }
    SetOverviewDataRecordingTimePeriodType(targetDataRecordingTimePeriodType)
  }, [chartRef, overviewChartVisibleRange])

  React.useLayoutEffect(() => {
    if (Object.entries(growDurations).length > 0) {
      const maxDuration = Math.max(...Object.values(growDurations))
      SetOverviewChartVisibleRange({start: 0, end: maxDuration})
    }else {
      SetOverviewChartVisibleRange(defaultOverviewChartInterval)
    }
  }, [growDurations])

  let processingMainChartInterval = false
  let processingOverviewChartInterval = false

  const checkMainChartInterval = (start, end) => {

    if ((start == chartRef.current.lastMainChartInterval.start && end == chartRef.current.lastMainChartInterval.end))
      return

    const overviewChartVisibleRange = getOverviewChartVisibleRange()


    processingMainChartInterval = true
    let changed = false;
    //const overviewChartVisibleRange = getOverviewChartInterval()

    const timeDelta = end - start
    let newEnergySubselection = { start: chartRef.current.energySubSelection.start, end: chartRef.current.energySubSelection.end }

    /*if (isForceLive) {
        let newEnd = new Date().getTime()
        overviewChartVisibleRange.end = newEnd
    }*/


    if (Math.floor(timeDelta) != Math.floor(chartRef.current.lastMainChartInterval.end - chartRef.current.lastMainChartInterval.start)) {
      //Zoom
      if (start < overviewChartVisibleRange.start || start > overviewChartVisibleRange.end) {
        start = overviewChartVisibleRange.start
        changed = true
      }
      if (chartRef.current.isForceLive && (end !== overviewChartVisibleRange.end)) {
        end = overviewChartVisibleRange.end
        changed = true
      } else if (end > overviewChartVisibleRange.end || end < overviewChartVisibleRange.start) {
        end = overviewChartVisibleRange.end
        changed = true
      }


      //Handle current position of energy subselection 
      if (newEnergySubselection.start < start) {
        newEnergySubselection.start = start
        if (newEnergySubselection.end < start) {
          newEnergySubselection.end = start + 1000
        }
        //newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
      }
      if (newEnergySubselection.end > end) {
        newEnergySubselection.end = end
        if (newEnergySubselection.start > end) {
          newEnergySubselection.start = end - 1000
        }
        //newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
      }



    } else {
      //Pan
      if (end > overviewChartVisibleRange.end) {
        start = overviewChartVisibleRange.end - timeDelta
        end = overviewChartVisibleRange.end
        changed = true
      }
      if (start < overviewChartVisibleRange.start) {
        start = overviewChartVisibleRange.start
        end = overviewChartVisibleRange.start + timeDelta
        changed = true
      }

      //Handle current position of energy subselection 
      if (newEnergySubselection.start < start) {
        newEnergySubselection.start = start
        newEnergySubselection.end = start + (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
      }
      if (newEnergySubselection.end > end) {
        newEnergySubselection.end = end
        newEnergySubselection.start = end - (chartRef.current.energySubSelection.end - chartRef.current.energySubSelection.start)
      }
    }


    //console.log(new Date(chartRef.current.energySubSelection.start), new Date(chartRef.current.energySubSelection.end), new Date(newEnergySubselection.start), new Date(newEnergySubselection.end), new Date(start), new Date(end))
    chartRef.current.energySubSelection = { start: newEnergySubselection.start, end: newEnergySubselection.end }


    chartRef.current.lastMainChartInterval.start = start
    chartRef.current.lastMainChartInterval.end = end
    if (changed) {
      SetMainChartVisibleRangeInstantly({ start: start, end: end })
    }
    SetMainChartVisibleRange({ start: start, end: end })

    processingMainChartInterval = false
  }

  const checkOverviewChartInterval = React.useCallback((start, end) => {
    if (chartRef.current === undefined)
      return

    if ((start == chartRef.current.lastOverviewChartInterval.start && end == chartRef.current.lastOverviewChartInterval.end) || processingOverviewChartInterval)
      return
    processingOverviewChartInterval = true



    chartRef.current.lastOverviewChartInterval.start = start
    chartRef.current.lastOverviewChartInterval.end = end
    SetOverviewChartVisibleRange({ start: start, end: end })


    processingOverviewChartInterval = false

  })

  const mainChartConvertTimeToPosition = (time) => {
    const mainChartVisibleRange = getMainChartVisibleRange()
    return ((time - mainChartVisibleRange.start) / (mainChartVisibleRange.end - mainChartVisibleRange.start)) * mainChartArea.width
  }
  const mainChartConvertPositionToTime = (x) => {
    const mainChartVisibleRange = getMainChartVisibleRange()
    if (x < 0)
      x = 0
    if (x > mainChartArea.width)
      x = mainChartArea.width
    return mainChartVisibleRange.start + (x / mainChartArea.width) * (mainChartVisibleRange.end - mainChartVisibleRange.start)
  }


  const overviewChartConvertTimeToPosition = (time) => {
    const overviewChartVisibleRange = getOverviewChartVisibleRange()
    return ((time - overviewChartVisibleRange.start) / (overviewChartVisibleRange.end - overviewChartVisibleRange.start)) * overviewChartArea.width
  }
  const overviewChartConvertPositionToTime = (x) => {
    const overviewChartVisibleRange = getOverviewChartVisibleRange()
    if (x < 0)
      x = 0
    if (x > overviewChartArea.width)
      x = overviewChartArea.width
    return overviewChartVisibleRange.start + (x / overviewChartArea.width) * (overviewChartVisibleRange.end - overviewChartVisibleRange.start)
  }









  const getTimeDeltaType = (timeDelta) => {
    if (timeDelta <= 1000 * 60 * 1)
      return ["minute", 1000 * 10, 1000 * 60]
    if (timeDelta <= 1000 * 60 * 5)
      return ["minute5", 1000 * 30, 1000 * 60]
    if (timeDelta <= 1000 * 60 * 10)
      return ["minute10", 1000 * 60, 1000 * 60 * 5]
    if (timeDelta <= 1000 * 60 * 30)
      return ["minute30", 1000 * 60 * 2, 1000 * 60 * 60]
    if (timeDelta <= 1000 * 60 * 60)
      return ["hour", 1000 * 60 * 5, 1000 * 60 * 60]
    if (timeDelta <= 1000 * 60 * 60 * 3)
      return ["hour3", 1000 * 60 * 15, 1000 * 60 * 60]
    if (timeDelta <= 1000 * 60 * 60 * 6)
      return ["hour6", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
    if (timeDelta <= 1000 * 60 * 60 * 12)
      //if (isLargeDesktop) {
      return ["hour12", 1000 * 60 * 60, 1000 * 60 * 60 * 24]
    //}else {
    //    return ["hour12", 1000 * 60 * 60 * 3, 1000 * 60 * 60 * 24]
    //}
    if (timeDelta <= 1000 * 60 * 60 * 24)
      //if (isLargeDesktop) {
      return ["day", 1000 * 60 * 60 * 3, 1000 * 60 * 60 * 24]
    //}else {
    //    return ["day", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]
    //}
    if (timeDelta <= 1000 * 60 * 60 * 24 * 3)
      //if (isLargeDesktop) {
      return ["3day", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]
    //}else {
    //    return ["day", 1000 * 60 * 60 * 6, 1000 * 60 * 60 * 24]
    //}
    if (timeDelta <= 1000 * 60 * 60 * 24 * 7)
      //if (isLargeDesktop) {
      return ["week", 1000 * 60 * 60 * 12, 1000 * 60 * 60 * 24]
    //}else {
    //    return ["week", 1000 * 60 * 60 * 12, 1000 * 60 * 60 * 24]
    //}

    return ["month", 1000 * 60 * 60 * 24, 1000 * 60 * 60 * 24 * 7]
  }

  const createTicksInRangeX = (axis, tickList, timeDelta, start, end) => {

    //let minorTickInterval = 1000 * 60 * 60 * 24 //every day
    //let majorTickInterval = 1000 * 60 * 60 * 24 * 7 //every week

    let minorTickFormat = '~H:~MM'
    let majorTickFormat = 'Day ~D+'
    const [deltaType, minorTickInterval, majorTickInterval] = getTimeDeltaType(timeDelta)
    if (deltaType === "minute") { //1 minute span -- we want ticks every 30 seconds
      minorTickFormat = '~H:~MM:~SS'
      majorTickFormat = '~H:~MM'
      let minuteCount = 1
      start = RoundedTimeToNearestLastMinute(minuteCount, start)
      end = RoundedTimeToNearestNextMinute(minuteCount, end)
    } else if (deltaType === "minute5") { //5 minute span -- we want ticks every 30 seconds
      minorTickFormat = '~H:~MM:~SS'
      majorTickFormat = '~H:~MM'
      let minuteCount = 5
      start = RoundedTimeToNearestLastMinute(minuteCount, start)
      end = RoundedTimeToNearestNextMinute(minuteCount, end)

    } else if (deltaType === "minute10") { //10 minute span -- we want ticks every minute
      if (isLargeDesktop) {
        minorTickFormat = '~H:~MM:~SS'
        majorTickFormat = '~H:~MM'
      } else {
        minorTickFormat = '~H:~MM'
        majorTickFormat = '~H:~MM'
      }
      let minuteCount = 10
      start = RoundedTimeToNearestLastMinute(minuteCount, start)
      end = RoundedTimeToNearestNextMinute(minuteCount, end)

    } else if (deltaType === "minute30") { //30 minute span -- we want ticks every 5 minutes
      if (isLargeDesktop) {
        minorTickFormat = '~H:~MM:~SS'
        majorTickFormat = '~H:~MM'
      } else {
        minorTickFormat = '~H:~MM'
        majorTickFormat = '~H:~MM'
      }
      let minuteCount = 30
      start = RoundedTimeToNearestLastMinute(minuteCount, start)
      end = RoundedTimeToNearestNextMinute(minuteCount, end)
    } else if (deltaType === "hour") { //60 minute span -- we want ticks every 15 minutes
      minorTickFormat = '~H:~MM'
      majorTickFormat = '~H:~MM'
      let minuteCount = 60
      start = RoundedTimeToNearestLastMinute(minuteCount, start)
      end = RoundedTimeToNearestNextMinute(minuteCount, end)
    } else if (deltaType === "hour3") { //3 hour span -- we want ticks every 30 minutes
      minorTickFormat = '~H:~MM'
      majorTickFormat = '~H:~MM'
      let hourCount = 3
      start = RoundedTimeToNearestLastHour(hourCount, start)
      end = RoundedTimeToNearestNextHour(hourCount, end)
    } else if (deltaType === "hour6") { //6 hour span -- we want ticks every hour
      minorTickFormat = '~H:~MM'
      majorTickFormat = 'Day ~D+'
      let hourCount = 6
      start = RoundedTimeToNearestLastHour(hourCount, start)
      end = RoundedTimeToNearestNextHour(hourCount, end)
    } else if (deltaType === "hour12") { //12 hour span -- we want ticks every hour
      minorTickFormat = '~H:~MM'
      majorTickFormat = 'Day ~D+'
      let hourCount = 12
      start = RoundedTimeToNearestLastHour(hourCount, start)
      end = RoundedTimeToNearestNextHour(hourCount, end)
    } else if (deltaType === "day") { //1 day span -- we want ticks every 6 hours
      minorTickFormat = '~H:~MM'
      majorTickFormat = 'Day ~D+'
      let dayCount = 1
      start = RoundedTimeToNearestLastDay(dayCount, start)
      end = RoundedTimeToNearestNextDay(dayCount, end)
    } else if (deltaType === "3day") { //1 day span -- we want ticks every 6 hours
      minorTickFormat = '~H:~MM'
      majorTickFormat = 'Day ~D+'
      let dayCount = 3
      start = RoundedTimeToNearestLastDay(dayCount, start)
      end = RoundedTimeToNearestNextDay(dayCount, end)
    } else if (deltaType === "week") { //7 day span -- we want ticks every day
      minorTickFormat = '~H:~MM'
      majorTickFormat = 'Day ~D+'
      let dayCount = 7
      start = RoundedTimeToNearestLastDay(dayCount, start)
      end = RoundedTimeToNearestNextDay(dayCount, end)
    }


    // Major ticks every 1000 units.
    let majorTickPositions = []
    for (let majorTickPos = start; majorTickPos <= end; majorTickPos += majorTickInterval) {


      if (majorTickPos >= start && majorTickPos <= end) {
        majorTickPositions.push(majorTickPos)
        const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
          .setTextFormatter(() => FormatTime(majorTickPos, majorTickFormat))
          .setValue(majorTickPos)
          .setMarker(marker => marker
            .setTextFont(new FontSettings({ size: 14, style: '' }))
          )
          .setTickLength(8)
          .setTickLabelPadding(-4)
          .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(100)))
        tickList.push(tick)
      }
    }
    // Major ticks every 100 units, but not at same interval as major ticks.
    for (let minorTickPos = start; minorTickPos <= end; minorTickPos += minorTickInterval) {
      if (minorTickPos >= start && majorTickPositions.indexOf(minorTickPos) === -1) {
        const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
          .setTextFormatter(() => FormatTime(minorTickPos, minorTickFormat))
          .setValue(minorTickPos)
          .setMarker(marker => marker
            .setTextFont(new FontSettings({ size: 12, style: '' }))
          )
          .setTickLabelPadding(-4)
          .setTickLength(4)
          .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(50)))
        tickList.push(tick)
      }
    }

  }




  const updateChartAxisTicks = (axis, ticks, lastRange, start, end) => {
    if (start == lastRange.start && end == lastRange.end)
      return ticks

    const timeDelta = end - start
    if (Math.floor(timeDelta) != Math.floor(lastRange.end - lastRange.start)) {
      //Zoom

      ticks = ticks.filter(tick => {
        tick.dispose()
        return false
      })
      createTicksInRangeX(axis, ticks, timeDelta, start, end)


    } else {
      //Pan
      /*const [deltaType, minorTickInterval, majorTickInterval] = getTimeDeltaType(timeDelta)
      let minuteCount = 1
      if (deltaType === "minute")  { //1 minute span -- we want ticks every 30 seconds
      }else if (deltaType === "minute5")  { //5 minute span -- we want ticks every 30 seconds
          minuteCount = 5
      }else if (deltaType === "minute10")  { //10 minute span -- we want ticks every minute
          minuteCount = 10
      }else if (deltaType === "minute30")  { //30 minute span -- we want ticks every 5 minutes
          minuteCount = 30
      }else if (deltaType === "hour")  { //60 minute span -- we want ticks every 15 minutes
          minuteCount = 60
      }else if (deltaType === "hour3")  { //3 hour span -- we want ticks every 30 minutes
          minuteCount = 60 * 3
      }else if (deltaType === "hour6")  { //6 hour span -- we want ticks every hour
          minuteCount = 60 * 6
      }else if (deltaType === "hour12")  { //12 hour span -- we want ticks every hour
          minuteCount = 60 * 12
      }else if (deltaType === "day")  { //1 day span -- we want ticks every 6 hours
          minuteCount = 60 * 24
      }else if (deltaType === "week")  { //7 day span -- we want ticks every day
          minuteCount = 60 * 24 * 7
      }
      start = RoundedDateToNearestLastMinute(minuteCount, new Date(start)).getTime()
      end = RoundedDateToNearestNextMinute(minuteCount, new Date(end)).getTime()
      

      
      //compare last vs now to see if we need to remove ticks
      ticks = ticks.filter(tick => {
          console.log(tick.getValue(), start - chartOriginDate, end - chartOriginDate)
          if (tick.getValue() < (start - chartOriginDate) || tick.getValue() > (end - chartOriginDate)) {
              // Tick is out of view.
              tick.dispose()
              return false
          } else {
              return true
          }
      })
      //compare last vs now to see if we need to add ticks
      
      if (end > lastRange.end) {
          createTicksInRangeX(axis, ticks, timeDelta, lastRange.end, end)
      }
      if (start < lastRange.start)    {
          createTicksInRangeX(axis, ticks, timeDelta, start, lastRange.start)
      }


      */

      ticks = ticks.filter(tick => {
        tick.dispose()
        return false
      }
      )
      createTicksInRangeX(axis, ticks, timeDelta, start, end)




    }

    lastRange.start = start
    lastRange.end = end


    return ticks
  }




  const createOverviewTicksInRangeX = (axis, tickList, timeDelta, start, end) => {

    //let minorTickInterval = 1000 * 60 * 60 * 24 //every day
    //let majorTickInterval = 1000 * 60 * 60 * 24 * 7 //every week

    let minorTickFormat = 'Day ~D+'
    //let majorTickFormat = 'MMM dd'
    const minorTickInterval = 1000 * 60 * 60 * 24 //days
    //const majorTickInterval = 0

    let minuteCount = 60 * 24
    start = RoundedTimeToNearestLastMinute(minuteCount, start)
    end = RoundedTimeToNearestNextMinute(minuteCount, end)


    // Major ticks every 100 units, but not at same interval as major ticks.
    const majorTickPositions = []
    for (let minorTickPos = start; minorTickPos <= end; minorTickPos += minorTickInterval) {
      if (minorTickPos >= start && majorTickPositions.indexOf(minorTickPos) === -1) {
        const tick = axis.addCustomTick(UIElementBuilders.AxisTick)
          .setTextFormatter(() => FormatTime(minorTickPos, minorTickFormat))
          .setValue(minorTickPos)
          .setMarker(marker => marker
            .setTextFont(new FontSettings({ size: 12, style: '' }))
          )
          .setTickLabelPadding(-4)
          .setTickLength(4)
          .setGridStrokeStyle(style => style.setFillStyle(fill => fill.setA(50)))
        tickList.push(tick)
      }
    }

  }



  const updateOverviewChartAxisTicks = (axis, ticks, lastRange, start, end) => {
    if (start == lastRange.start && end == lastRange.end)
      return ticks

    const timeDelta = end - start
    ticks = ticks.filter(tick => {
      tick.dispose()
      return false
    }
    )
    createOverviewTicksInRangeX(axis, ticks, timeDelta, start, end)


    lastRange.start = start
    lastRange.end = end


    return ticks
  }






  const [chartingAreaPointerId, SetChartingAreaPointerId] = React.useState(null)
  const [lastMainChartingAreaPointerLocation, SetLastMainChartingAreaPointerLocation] = React.useState(null)
  const [lastMainChartingAreaPointerLocationWithinChart, SetLastMainChartingAreaPointerLocationWithinChart] = React.useState(null)
  const [chartAreaPointerInitialPosition, SetChartAreaPointerInitialPosition] = React.useState({ x: 0, y: 0 })
  const [activePointerTime, SetActivePointerTime] = React.useState(null)

  const [numberOfPointersDownOnMainChart, SetNumberOfPointersDownOnMainChart] = React.useState(0)
  const [isPanningMainChart, SetIsPanningMainChart] = React.useState(false)
  const [panningMainChartOriginTimes, SetPanningMainChartOriginTimes] = React.useState(null)


  const mainChartPointerDown = (e) => {
    const numberOfPointersDown = numberOfPointersDownOnMainChart + 1
    SetNumberOfPointersDownOnMainChart(numberOfPointersDown)
    SetChartAreaPointerInitialPosition({ top: e.clientY, left: e.clientX })
    return false
  }
  const mainChartPointerMove = (e) => {
    const pointerPosition = { top: e.clientY, left: e.clientX }
    SetLastMainChartingAreaPointerLocation(pointerPosition)
    SetLastMainChartingAreaPointerLocationWithinChart({ top: pointerPosition.top - mainChartArea.top, left: pointerPosition.left - mainChartArea.left })
    SetActivePointerTime(mainChartConvertPositionToTime(e.clientX - mainChartArea.left))
    calculateActiveDataPoints(pointerPosition)
    if (!isPanningMainChart && ((isTouchDevice && numberOfPointersDownOnMainChart === 2) || !isTouchDevice && numberOfPointersDownOnMainChart === 1)) {
      SetIsPanningMainChart(true)
      SetPanningMainChartOriginTimes(getMainChartVisibleRange())
      if (mainChartAreaRef.current !== undefined && mainChartAreaRef.current.setPointerCapture) {
        SetChartingAreaPointerId(e.pointerId)
        mainChartAreaRef.current.setPointerCapture(e.pointerId);
      }
    } else if (isPanningMainChart) {
      handleMainChartPan(pointerPosition)
    }
  }
  const mainChartPointerUp = (e) => {
    if (!isPanningMainChart) {
      const numberOfPointersDown = numberOfPointersDownOnMainChart - 1
      SetNumberOfPointersDownOnMainChart(numberOfPointersDown)
    } else {
      doneMainChartPan()
    }
  }
  const mainChartPointerLeave = (e) => {
    
  }
  const handleMainChartPan = (pointerPosition) => {
    const offsetPosition = { top: pointerPosition.top - chartAreaPointerInitialPosition.top, left: pointerPosition.left - chartAreaPointerInitialPosition.left }

    const existingTimeDelta = panningMainChartOriginTimes.end - panningMainChartOriginTimes.start
    const perPixelTimeRange = existingTimeDelta / mainChartAreaWidth

    const overviewChartVisibleRange = getOverviewChartVisibleRange()
    let start = panningMainChartOriginTimes.start + (-offsetPosition.left * perPixelTimeRange)
    let end = panningMainChartOriginTimes.end + (-offsetPosition.left * perPixelTimeRange)
    if (start < overviewChartVisibleRange.start) {
      start = overviewChartVisibleRange.start
      end = overviewChartVisibleRange.start + existingTimeDelta
    } else if (end > overviewChartVisibleRange.end) {
      end = overviewChartVisibleRange.end
      start = overviewChartVisibleRange.end - existingTimeDelta
    }
    SetMainChartVisibleRangeInstantly({ start: start, end: end })
    SetNumberOfPointersDownOnMainChart(0)
  }
  const doneMainChartPan = () => {
    SetIsPanningMainChart(false)
    if (mainChartAreaRef.current !== undefined && mainChartAreaRef.current.releasePointerCapture && chartingAreaPointerId !== null) {
      mainChartAreaRef.current.releasePointerCapture(chartingAreaPointerId);
    }
    SetNumberOfPointersDownOnMainChart(0)
  }





  const [activeDataPoints, SetActiveDataPoints] = React.useState({})
  const [activeDataPoint, SetActiveDataPoint] = React.useState(null)
  const calculateActiveDataPoints = (pointerPosition) => {

  }
  const pointerNoLongerOverMainChart = () => {
    SetActiveDataPoints({})
    SetActiveDataPoint(null)
  }

  //const lc = useContext(LCContext)
  React.useEffect(() => {
    if (chartRef.current)
      return


    const lc = createLightningChart({})
    if (!lc) {
      return
    }

    const mainChart = lc.ChartXY({
      container: mainChartAreaEle.current,
      theme: Themes.light,
    }).setMouseInteractionRectangleZoom(false)
      .setMouseInteractionRectangleFit(false)
      .setMouseInteractionWheelZoom(true)
      .setMouseInteractionPan(false)
      .setTitle("")
      .setPadding({ top: 0, left: 0, right: 0, bottom: 0 })
      .setAutoCursorMode(AutoCursorModes.disabled)



    mainChart.getDefaultAxisY()
      .setMouseInteractions(false)
      .setStrokeStyle(emptyLine)
      .setThickness(0)
      .setTickStrategy(AxisTickStrategies.Empty)

    const mainChartTimeAxis = mainChart.getDefaultAxisX()
    mainChartTimeAxis
      .setTickStrategy(AxisTickStrategies.Time)
      .setAnimationsEnabled(false)
      .setChartInteractionPanByDrag(false)
      .setChartInteractionZoomByWheel(true)
      .setNibInteractionScaleByWheeling(false)
      .setInterval({ start: defaultMainChartInterval.start, end: defaultMainChartInterval.end })
      .setScrollStrategy(undefined)
      .setTickStrategy(AxisTickStrategies.Empty)
      .setThickness(mainChartAxisHeight)

    mainChartTimeAxis.onIntervalChange((axis, start, end) => {
      checkMainChartInterval(start, end)
    })




    let mainChartTimeTicks = []
    let lastMainChartInterval = { start: 0, end: 0 }
    let lastMainChartTickRange = { start: 0, end: 0 }
    mainChartTimeTicks = updateChartAxisTicks(mainChartTimeAxis, mainChartTimeTicks, lastMainChartTickRange, defaultMainChartInterval.start, defaultMainChartInterval.end)

    const overviewChart = lc.ChartXY({
      container: overviewChartAreaEle.current,
      theme: mainChartTheme,
    }).setMouseInteractionRectangleZoom(false)
      .setMouseInteractionRectangleFit(false)
      .setMouseInteractionWheelZoom(false)
      .setMouseInteractions(false)
      .setTitle("")
      .setPadding({ top: 0, left: 0, right: 0, bottom: 0 })
      .setAutoCursorMode(AutoCursorModes.disabled)
      .setBackgroundFillStyle(new SolidFill({ color: ColorHEX("#FFFFFF") }))
      .setSeriesBackgroundFillStyle(new SolidFill({ color: ColorHEX("#FFFFFF") }))
      .setSeriesBackgroundStrokeStyle(new SolidLine({ thickness: 3, fillStyle: new SolidFill({ color: ColorHEX("#858585") }) }))

    overviewChart.getDefaultAxisY()
      .setMouseInteractions(false)
      .setStrokeStyle(emptyLine)
      .setThickness(0)
      .setTickStrategy(AxisTickStrategies.Empty)


    const overviewChartTimeAxis = overviewChart.getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.Empty)
      .setMouseInteractions(false)
      .setChartInteractionPanByDrag(false)
      .setChartInteractionZoomByWheel(false)
      .setNibInteractionScaleByWheeling(false)
      .setInterval({ start: defaultOverviewChartInterval.start, end: defaultOverviewChartInterval.end })
      .setScrollStrategy(undefined)
      .setStrokeStyle(emptyLine)
      .setTickStrategy(AxisTickStrategies.Empty)
      .setThickness(overviewChartAxisHeight)

    let overviewChartTimeTicks = []
    let lastOverviewChartInterval = { start: 0, end: 0 }
    let lastOverviewChartTickRange = { start: 0, end: 0 }
    overviewChartTimeTicks = updateOverviewChartAxisTicks(overviewChartTimeAxis, overviewChartTimeTicks, lastOverviewChartTickRange, defaultOverviewChartInterval.start, defaultOverviewChartInterval.end)

    overviewChartTimeAxis.onIntervalChange((axis, start, end) => {
      checkOverviewChartInterval(start, end)
    })





    chartRef.current = {
      mainChart,
      mainChartTimeAxis,
      overviewChart,
      overviewChartTimeAxis,
      dataSeries: {},
      mainChartTimeTicks,
      overviewChartTimeTicks,

      lastMousePosition: { x: 0, y: 0 },
      lastMainChartPointerOffset: { x: 0, y: 0 },
      lastOverviewChartPointerOffset: { x: 0, y: 0 },
      lastSelectedGrows: [],
      lastMainChartInterval,
      lastOverviewChartInterval,
      lastMainChartTickRange,
      lastOverviewChartTickRange,
      lastMainDataRecordingTimePeriodType: null,
      lastMainChartDeltaType: null,
      growPositionIndicator: {},
      /*verticalRackGroup : {},
      verticalRacks: [],*/
      activeYAxes: {},
      lastEnergySubSelection: { start: 0, end: 0 },
      energySubSelection: defaultMainChartInterval,
      energyTotals: {},
      energySubselectionActive: false,

      nutrientDosingBarsRequiresReset: true,
      nutrientDosingLargestVolume: 0,
      nutrientDosingBars: {},

      tempYAxisInterval: {},

    }

    SetMainChartAreaPadding(chartRef.current.mainChart.getPadding())
    SetMainChartTimeAxisThickness(chartRef.current.mainChartTimeAxis.getThickness().max)

    SetOverviewChartAreaPadding(chartRef.current.overviewChart.getPadding())
    SetOverviewChartTimeAxisThickness(chartRef.current.overviewChartTimeAxis.getThickness().max)

    SetChartReady(true)

  }, [mainChartTheme])






  return (<>
    <InitializeDataRecordingTimePeriodTypes growIds={activeGrowIds} dataRecordingTimePeriodTypes={dataRecordingTimePeriodTypes}/>
    <MaintainGrowAnalyticsData
      growIds={activeGrowIds}
      mainRange={mainChartVisibleRange} 
      overviewRange={overviewChartVisibleRange} 
      mainDataRecordingTimePeriodType={mainDataRecordingTimePeriodType}
      overviewDataRecordingTimePeriodType={overviewDataRecordingTimePeriodType}/>
    <div className="GrowAnalytics-Charting-Charts">
      <div className="GrowAnalytics-Charting-Content">
        <div className="GrowAnalytics-Charting-MainChart-Wrapper" ref={mainChartContainerRef}>
          <div className="GrowAnalytics-Charting-MainChart-Content">
            {/*style={{ height: isLargeDesktop ? 500 : isDesktop ? 350 : isTablet ? 250 : 200 }}>*/}
            <div ref={mainChartAreaRef}
              className="GrowAnalytics-Charting-MainChart"
              onPointerDown={mainChartPointerDown}
              onPointerMove={mainChartPointerMove}
              onPointerUp={mainChartPointerUp}
              onPointerLeave={mainChartPointerLeave}></div>
            <div className="GrowAnalytics-Charting-MainChart-OverlayContainer">
              <GrowAnalyticsPage_ChartPointCrosshair
                calculatePositionFromTime={mainChartConvertTimeToPosition}
                chartAreaWidth={mainChartArea.width}
                chartAreaHeight={mainChartArea.height}
                activeTime={activePointerTime}
                pointerPosition={lastMainChartingAreaPointerLocationWithinChart}
                nearestActiveDataPoint={activeDataPoint} />
              {/*<GrowAnalyticsPage_DataLegendTooltip
                isTouchDevice={isTouchDevice}
                isPanningChart={isPanningMainChart}
                calculatePositionFromTime={mainChartConvertTimeToPosition}
                dataTypeToggles={dataTypeToggles}
                activeDataTypeToggles={activeDataTypeToggles}
                chartArea={mainChartArea}
                activeDataPoints={activeDataPoints}
                activeTime={activePointerTime}
                pointerPosition={lastMainChartingAreaPointerLocationWithinChart}
                nearestActiveDataPoint={activeDataPoint} />*/}
              <GrowAnalyticsPage_ActiveTimeTooltip
                calculatePositionFromTime={mainChartConvertTimeToPosition}
                activeTime={activePointerTime}
                nearestActiveDataPoint={activeDataPoint}
                chartAreaWidth={mainChartArea.width}
                chartAreaHeight={mainChartArea.height}
              />
            </div>
          </div>
          {activeGrowIds.length === 0 && <>
            <div className="GrowAnalytics-Charting-NoGrowsSelectedPopup-Container">
              <div className="GrowAnalytics-Charting-NoGrowsSelectedPopup-Content">
                <div className="Text-H24">No Grows Selected</div>
                <Button status="Hyperlink" content={"Select Grow"} onClick={onSelectGrows} />
              </div>
            </div>
          </>}
        </div>
        <div className="GrowAnalytics-Charting-OverviewChart-Wrapper" ref={overviewChartContainerRef}>
          <div className="GrowAnalytics-Charting-OverviewChart-Content"
            style={{ height: isLargeDesktop ? 100 : isDesktop ? 60 : isTablet ? 40 : 40, marginBottom: -overviewChartAxisHeight }}>
            <div ref={overviewChartAreaRef}
              className="GrowAnalytics-Charting-OverviewChart"
              style={{ cursor: overviewCursor }}></div>

            {<GrowAnalyticsPage_OverviewChartMainChartSubselection
              mainChartArea={mainChartArea}
              overviewChartArea={overviewChartArea}
              calculatePositionFromTime={overviewChartConvertTimeToPosition}
              calculateTimeFromPosition={overviewChartConvertPositionToTime}
              getMainChartVisibleRange={getMainChartVisibleRange}
              updateMainChartVisibleRange={SetMainChartVisibleRangeInstantly}/>}


          </div>
        </div>
      </div>
    </div>
  </>)
}

export default GrowAnalyticsPage_Charting