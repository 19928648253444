import './GrowZonesPage.scss';
import React from 'react'

import _ from 'underscore';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllServicesForFacility, selectAllFacilities } from '../../redux/entities/Facilities';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, getBladeZoneConfigurationMap, markGrowZoneForCleanse, selectAllBlades, selectAllBladeZoneConfigurationMaps, setBladeRuntimeProperty, setBladeZoneRuntimeProperty, MaintainBladeZoneStatus, MaintainBladeLiveData, selectBladeZoneDisplayNameByUID, selectAllBladeUIDs, getBladeByUID, MaintainBladeStatus, InitialLoadAllBladeZones, InitialLoadAllBladeGroups, InitialLoadAllBlades, MaintainBladeConfigurationMaps, MaintainBladeUniqueConfigurationMaps, selectAllBladeGroupIdsForFacility, selectAllBladeZoneUIDsForBladeGroupSorted, selectAllBladeIndexesForBladeGroup } from '../../redux/entities/service/Blade';
import ControlBar from '../../components/ControlBar';
import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import Button from '../../components/Button';
import RowGroup from '../../components/RowGroup/RowGroup';
import { AirSetpoint, LightingSetpoint, LowerWaterSetpoint, Pause, Play, PlusAlt, RSSI, UpperWaterSetpoint, WaterSetpoint } from '../../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../components/input/DropDownInput';
import TextInput from '../../components/input/TextInput';
import { FormatDate, useRenderingTrace } from '../../helpers';
import TaskPopup from '../Tasks/TaskPopup';
import GrowZonesPage_Zone from './GrowZonesPage_Zone';




const GrowZonesPage = ({selectedFacility}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  /* React useEffects */
  const dispatch = useDispatch()
  const navigate = useNavigate()


  /* Data */   

  const activeBladeGroupIds = useSelector(state => selectAllBladeGroupIdsForFacility(state, selectedFacility), _.isEqual)

  const activeBladeUIDs = useSelector(state => state.blade.blade.filter((b) => {
    return (activeBladeGroupIds.indexOf(b.group_id) !== -1)
  }).map((b) => b.uid), _.isEqual)

  const activeBladeZoneUIDs = useSelector(state => state.blade.zones.filter((z) => {
    return (activeBladeGroupIds.indexOf(z.group_id) !== -1)
  }).map((z) => z.uid), _.isEqual) 

 
  /* Interaction */
  const [viewingTask, SetViewingTask] = React.useState(null)
  const onViewingTask = (taskId) => {
    SetViewingTask(taskId)
  }
  const closeViewingTask = () => {
    SetViewingTask(null)
  }

  return (<>
    <InitialLoadAllBlades/>
    <InitialLoadAllBladeGroups/>
    <InitialLoadAllBladeZones/>
    <MaintainBladeStatus bladeUIDs={activeBladeUIDs}/>
    <MaintainBladeLiveData bladeUIDs={activeBladeUIDs}/>
    <MaintainBladeConfigurationMaps bladeUIDs={activeBladeUIDs} />
    <MaintainBladeUniqueConfigurationMaps bladeUIDs={activeBladeUIDs} />
    <MaintainBladeZoneStatus bladeZoneUIDs={activeBladeZoneUIDs}/>
    <div id="GrowZonesStatus">
      
      {activeBladeGroupIds.length === 0 && 
        <div className="GrowZonesStatus-NoGroupsContent">
          <span>No active zones</span>
        </div>
      }
      {activeBladeGroupIds.length > 0 &&
        <div className="GrowZonesStatus-VerticalRackGroups">
          {activeBladeGroupIds.map((bladeGroupId) => {


            return (
              <GrowZonesPage_Group 
                key={bladeGroupId} 
                selectedFacility={selectedFacility} 
                bladeGroupId={bladeGroupId}
                defaultOpen={activeBladeGroupIds.length === 1}/>
              
              )
            
            })}
          </div>
        }
    </div>
    {viewingTask && <>
      <TaskPopup taskId={viewingTask} onCloseTask={closeViewingTask} viewingTaskCallback={onViewingTask}/>
    </>}
  </>)
} 


export default GrowZonesPage









const GrowZonesPage_Group = ({selectedFacility, bladeGroupId, defaultOpen, viewingTaskCallback}) => {

  let otherGroupProps = {}
  if (defaultOpen) {
    otherGroupProps.expandedState = true
  }

  const bladeGroup = useSelector((state) => state.blade.groups.find((bG) => bG.id === bladeGroupId), _.isEqual)
  //const bladeIndexes = useSelector((state) => selectAllBladeIndexesForBladeGroup(state, bladeGroupId), _.isEqual)
  const activeBladeZoneUIDs = useSelector((state) => selectAllBladeZoneUIDsForBladeGroupSorted(state, bladeGroupId), _.isEqual)


  return (<>
    <RowGroup
      title={bladeGroup.display_name}
      hasSettings={false}
      isExpandable={true}
      expandCellContent={"Show " + Object.values(bladeGroup.zones).length + " Zones"}
      collapseCellContent={"Hide Zones"}
      hasColumnHeaders={false}
      {...otherGroupProps}>
        
        {activeBladeZoneUIDs.map((zoneUID) => {
          return <GrowZonesPage_Zone
            key={zoneUID}
            selectedFacility={selectedFacility} 
            zoneUID={zoneUID} 
            onViewingTask={viewingTaskCallback}/>
        })}

      </RowGroup>
  </>)
}