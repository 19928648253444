
import './SelectGrows.scss';
import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { FormatDate } from '../helpers'
import { selectAllGrows } from '../redux/entities/Grow';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import Button from '../components/Button';
import { useMediaQuery } from 'react-responsive';
import RowGroup from '../components/RowGroup/RowGroup';
import RowGroupRow from '../components/RowGroup/RowGroupRow';
import SelectGrows from './SelectGrows';
import PopupModal from './PopupModal';


const SelectGrowsPopup = ({
  selectedFacility,
  selectedGrowId, selectedGrowIds,
  allowMultiselect, maxSelected,
  onGrowsSelected, closeCallback
}) => {


  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({ maxHeight: 800 }) && isDesktop

  const [currentSelectedGrowIds, SetSelectedGrowIds] = React.useState([])
  React.useLayoutEffect(() => {
    if (!allowMultiselect) {
      if (selectedGrowId !== null)  {
        SetSelectedGrowIds([selectedGrowId])
      }else {
        SetSelectedGrowIds([])
      }
    }else {
      if (selectedGrowIds !== null)  {
        SetSelectedGrowIds([...selectedGrowIds])
      }else {
        SetSelectedGrowIds([])
      }
    }
  }, [])
  const growsSelected = (growIds) => {
    SetSelectedGrowIds(growIds)
  }
  const doneSelectingGrows = () => {
    onGrowsSelected(currentSelectedGrowIds)
  }
  return (
    <>
       <PopupModal
            id={"ManageBladeWalkthrough_Popup"}
            title={<>
                <div className="FlexContent-H-10 FlexContent-Center">
                  <div>Select Grows</div>
                    {(allowMultiselect && maxSelected) && <>
                    <div className={"Text-Aux20"}>
                      {currentSelectedGrowIds.length}/{maxSelected} Selected
                    </div>
                  </>}
                </div>
            </>}
            hideMainSeparators={true}
            closeCallback={closeCallback}
            size={"full"}
            footer={<>
                <Button content={"Cancel"} status={"Neutral"} onClick={closeCallback} width={"Flex100"}/>
                <Button content={"Done"} status={"Primary"} onClick={doneSelectingGrows} width={"Flex100"}/>
            </>}>
            <>
              <div className={"Popup-Card-Content"} style={{minWidth: isMobile ? 100 : isTablet ? 300 : 500}}>
                <SelectGrows selectedFacility={selectedFacility} selectedGrowIds={currentSelectedGrowIds} allowMultiselect={true} onGrowsSelected={growsSelected} maxSelected={maxSelected}/>                    
              </div>  
            </>
        </PopupModal>
       
        
    </>
  )
}


SelectGrowsPopup.defaultProps = {
  allowMultiselect: false,
  maxSelected: null,
}

export default SelectGrowsPopup