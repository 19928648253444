import './HarvestTask.scss';
import React from 'react';
import _ from 'underscore';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { getBladeByUID, markGrowZoneForCleanse, selectAllBlades, selectAllBladeZones, selectBladeIdsForBladeZoneUID } from '../../../../redux/entities/service/Blade';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks';


const HarvestTask_ZoneCleanOptionsWalkthroughStep = ({
        task,
        harvestingZoneUID,
        containers,
        cancelTaskCallback,
        taskCompletedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["container_list", null])
    }
    const dispatch = useDispatch()
    const completeClicked = () => {
        if (cleaningOption === "clean") {
            if (growBlade !== undefined)    {
                dispatch(markGrowZoneForCleanse({bladeId: growBlade.id, zoneUID: harvestingZoneUID, forReservoir: "primary", callback: (success, taskId) => {
                    if (success)  {
                        finishedHarvestTask()
                    }
                }}))
            }
        }else {
            finishedHarvestTask()
        }
    }


    
    const [performingHarvestTaskCompleted, SetPerformingHarvestTaskCompleted] = React.useState(false)
    const finishedHarvestTask = () => {
        console.log(containers)
        SetPerformingHarvestTaskCompleted(true)
        dispatch(markTaskAsCompleted({taskId: task.id, params: {

        }, callback: (success) => {
            if (success)    {
                taskCompletedCallback()
            }
            SetPerformingHarvestTaskCompleted(false)
        }}))
    }
    
    



    const [cleaningOption, SetCleaningOption] = React.useState(null)
    
    const allBlades = useSelector((state) => selectAllBlades(state))
    const allBladeZones = useSelector((state) => selectAllBladeZones(state))

    const activeBladeZone = allBladeZones.find((bZ) => bZ.uid === harvestingZoneUID)
    const growBlade = allBlades.find((b) => b.uid === activeBladeZone.zone_props["grow_rack_uid"])
    React.useEffect(() => {
        if (activeBladeZone !== undefined && activeBladeZone.zone_props !== null && activeBladeZone.zone_props["grow_rack_uid"] !== undefined)  {
            if (allBlades.find((b) => b.uid === activeBladeZone.zone_props["grow_rack_uid"]) === undefined)  {
                dispatch(getBladeByUID({bladeUID: activeBladeZone.zone_props["grow_rack_uid"]}))
            }
        }
    }, [allBlades, activeBladeZone])


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Does {activeBladeZone !== undefined && activeBladeZone.display_name} need cleaning?
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={isMobile ? "FlexContent-10" : "FlexContent-H-10"} style={{flex:1}}>
                    <Button content={<>
                        <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                            <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                                <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>Yes, Mark zone as dirty</div>
                                <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>Cleanse tasks will be created to take care of this zone before another grow can run</div>
                            </div>
                        </div>
                    </>} status={"Primary-Toggle"} state={cleaningOption === "clean"} width={"Flex100"} onClick={() => {SetCleaningOption("clean")}}/>
                <Button content={<>
                    <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                            <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>No, Skip cleaning</div>
                            <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>The zone will be ready for another grow immediately</div>
                        </div>
                    </div>
                    </>} status={"Primary-Toggle"} state={cleaningOption === "skip"} width={"Flex100"} onClick={() => {SetCleaningOption("skip")}}/>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Complete"} onClick={completeClicked} size={"Flex50"} disabled={cleaningOption === null}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ZoneCleanOptionsWalkthroughStep