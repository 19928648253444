
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectPlantVarieties from '../../../../model_components/SelectPlantVarieties';
import SelectRecipes from '../../../../model_components/SelectRecipes';
  

const CreateGrowPlan_SelectRecipeWalkthroughStep = ({
        initialSelectedRecipeId,
        initialSelectedRecipeVersion,
        recipeConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop



    const [selectedRecipeId, SetSelectedRecipeId] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedRecipeId !== undefined)  {
            SetSelectedRecipeId(initialSelectedRecipeId)
        }
    }, [initialSelectedRecipeId])
    
    const [selectedRecipeVersion, SetSelectedRecipeVersion] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedRecipeVersion !== undefined)  {
            SetSelectedRecipeVersion(initialSelectedRecipeVersion)
        }
    }, [initialSelectedRecipeVersion])


    const recipeSelected = (recipeId, recipeVersion) => {
        SetSelectedRecipeId(recipeId)
        SetSelectedRecipeVersion(recipeVersion)
    }




    const backClicked = () => {
        transitionToPreviousStep(["plant_variant_list", null])
    }
    const nextClicked = () =>   {
        recipeConfirmedCallback({
            recipeId: selectedRecipeId,
            recipeVersion: selectedRecipeVersion
        })
        transitionToNextStep(["select_grow_out_raft_type", null])
    }
 
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"} style={{minWidth: isMobile ? 100 : isTablet ? 300 : 500}}>
                    <div style={{display:"flex", alignContent: "flex-start", alignSelf: "stretch"}}>Select a recipe</div>
                    <SelectRecipes selectedRecipe={selectedRecipeId} onRecipeSelection={recipeSelected}/>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedRecipeId === null || selectedRecipeVersion === null}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectRecipeWalkthroughStep
