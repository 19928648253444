
import './RecipesPage.scss';
import variables from '../../../globals.scss';
import _ from 'underscore';

import React from 'react'
import StandardTable from '../../../components/StandardTable.js'
import { Routes, Route, Navigate, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllRecipes, getAllRecipes, createRecipe, selectAllRecipeIDs, selectAllAccountIdsForRecipes } from '../../../redux/entities/Recipes'

import SelectRecipes from '../../../model_components/SelectRecipes';

import Badge from '../../../components/Badge.js'
import Button from '../../../components/Button.js'
import StandardList from '../../../components/StandardList/StandardList';
import { FormatDate } from '../../../helpers';

import {FaTrashAlt} from 'react-icons/fa'
import CreateRecipePopup from './CreateRecipePopup';
import { InitialLoadAllAccountsBasicInfo } from '../../../redux/entities/Account';


const RecipesPage = ({pageHeaderHelper, pageHeaderHelperSetup}) => {
  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelperSetup()
    pageHeaderHelper.current.SetTitle("My Repository")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <Button content={"Create Recipe"}  onClick={() => {SetCreatingRecipe(true)}}/>
            </div>
          </div>
        </>
      )
    })
  }, [])

  const dispatch = useDispatch()
  const navigate = useNavigate(); 
  let recipes = useSelector(selectAllRecipes)
  const activeRecipeIds = useSelector(selectAllRecipeIDs, _.isEqual)
  const recipeAccountIds = useSelector((state) => selectAllAccountIdsForRecipes(state, activeRecipeIds), _.isEqual)
  const allAccounts = useSelector((state) => state.account.accounts)
  let haveInitialData = useSelector((state) => state.recipes.haveInitialData)

  React.useEffect( () =>  { 
    if (!haveInitialData) {
      dispatch(getAllRecipes({}))
    }
  }, [haveInitialData])

  const [creatingRecipe, SetCreatingRecipe] = React.useState(false)
  const closeCreateRecipePopup = () =>  {
    SetCreatingRecipe(false)
  }

  const [postCreatingRecipeTempId, SetPostCreatingRecipeTempId] = React.useState(null)
  const recipeTempId = useSelector((state) => state.recipes.currentRecipeTempId)
  const creatingRecipeFinalized = (finalizedRecipe) =>  {
    finalizedRecipe.type = "universal"
    finalizedRecipe.temp_id = recipeTempId
    finalizedRecipe.version = 0
    finalizedRecipe.created_on = finalizedRecipe.modified_on = new Date().toISOString();
    finalizedRecipe.timeline_items = []
    finalizedRecipe.created_by_account_id = 29 //hacked
    finalizedRecipe.public = false
    finalizedRecipe.enabled = true
    finalizedRecipe.is_active_version = true
    finalizedRecipe.version_name = finalizedRecipe.name + "-Created"
    finalizedRecipe.stack_index = 1
    finalizedRecipe.revision_stack_size = 1
    finalizedRecipe.initialSave = false

    dispatch(createRecipe({recipe: finalizedRecipe}))
    SetCreatingRecipe(false)
    SetPostCreatingRecipeTempId(recipeTempId)
  }
  React.useEffect(() => {
    if (postCreatingRecipeTempId) {
      let foundRecipe = recipes.find((r) => r.temp_id === postCreatingRecipeTempId)
      if (foundRecipe !== undefined && foundRecipe.id !== undefined && foundRecipe.id)  {
        navigate("/repo/recipe/" + foundRecipe.id + "/edit");
      }
    }
  }, [recipes])
  


  const [recipesList, SetRecipesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")

  React.useEffect(() => {
    let currentRecipesList = {}

    let activeRecipes = recipes.filter((r) => r.is_active_version)
    let currentRecipes = []
    

    if (currentSearchInput === "")  {
      currentRecipes = [...activeRecipes]
    }else {
      currentRecipes.push(...activeRecipes.filter((recipe) => {
        if (recipe.name.indexOf(currentSearchInput) !== -1 && currentRecipes.indexOf(recipe) === -1)  {
          return true
        }
        return false
      }))
    }
    
    
    for (let recipe of currentRecipes) {
      //Check if the recipe is in working mode
      let foundWorkingRecipe = recipes.find((r) => r.id === recipe.id && r.is_working_version)
      let foundCreatedByAccount = allAccounts.find((a) => a.id === recipe.created_by_account_id)
      currentRecipesList[recipe.id] = {
        data: recipe,
        columns: {
          name: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{recipe.name}</div>
              <div className="StandardList-Row-Column-Secondary" style={{gap:4}}>
                <span>v{recipe.version}</span>
                {foundWorkingRecipe === undefined ?
                  <span>
                    {FormatDate(new Date(recipe.modified_on), "MM/d/yyyy")}
                  </span>
                :
                  <span style={{color: variables.textCritical}}>
                    Unsaved Changes
                  </span>
                }
              </div>
            </div>),
          },
          rack_type: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary"><Badge content={recipe.rack_type}/></div>
            </div>),
          },
          cultivar: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">Blackberries</div>
              <div className="StandardList-Row-Column-Secondary">Bitter Blue</div>
            </div>),
          },
          creator: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">{foundCreatedByAccount !== undefined ? foundCreatedByAccount.full_name : "N/A"}</div>
            </div>),
          },
          sharedWith: {
            render: (<div className="StandardList-Row-Column">
              <div className="StandardList-Row-Column-Primary">Noone</div>
            </div>),
          },
          deleteFunction: {
            render: (<div className="StandardList-Row-Column">
              <FaTrashAlt className="StandardList-Row-Column-Trash"/>
            </div>)
          },
        }
      }
    }

    SetRecipesList(currentRecipesList)
  }, [recipes, currentSearchInput])


  const columns = {
    name: {render: (<div className="StandardList-Header-Column">Name</div>), width: 15, resizable: true, sort_by: false},
    rack_type: {render: (<div className="StandardList-Header-Column">Rack Type</div>), width: 12, resizable: true, sort_by: false},
    cultivar: {render: (<div className="StandardList-Header-Column">Cultivar</div>), width: 10, resizable: true, sort_by: false},
    creator: {render: (<div className="StandardList-Header-Column">Creator</div>), width: 10, resizable: true, sort_by: false},
    sharedWith: {render: (<div className="StandardList-Header-Column">Shared With</div>), width: 10, resizable: true, sort_by: false},
    lastUpdated: {render: (<div className="StandardList-Header-Column">Last Updated</div>), minWidth: 20, width: "grow", resizable: true, sort_by: false},
    /*deleteFunction: {render: (<div className="StandardList-Header-Column"> </div>), widthType: "px", width: 20, resizable: false},*/
  }

  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  const recipeClicked = React.useCallback((recipe) => {
    if (recipes.find((r) => r.id === recipe.data.id && r.is_working_version) !== undefined)  {
      navigate("/repo/recipe/" + recipe.data.id + "/edit");
    }else {
      navigate("/repo/recipe/" + recipe.data.id);
    }
  })

  
  return (
    <>
      <InitialLoadAllAccountsBasicInfo accountIds={recipeAccountIds}/>
      <div id="RecipesPage">
        <StandardList 
            columns={columns} 
            showHeader={true}
            rows={recipesList}
            isSearchable={true}
            isMultiSelectable={true}
            resourcesAttached={false}
            onSearchInput={onSearchInput}
            onRowClicked={recipeClicked}
            loadingData={haveInitialData}/>

        {creatingRecipe && 
          <CreateRecipePopup closeCallback={closeCreateRecipePopup} completeCallback={creatingRecipeFinalized}/>
        }
      </div>
    </>
  )
} 

export default RecipesPage