import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { RoundToNearest } from '../../../../helpers';



const DataStatus_CondensateSubsystemCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const [leftDistanceComponentInfo, leftDistanceStatsusComponentInfo, rightDistanceComponentInfo, rightDistanceStatsusComponentInfo] = 
    useSelector((state) => selectBladeComponentInfo(state, bladeId, "RackMotionControl", ["ld", "lds", "rd", "rds"]))
  
  const leftDistance = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, leftDistanceComponentInfo))
  const leftDistanceStatus = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, leftDistanceStatsusComponentInfo))
  const rightDistance = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, rightDistanceComponentInfo))
  const rightDistanceStatus = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, rightDistanceStatsusComponentInfo))



  const formatDistanceStatus = (status) => {
    switch (status) {
      case 1:
        return "Closed"
      case 2:
        return "Nearby"
      case 3:
        return "Open"
      default:
        return "N/A"
    }
  }

  let dataListItems = [
    {
      label: "Left Distance", value: leftDistance.value, suffix: "mm",
    },
    {
      label: "Left Distance Status", value: formatDistanceStatus(leftDistanceStatus.value),
    },
    {
      label: "Right Distance", value: rightDistance.value, suffix: "mm",
    },
    {
      label: "Right Distance Status", value: formatDistanceStatus(rightDistanceStatus.value),
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Motion</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default DataStatus_CondensateSubsystemCell