import './TextInput.scss';
import React from 'react'
import {BsSearch} from 'react-icons/bs'
import { SearchIcon } from '../../assets/icons/Icons';


const TextInput = ({label, value, icon, stepper, placeHolder, prefix, suffix, multiline, clearButton, submitButton, submitButtonStyle, isPassword, onSubmit, minWidth, width, onChange, onBlur, disabled, inactive}) => {
  const [focusState, SetFocusState] = React.useState(false);
  const [pointerId, SetPointerId] = React.useState(null)
  const containerRef = React.useRef(null);

  const actualIcon = false;
  
  const inputRef = React.useRef(null);
  const [currentValue, setValue] = React.useState(value);
  const currentValueRef = React.useRef(null);

  React.useEffect(() => {
    setValue(value)
  }, [value])

  const textChanged = (e) =>  {
    if (onChange !== undefined) {
      let result = onChange(e.target.value)
      if (result !== undefined) {
        inputRef.current.value = result
        setValue(result)
      }else {
        setValue(e.target.value)
      }
    }else {
      setValue(e.target.value)
    }
  }

  React.useEffect(() => {
    currentValueRef.current = currentValue
  }, [currentValue])

  const handleFocus = (e) => {
    if (disabled || inactive) {
      SetFocusState(false)
      e.preventDefault()
      e.stopPropagation()
      return;
    }
    if (!focusState)  {
      SetFocusState(true)
    }
  }


  const blurDetectPointerDown = (e) => {
    if (focusState && containerRef.current) {
      console.log("Blur")
      blur()
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }

  const blur = React.useCallback(() => {
    SetFocusState(false)
    if (inputRef.current) {
      inputRef.current.blur()
    }
    if (onBlur !== undefined) {
      onBlur(currentValueRef.current)
    }
  })
  

  const focusEnd = (e) => {
    
  }

  const submitClicked = (e) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(currentValue)
    }
  }


  const inputProps = {style:{}}
  if (minWidth) {
    inputProps.style.minWidth = minWidth
  }
  if (width) {
    inputProps.style.width = width
  }
  if (isPassword) {
    inputProps.type = "password"
  }
  return (<>
    <div className={"TextInput" + (inactive || disabled ? (inactive ? " TextInput-Inactive" : " TextInput-Disabled") : (focusState ? " TextInput-Focused" : ""))}
     onClick={handleFocus}
     ref={containerRef}>
      <div>
        {label && <div className="TextInput-Label">{label}</div>}
        <div className="TextInput-Content noselect">
          {(icon !== false || prefix !== false) &&
            <div className="TextInput-Prefix">
              {icon !== false &&
                icon
              }
              {prefix !== false &&
                prefix
              }
            </div>
          }
          <div className="TextInput-DisplayArea noselect">
            {!multiline && 
              <input ref={inputRef} type="text" value={currentValue} placeholder={placeHolder !== false ? placeHolder: ""} onChange={textChanged} {...inputProps}/>
            }
            {multiline && 
              <textarea ref={inputRef} value={currentValue} placeholder={placeHolder !== false ? placeHolder: ""} onChange={textChanged} rows={3}></textarea>
            }
          </div>
          {(submitButton !== false) &&
            <div className={"TextInput-SubmitButton" + (submitButtonStyle !== null ? (" " + submitButtonStyle) : "")} onClick={submitClicked}>
              {submitButton}
            </div>
          }
        </div>
      </div>
    </div>
    <div className={"TextInput-BlurDetect" + (focusState ? " TextInput-BlurDetect-Active" : "")}
        onPointerDown={blurDetectPointerDown}></div>
    </>
  )
} 

TextInput.defaultProps = {
  value: "",
  icon: false,
  stepper: false,
  placeHolder: false,
  prefix: false,
  suffix: false,
  multiline: false,
  clearButton:false,
  minWidth: null,
  width: null,
  disabled: false,
  isPassword: false,
  inactive: false,
  submitButton: false,
  submitButtonStyle: null,
  onSubmit: undefined
}

TextInput.SearchIcon = SearchIcon

export default TextInput