import './FarmsPage.scss';

import React from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import DropDownInput from '../../components/input/DropDownInput.js'
import Button from '../../components/Button.js'

import ExpensesPage from '../Expenses/ExpensesPage';
import RevenuePage from '../Revenue/RevenuePage';
import InventoryPage from '../Inventory/InventoryPage';
import RecordsPage from '../Records/RecordsPage';
import ServicesPage from '../Services/ServicesPage';
import { getAllFacilities, getAllServicesForFacility, InitialLoadAllFacilities, selectAllFarmFacilities } from '../../redux/entities/Facilities.js';
import { useDispatch, useSelector } from 'react-redux';
import BladeInstancesPage from '../Services/GrowRacks/BladeInstancesPage';
import { getBladeByServiceId, selectAllBladeServiceIds, selectAllBlades } from '../../redux/entities/service/Blade';
import { getNDSByServiceId, selectAllNDS } from '../../redux/entities/service/NDS';
import { getHVACByServiceId, selectAllHVAC } from '../../redux/entities/service/HVAC';
import HVACsPage from '../Services/HVACs/HVACsPage';
import NDSsPage from '../Services/NDSs/NDSsPage';
import { IsSessionActive } from '../Account/Common.js';
import GrowZonesPage from '../GrowZones/GrowZonesPage.js';
import { useRenderingTrace } from '../../helpers.js';


const FarmsPage = ({pageHeaderHelper}) => {


  const navigate = useNavigate()
  const location = useLocation()



  const { farmID } = useParams();
  const [selectedFacility, SetSelectedFacility] = React.useState(farmID ? parseInt(farmID) : null);
  React.useLayoutEffect(() => {
    SetSelectedFacility(farmID ? parseInt(farmID) : null)
  }, [farmID])
  const allFarms = useSelector((state) => selectAllFarmFacilities(state))



  const validateLocation = () =>  {
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    if (splitLocation.length == 3 && !Number.isNaN(splitLocation[2]))  {
      navigate("/farms/" + splitLocation[2] + "/grow_zone")
    }else if (splitLocation.length >= 2 && Number.isNaN(splitLocation[2]))  {
      navigate("/farms/")
    }else {
      //SetSelectedFacility(parseInt(splitLocation[2]))
    }
  }
  React.useEffect(() => {
    let changedLocation = false
    if (selectedFacility === null) {
      if (allFarms.length > 0) {
        selectedFacilityChanged(allFarms[0].id)
        changedLocation = true
      }
    }
    if (!changedLocation) {
      validateLocation()
    }
  }, [location, allFarms, selectedFacility])
  React.useLayoutEffect(() => {
    validateLocation()
  }, [])




  const [facilitySelectionOptions, SetFacilitySelectionOptions] = React.useState([]);
  const selectedFacilityChanged = (value) => {
    const { pathname } = location;
    let splitLocation = pathname.split("/");
    splitLocation[2] = value
    SetSelectedFacility(value)
    navigate(splitLocation.join("/"))
    //SetIsAllSelected(value === "all")
  }
  
  React.useLayoutEffect(() => {
    //Check location to see if we are in services, if so we need to limit the options to not include all farms
    let currentFacilitySelectionOptions = []
    for (let facility of allFarms)  {
      currentFacilitySelectionOptions.push({
        value: facility.id,
        label: facility.name
      })
    }
    SetFacilitySelectionOptions(currentFacilitySelectionOptions)
  }, [allFarms])




  
  const tabs = [new TabControlTab("Grow Zone", "grow_zone", null, true),
                new TabControlTab("Blade", "blade"),
                new TabControlTab("Flow", "flow"),
                new TabControlTab("HVAC", "hvac")]
  
  const tabControlTabClickedRef = React.useRef(null);
  const [pageHeaderRightSideContent, SetPageHeaderRightSideContent] = React.useState(<></>)
  const setPageHeader = () => {
    pageHeaderHelper.current.Reset()
    pageHeaderHelper.current.SetTitle("Farms")
    
    pageHeaderHelper.current.SetTabControlTabs(tabs);
    pageHeaderHelper.current.SetTabControlTabClickCallback((tab) => {
      if (tabControlTabClickedRef.current) {
        tabControlTabClickedRef.current(tab);
      }
    });

    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              {pageHeaderRightSideContent}
              <DropDownInput placeholder={"Select Facility"} options={facilitySelectionOptions} value={selectedFacility} onSelectionChange={selectedFacilityChanged}/>
              {/*<Button content={"Create Farm"}/>*/}
            </div>
          </div>
        </>
      )
    })
    
    pageHeaderHelper.current.SubPageUpdateHeader()

  }
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [])
  React.useLayoutEffect(() => {
    setPageHeader()
  }, [facilitySelectionOptions])

  React.useLayoutEffect(() => {
    setPageHeader()
  }, [pageHeaderRightSideContent])


  const SetRightSideContent = (content) =>  {
    SetPageHeaderRightSideContent(content)
  }





  return (<>
    <InitialLoadAllFacilities/>
    <div className="PageWrapper_Standard">
      <TabControl activePathIndex={3} tabs={tabs}
        hideTabs={true}
        tabClickedRef={(ref) => {tabControlTabClickedRef.current=ref;}}
        content={
          <div id="FarmsPage">
            
            <div id="FarmsPage_TabContent">
              <Routes>
                <Route path="/grow_zone" element={
                  <GrowZonesPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={selectedFacility}/>
                }/>
                <Route path="/blade" element={
                  <BladeInstancesPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={selectedFacility}/>
                }/>
                <Route path="/flow" element={
                  <NDSsPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={selectedFacility}/>
                }/>
                <Route path="/hvac" element={
                  <HVACsPage setRightSideContent={(content) => SetRightSideContent(content)} selectedFacility={selectedFacility}/>
                }/>
                
                <Route path="*" element={<Navigate to="" replace />} />
              </Routes>
            </div>
          </div>
        }/>
    </div>
  </>)
} 

export default FarmsPage