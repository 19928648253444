import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';



const DataStatus_CondenserCell = ({ bladeId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
  
    const [fanSpeedComponentInfo, fanRPMComponentInfo] 
      = useSelector((state) => selectBladeComponentInfo(state, bladeId, "CondenserFan", ["control", "rpm"]), _.isEqual)

    const [highPressureComponentInfo, subcoolComponentInfo] 
      = useSelector((state) => selectBladeComponentInfo(state, bladeId, "CondenserSubcoolPressureTransducer", ["pressure", "sc"]), _.isEqual)

    const [highTempComponentInfo] 
      = useSelector((state) => selectBladeComponentInfo(state, bladeId, "ACDischargeOWTB", ["t"]), _.isEqual)
      
    
    
    let fanSpeed = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, fanSpeedComponentInfo), _.isEqual)
    let fanRPM = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, fanRPMComponentInfo), _.isEqual)
    let highPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, highPressureComponentInfo), _.isEqual)
    let subcool = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, subcoolComponentInfo), _.isEqual)
    let highTemp = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, highTempComponentInfo), _.isEqual)

    
    let dataListItems = [
      {label: "Fan Speed", value: fanSpeed.value, suffix: "%", visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 100, status: "normal"}
      }},
      {label: "Subcool", value: subcool.value, suffix: "°C", visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 10, status: "normal"}
      }},
      {label: "Pressure", value: highPressure.value, suffix: "psi", visualization: {
        type: "bar", info: {usesRange: true, min: 300, max: 500, status: "normal"}
      }},
      {label: "HS Temp", value: highTemp.value, suffix: "°C"},
    ]


    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Condenser</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            <DropDownButton content={<AdditionalOptions/>} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <DataStatus_DataList items={dataListItems}/>
        </div>
      </div>
    </>)
  }
  
  export default DataStatus_CondenserCell