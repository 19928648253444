import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../../api'
import { getAccountSessionData } from '../../../pages/Account/Common'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'



const updateNutrientReservoirsForNDS = (NDS) => {

  NDS.nutrientReservoirs = {}
  if (NDS.maps !== undefined && NDS.maps.versions["dosing_map"] !== undefined && NDS.maps.maps["dosing_map"] !== undefined) {
    NDS.haveMapVersions["dosing_map"] = NDS.maps.versions["dosing_map"]

    //Process map
    for (let [index, info] of Object.entries(NDS.maps.maps["dosing_map"]["entries"])) {
      NDS.nutrientReservoirs[parseInt(index)] = {...info, "runtime_information": {}, "item": {}}
    }
  
    for (let [key, value] of Object.entries(NDS["runtime_information"]))  {
      if (key.indexOf("dosing_reservoir_info_") !== -1) {
        let index = parseInt(key.substring("dosing_reservoir_info_".length, key.length))
        if (NDS.nutrientReservoirs[index] !== undefined)  {
          NDS.nutrientReservoirs[index]["runtime_information"] = JSON.parse(value)
        }
      }
    }


  }else {
    NDS.haveMapVersions["dosing_map"] = -1
  }

  

}

const updateAssignedBladeZoneMapForNDS = (NDS) => {

  if (NDS.maps !== undefined && NDS.maps.versions["assigned_blade_zone_map"] !== undefined && NDS.maps.maps["assigned_blade_zone_map"] !== undefined) {
    NDS.haveMapVersions["assigned_blade_zone_map"] = NDS.maps.versions["assigned_blade_zone_map"]

  }else {
    NDS.haveMapVersions["assigned_blade_zone_map"] = -1
  }
}

const updateCurrentActionsForNDS = (NDS) => {
  NDS.currentActionInfo = {}
  if (NDS.runtime_information["control_info_state"] !== undefined)  {
    NDS.currentActionInfo = JSON.parse(NDS.runtime_information["control_info_state"])
  }

  NDS.currentAction = "N/A"
  NDS.currentSubaction = null

  if (NDS.runtime_information["control_state"] !== undefined) {
    switch (NDS.runtime_information["control_state"]) {
      case "waiting":
        NDS.currentAction = "Idle"
        NDS.currentSubaction = null              
        break;

      case "cleanse":
        NDS.currentAction = "Cleaning"
        if (NDS.runtime_information["subcontrol_state"] !== undefined) {
          switch (NDS.runtime_information["subcontrol_state"]) {
            case "cleansing-return":
              NDS.currentSubaction = "Return"
              break

            case "cleansing-supply":
              NDS.currentSubaction = "Supply"
              break
              

            case "cleansing-only-supply":
              NDS.currentSubaction = "Supply"
              break
              
        
            default:
              NDS.currentSubaction = NDS.runtime_information["subcontrol_state"]
              break
          }
        }
        break;

    
      case "prime_dosing_reservoir":
        if (NDS.currentActionInfo["reservoir_index"] !== undefined) {
          NDS.currentAction = "Priming Reservoir #" + NDS.currentActionInfo["reservoir_index"].toString()
        }else {
          NDS.currentAction = "Priming Reservoir #X"
        }      
        NDS.currentSubaction = null
        break;
  
      case "rack_dose":
        if (NDS.currentActionInfo["zone_uid"] !== undefined) {
          NDS.currentAction = "Dosing Zone " + NDS.currentActionInfo["zone_uid"]
        }else {
          NDS.currentAction = "Dosing Zone #X"
        }
        NDS.currentSubaction = null

        if (NDS.runtime_information["subcontrol_state"] !== undefined) {
          switch (NDS.runtime_information["subcontrol_state"]) {
            case "pending_rack_ready":
              NDS.currentSubaction = "Waiting for zone"
              break

            case "filling_rack_to_minimum_volume":
              NDS.currentSubaction = "Filling to minimum volume"
              break

            case "pending_rack_liquid":
              NDS.currentSubaction = "Mixing"
              break

            case "running_dosing_queue":
              NDS.currentSubaction = "Injecting"
              break

            case "calculate_dose":
              NDS.currentSubaction = "Calculating"
              break

            case "running_water_top_up":
              NDS.currentSubaction = "Topping Up Water"
              break

            case "pause_pumping_for_dosing_queue":
              NDS.currentSubaction = "Blade pausing pumping for injection"
        
            case "resume_pump_for_dosing_queue_for_mixing":
              NDS.currentSubaction = "Blade resuming pumping for mixing"

            case "mix_for_dosing_queue":
              NDS.currentSubaction = "Mixing for dosing queue"

            default:
              NDS.currentSubaction = NDS.runtime_information["subcontrol_state"]
              break
          }
        }
        break
      case "rack_cleanse_flush":
        if (NDS.currentActionInfo["zone_uid"] !== undefined) {
          NDS.currentAction = "Cleansing Zone " + NDS.currentActionInfo["zone_uid"]
        }else {
          NDS.currentAction = "Cleansing Zone #X"
        }
        NDS.currentSubaction = null

        if (NDS.runtime_information["subcontrol_state"] !== undefined) {
          switch (NDS.runtime_information["subcontrol_state"]) {
        
            default:
              NDS.currentSubaction = NDS.runtime_information["subcontrol_state"]
              break
          }
        }
        break;

        
      default:
        NDS.currentAction = NDS.runtime_information["control_state"]
        break
    }
  }


}


const processNDSFromAPI = (state, NDS) =>  {
  NDS.numberOfSensorBanks = null
  NDS.sensorBanks = null
  NDS.sensors = null
  if (NDS.haveMapVersions === undefined)  {
    NDS.haveMapVersions = {}
  }
  
  if (NDS.maps === undefined)  {
    //NDS.maps = {versions:{}}
  }
  if (NDS.runtime_information === undefined)  {
    NDS.runtime_information = {}
  }


  if (NDS.retrievingMapVersionsStatus === undefined)  {
    NDS.retrievingMapVersionsStatus = "idle"
  }
  if (NDS.retrievingMapsStatus === undefined)  {
    NDS.retrievingMapsStatus = "idle"
  }
  if (NDS.retrievingStatus === undefined)  {
    NDS.retrievingStatus = "idle"
  }

  if (NDS.updatingNDSAssignedBladeZoneEntriesStatus === undefined)  {
    NDS.updatingNDSAssignedBladeZoneEntriesStatus = "idle"
  }


  
  if (NDS.loading_status_data === undefined)  {
    NDS.loading_status_data = "idle"
  }
  if (NDS.loading_live_data === undefined)  {
    NDS.loading_live_data = "idle"
  }



  updateCurrentActionsForNDS(NDS)
  updateNutrientReservoirsForNDS(NDS)
  updateAssignedBladeZoneMapForNDS(NDS)

  return NDS
}

const processNDSPropertyFromAPI = (NDS, key, value) =>  {
  console.log(key, value)
  switch (key)  {
    case "number_of_sensor_banks":
      NDS.numberOfSensorBanks = parseInt(value)
    case "sensor_banks":
      NDS.sensorBanks = value
    case "sensors":
      NDS.sensors = value
    case "nutrient_reservoirs":
      NDS.nutrientReservoirs = value
    default:
      break
  }
}


export const getAllFlows = createAsyncThunk('Flow/getAllFlows', async ({}, { getState }) => {
  return await FetchPost(APIRepository.Flow.GetAllFlows, {
    ...getAccountSessionData(getState()),
  })  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.loadingAllFlowsStatus === 'pending') {
      return false
    }
  },
})

export const getNDSById = createAsyncThunk('NDS/getNDSById', async ({NDSId, NDSIds}, { getState }) => {
  console.log(NDSId)
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (NDSId !== undefined) {
    payload.nds_id = NDSId
  }
  if (NDSIds !== undefined) {
    payload.nds_ids = NDSIds
  }
  return await FetchPost(APIRepository.NDS.GetNDSById, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})





export const getNDSByServiceId = createAsyncThunk('NDS/getNDSByServiceId', async ({NDSServiceId, NDSServiceIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (NDSServiceId !== undefined) {
    payload.service_id = NDSServiceId
  }
  if (NDSServiceIds !== undefined) {
    payload.service_ids = NDSServiceIds
  }
  return await FetchPost(APIRepository.NDS.GetNDSByServiceId, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})



export const getNDSStatusById = createAsyncThunk('NDS/getNDSStatusById', async ({NDSId, NDSIds}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    nds_id: NDSId,
    nds_ids: NDSIds
  }
  return await FetchPost(APIRepository.NDS.GetNDSStatusById, payload)  
})


export const getNDSProperty = createAsyncThunk('NDS/getNDSProperty', async ({NDSId, key, keys}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    nds_id: NDSId
  }  
  if (key !== undefined) {
    payload.key = key
  }
  if (keys !== undefined) {
    payload.keys = keys
  }
  return await FetchPost(APIRepository.NDS.GetNDSProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})

export const setNDSProperty = createAsyncThunk('NDS/setNDSProperty', async ({NDSId, key, value, values, callback}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    nds_id: NDSId
  }  
  if (key !== undefined && value !== undefined) {
    payload.key = key
    payload.value = value
  }
  if (values !== undefined) {
    payload.values = values
  }
  return await FetchPost(APIRepository.NDS.SetNDSProperty, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.status === 'pending') {
      return false
    }
  },
})




export const manageNDSAssignedBladeZoneEntry = createAsyncThunk('NDS/manageNDSAssignedBladeZoneEntry', async ({NDSId, portIndex, changeType, entryInfo, callback}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    nds_id: NDSId,
    port_index: portIndex,
    change_type: changeType,
    entry_info: entryInfo,
  }  
  return await FetchPost(APIRepository.NDS.ManageNDSAssignedBladeZoneEntry, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    if (NDS.updatingNDSAssignedBladeZoneEntriesStatus === 'pending') {
      return false
    }
  },
})




export const getNDSMapVersions = createAsyncThunk('NDS/getNDSMapVersions', async ({NDSId}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    nds_id: NDSId
  }
  return await FetchPost(APIRepository.NDS.GetNDSMapVersions, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    const foundNDS = NDS.NDS.find((n) => n.id === args.NDSId)
    if (foundNDS !== undefined) {
      if (foundNDS.retrievingMapVersionsStatus === "pending") {
        return false
      }
    }else {
      return false
    }
  },
})




export const getNDSMaps = createAsyncThunk('NDS/getNDSMaps', async ({NDSId, mapKeys}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    nds_id: NDSId,
    map_keys: mapKeys
  }
  return await FetchPost(APIRepository.NDS.GetNDSMaps, payload)  
},
{
  condition: (args, { getState }) => {
    const { NDS } = getState()
    const foundNDS = NDS.NDS.find((n) => n.id === args.NDSId)
    if (foundNDS !== undefined) {
      if (foundNDS.retrievingMapsStatus === "pending") {
        return false
      }
    }else {
      return false
    }
  },
})

export const NDSsSlice = createSlice({
  name: 'NDS',
  initialState: {
    NDS:  [

    ],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false,

    loadingAllFlowsStatus: "idle",
    loadedAllFlows: false,
  },
  reducers: {
    
  },
  extraReducers: {
    [getAllFlows.pending]: (state) => {
      state.loadingAllFlowsStatus = 'pending';
    },
    [getAllFlows.fulfilled]: (state, action) => {
      state.loadingAllFlowsStatus = 'fulfilled';
      if (action.payload.error !== undefined && action.payload.error !== null)  {
        //Failed in some way
      }else {
        state.loadedAllFlows = true
        if (action.payload.flows !== null) {
          for (let flow of action.payload.flows) {
            if (flow) {
              let exists = false
              for (let flowIndex in state.NDS) {
                if (state.NDS[flowIndex].id === flow.id) {
                  //Get the UID

                  state.NDS[flowIndex] = processNDSFromAPI(state, { ...state.NDS[flowIndex], ...flow })
                  exists = true
                  break
                }
              }
              if (!exists) {
                state.NDS.push(processNDSFromAPI(state, flow))
              }
            }
          }
        }
      }

    },
    [getAllFlows.rejected]: (state) => {
      state.loadingAllFlowsStatus = 'rejected';
    },

    [getNDSById.pending]: (state) => {
      state.status = 'pending';
    },

    [getNDSById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      /*if (action.payload.nds !== null) {
        state.NDS.push(processNDSFromAPI(action.payload.nds))
      }*/
      if (action.payload.ndss !== null) {
        for (let NDS of action.payload.ndss)  {
          if (NDS)  {
            state.NDS.push(processNDSFromAPI(state, NDS))
          }
        }
      }
    },

    [getNDSById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getNDSByServiceId.pending]: (state) => {
      state.status = 'pending';
    },

    [getNDSByServiceId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload.vertical_rack_groups)
      if (action.payload.ndss !== undefined && action.payload.ndss !== null) {
        for (let NDS of action.payload.ndss)  {
          if (NDS)  {
            let exists = false
            for (let existingNDS of state.NDS) {
              if (existingNDS.id === NDS.id)  {
                existingNDS = processNDSFromAPI(state, {...existingNDS, ...NDS})
                exists = true
                break
              }
            }
            if (!exists)  {
              state.NDS.push(processNDSFromAPI(state, NDS))              
            }
          }
        }
      }
    },

    [getNDSByServiceId.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getNDSStatusById.pending]: (state, action) => {
      state.NDS = state.NDS.map((NDS) => {
        if (action.meta.arg.NDSId !== undefined && NDS.id === action.meta.arg.NDSId)  {
          return {...NDS, loading_status_data: "pending"}
        }
        if (action.meta.arg.NDSIds !== undefined) {
          for (let NDSId of action.meta.arg.NDSIds) {
            if (NDS.id === NDSId)  {
              return {...NDS, loading_status_data: "pending"}
            }
          }
        }
        return NDS
      })
    },

    [getNDSStatusById.fulfilled]: (state, action) => {
      state.NDS = state.NDS.map((NDS) => {
        if (action.meta.arg.NDSId !== undefined && NDS.id === action.meta.arg.NDSId)  {
          return {...NDS, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime()}
        }
        if (action.meta.arg.NDSIds !== undefined) {
          for (let NDSId of action.meta.arg.NDSIds) {
            if (NDS.id === NDSId)  {
              return {...NDS, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime()}
            }
          }
        }
        return NDS
      })

      if (action.payload.nds !== null) {
        
        for (let NDSPayload of action.payload.nds)  {
          if (NDSPayload)  {
            state.NDS = state.NDS.map((NDS) => {
              if (NDS.id === NDSPayload.id) {
                return processNDSFromAPI(state, {...NDS, ...NDSPayload})
              }
            })
          }
        }
      }
      
    },

    [getNDSStatusById.rejected]: (state, action) => {
      state.NDS = state.NDS.map((NDS) => {
        if (action.meta.arg.NDSId !== undefined && NDS.id === action.meta.arg.NDSId)  {
          return {...NDS, loading_status_data: "rejected"}
        }
        if (action.meta.arg.NDSIds !== undefined) {
          for (let NDSId of action.meta.arg.NDSIds) {
            if (NDS.id === NDSId)  {
              return {...NDS, loading_status_data: "rejected"}
            }
          }
        }
        return NDS
      })
    },

    [getNDSProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [getNDSProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      //console.log(action.payload)
      
      const NDS = state.NDS.find((n) => { return n.id === action.meta.arg.NDSId; })
      if (NDS !== undefined)  {
        //Do something with the resulting property
        if (action.payload.value !== null) {
          processNDSPropertyFromAPI(NDS, action.meta.arg.key, action.payload.value)
        }
        if (action.payload.values !== null) {
          for (let [key, value] of Object.entries(action.payload.values))  {
            processNDSPropertyFromAPI(NDS, key, value)
          }
        }
      }
    },

    [getNDSProperty.rejected]: (state) => {
      state.status = 'rejected';
    },

    [setNDSProperty.pending]: (state) => {
      state.status = 'pending';
    },

    [setNDSProperty.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
      //Do something with the resulting property
    },

    [setNDSProperty.rejected]: (state, action) => {
      state.status = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },


    [manageNDSAssignedBladeZoneEntry.pending]: (state, action) => {
      state.NDS = state.NDS.map((NDS) => {
        if (action.meta.arg.NDSId !== undefined && NDS.id === action.meta.arg.NDSId)  {
          return {...NDS, updatingNDSAssignedBladeZoneEntriesStatus: "pending"}
        }
        return NDS
      })
    },

    [manageNDSAssignedBladeZoneEntry.fulfilled]: (state, action) => {
      state.NDS = state.NDS.map((NDS) => {
        if (action.meta.arg.NDSId !== undefined && NDS.id === action.meta.arg.NDSId)  {
          return {...NDS, updatingNDSAssignedBladeZoneEntriesStatus: "fulfilled"}
        }
        return NDS
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true)
      }
    },

    [manageNDSAssignedBladeZoneEntry.rejected]: (state, action) => {
      state.NDS = state.NDS.map((NDS) => {
        if (action.meta.arg.NDSId !== undefined && NDS.id === action.meta.arg.NDSId)  {
          return {...NDS, updatingNDSAssignedBladeZoneEntriesStatus: "rejected"}
        }
        return NDS
      })
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },

    [getNDSMapVersions.pending]: (state, action) => {
      if (action.meta.arg.NDSId !== undefined) {
        const foundNDS = state.NDS.find((n) => n.id === action.meta.arg.NDSId)
        if (foundNDS !== undefined) {
          foundNDS.retrievingMapVersionsStatus = "pending"
        }
      }      
    },
    [getNDSMapVersions.fulfilled]: (state, action) => {
      if (action.meta.arg.NDSId !== undefined) {
        const foundNDS = state.NDS.find((n) => n.id === action.meta.arg.NDSId)
        if (foundNDS !== undefined) {
          foundNDS.retrievingMapVersionsStatus = "fulfilled"
          for (let [key, version] of Object.entries(action.payload.versions)) {
            foundNDS.maps.versions[key] = version
          }
        }
      }
    },

    [getNDSMapVersions.rejected]: (state, action) => {
      if (action.meta.arg.NDSId !== undefined) {
        const foundNDS = state.NDS.find((n) => n.id === action.meta.arg.NDSId)
        if (foundNDS !== undefined) {
          foundNDS.retrievingMapVersionsStatus = "rejected"
        }
      }

    },


    [getNDSMaps.pending]: (state, action) => {
      if (action.meta.arg.NDSId !== undefined) {
        const foundNDS = state.NDS.find((n) => n.id === action.meta.arg.NDSId)
        if (foundNDS !== undefined) {
          foundNDS.retrievingMapsStatus = "pending"
        }
      }      
    },
    [getNDSMaps.fulfilled]: (state, action) => {
      if (action.meta.arg.NDSId !== undefined) {
        const foundNDS = state.NDS.find((n) => n.id === action.meta.arg.NDSId)
        if (foundNDS !== undefined) {
          foundNDS.retrievingMapsStatus = "fulfilled"
          for (let [key, version] of Object.entries(action.payload.versions)) {
            foundNDS.maps.versions[key] = version
          }
          for (let [key, map] of Object.entries(action.payload.maps)) {
            foundNDS.maps.maps[key] = map
          }

          updateNutrientReservoirsForNDS(foundNDS)
          updateAssignedBladeZoneMapForNDS(foundNDS)
        }
      }
    },

    [getNDSMaps.rejected]: (state, action) => {
      if (action.meta.arg.NDSId !== undefined) {
        const foundNDS = state.NDS.find((n) => n.id === action.meta.arg.NDSId)
        if (foundNDS !== undefined) {
          foundNDS.retrievingMapsStatus = "rejected"
        }
      }

    },
  }
})





export const InitialLoadAllFlows = ({}) => {
  const dispatch = useDispatch()

  const loadedAllFlows = useSelector((state) => state.blade.loadedAllFlows)
  const loadingAllFlowsStatus = useSelector((state) => state.blade.loadingAllFlowsStatus)
  useEffect(() => {
    if (!loadedAllFlows && loadingAllFlowsStatus !== "pending")   {
      dispatch(getAllFlows({}))
    }
  }, [loadedAllFlows, loadingAllFlowsStatus])
}

export const useMaintainFlowStatus = ({NDSIds, NDSUIDs, interval=2000}) => {
  
  const dispatch = useDispatch()
  const allNDSs = useSelector((state) => selectAllNDS(state))
  /*Load NDS status data*/
  const validateNDSStatusDataToLoad = () => {
    let NDSsToLoadStatusData = []
    let currentTime = new Date().getTime()
    
    let NDSsToValidate = []
    if (NDSIds !== undefined)  {
      for (let NDSId of Object.values(NDSIds)) {
        let foundNDS = allNDSs.find((b) => b.id === NDSId)
        if (foundNDS !== undefined)  {
          NDSsToValidate.push(foundNDS)
        }
      }
    }
    if (NDSUIDs !== undefined)  {
      for (let NDSUID of Object.values(NDSUIDs)) {
        let foundNDS = allNDSs.find((b) => b.uid === NDSUID)
        if (foundNDS !== undefined)  {
          if (NDSsToValidate.find((b) => b.uid === NDSUID) === undefined)  {
            NDSsToValidate.push(foundNDS)
          }
        }
      }
    }
    for (let NDS of NDSsToValidate) {
      let requiresStatusUpdate = false
      if (NDS.loading_status_data === "idle") {
        requiresStatusUpdate = true
      }else if (NDS.loading_status_data === "fulfilled" || NDS.loading_status_data === "rejected") {
        if (NDS.last_status_data_loaded_on !== undefined) {
          let elapsedTime = currentTime - NDS.last_status_data_loaded_on
          if (elapsedTime > interval) {
            requiresStatusUpdate = true
          }
        }
      }
      if (requiresStatusUpdate) {
        NDSsToLoadStatusData.push(NDS.id)
      }
    }

    if (NDSsToLoadStatusData.length > 0)  {
      dispatch(getNDSStatusById({NDSIds: NDSsToLoadStatusData}))
    }
  }
  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateNDSStatusDataToLoad()
    }, interval / 10);
    validateNDSStatusDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allNDSs, NDSIds, NDSUIDs]);

  return [{NDSUIDs: NDSUIDs}]
}


export const useMaintainLatestMaps = ({NDSId, mapKeys, interval=5000}) => {
  
  const dispatch = useDispatch()
  const allNDSs = useSelector((state) => selectAllNDS(state))
  const activeNDS = allNDSs.find((n) => n.id === NDSId)
  const retrievingMapVersionsStatus = useSelector(state => state.NDS.retrievingMapVersionsStatus)
  const retrievingMapsStatus = useSelector(state => state.NDS.retrievingMapsStatus)
 
  const performMapValidationCheck = () => {
    if (retrievingMapVersionsStatus !== "pending")  {
      dispatch(getNDSMapVersions({NDSId: NDSId}))
    }
  }
  useEffect(() => {
    const mapValidationCheckInterval = setInterval(() => {
      performMapValidationCheck()
    }, interval);
    performMapValidationCheck()
    return () => clearInterval(mapValidationCheckInterval);
  }, [NDSId]);

  //Validate the dosing map that we have is the correct version
  const performMapRetrieval = () => {
    if (retrievingMapsStatus !== "pending") {
      let mapsRequireUpdate = []
      for (let key of Object.keys(activeNDS.maps.versions))  {
        if (activeNDS.haveMapVersions[key] !== undefined)  {
          if (activeNDS.maps.versions[key] !== activeNDS.haveMapVersions[key])  {
            mapsRequireUpdate.push(key)
          }
        }else {
          mapsRequireUpdate.push(key)
        }
      }

      if (mapsRequireUpdate.length > 0) {
        dispatch(getNDSMaps({NDSId: NDSId, mapKeys: mapsRequireUpdate}))
      }
    }  
  }
  useEffect(() => {
    if (activeNDS !== undefined && activeNDS.maps !== undefined && activeNDS.maps.versions !== undefined)  {
      const mapValidationCheckInterval = setInterval(() => {
        performMapRetrieval()
      }, interval);
      performMapRetrieval()
      return () => clearInterval(mapValidationCheckInterval);
    }
  }, [activeNDS]);

 
}


// Action creators are generated for each case reducer function
export const { } = NDSsSlice.actions

export default NDSsSlice.reducer

export const selectAllNDS = state => state.NDS.NDS
