import '../GrowZonesPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalOptions } from '../../../assets/icons/Icons';
import DropDownButton from '../../../components/DropDownButton';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeIdsForBladeZoneUID, selectBladeLiveDataByComponentInfo, selectBladeUniqueConfigurationByName, selectBladeZoneTypeByUID } from '../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../components/DataStatus/DataStatus';
import { parseBool, RoundToNearest } from '../../../helpers';



const GrowZone_StatusContent_CondensateCell = ({ zoneUID }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  
  const energyComponentId = (zoneType === "nursery" ? bladeIds["nursery"] : bladeIds["environment"])
  
  const [pumpStateComponentInfo, floatStateComponentInfo, currentVolumeComponentInfo, avgEvapImperialComponentInfo, avgEvapMetricComponentInfo] 
    = useSelector((state) => selectBladeComponentInfo(state, energyComponentId, "CondensateSubsystem", ["p_state", "fs_state", "current_total_condensate_volume", "average_evap_metric", "average_evap_imperial"]), _.isEqual)
  const pumpState = useSelector((state) => selectBladeLiveDataByComponentInfo(state, energyComponentId, pumpStateComponentInfo), _.isEqual)
  const floatState = useSelector((state) => selectBladeLiveDataByComponentInfo(state, energyComponentId, floatStateComponentInfo), _.isEqual)
  const currentVolume = useSelector((state) => selectBladeLiveDataByComponentInfo(state, energyComponentId, currentVolumeComponentInfo), _.isEqual)
  const avgEvapImperial = useSelector((state) => selectBladeLiveDataByComponentInfo(state, energyComponentId, avgEvapImperialComponentInfo), _.isEqual)
  const avgEvapMetric = useSelector((state) => selectBladeLiveDataByComponentInfo(state, energyComponentId, avgEvapMetricComponentInfo), _.isEqual)


  
  let condensateStatus = "Idle"
  if (parseBool(pumpState.value)) {
    condensateStatus = "Pumping"
  }else if (parseBool(floatState.value)) {
    condensateStatus = "Float Tripped"
  }



  let dataListItems = [
    {
      label: "Current Volume", value: currentVolume.value, suffix: "L"
    },
    {
      label: "Avg Evap (I)", value: RoundToNearest(avgEvapImperial.value * 96, 0.01), suffix: "lbs/hr"
    },
    {
      label: "Avg Evap (M)", value: avgEvapMetric.value, suffix: "g/m2/s"
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Condensate</div>
          <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{condensateStatus}</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default GrowZone_StatusContent_CondensateCell