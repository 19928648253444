import './RecipeTimeline.scss';
import React from 'react';

import {FiEdit} from 'react-icons/fi'
import useMeasure from '../../../useMeasure';


const cycleColors = [
    {background: "#E8DECF", foreground: "#916A00"},
    {background: "#E8D0CF", foreground: "#D82C0D"},
    {background: "#CEE8DE", foreground: "#008060"},
]


export const RecipeTimeline = ({
        recipe, 
        timelineItemSelected, selectedTimelineItem, timelineSelectable,
        linkToManage, manageTimelineSelected,
        recipeTimelineEventEntries, onTimelineEventEntryClicked,
        selectedEventKey,
        showProgress, currentProgress, showDuration, showDayTicks
    }) => {

    const [visualizationRef, { width: visualizationWidth}] = useMeasure()
    const [lastVisualizationWidth, SetLastVisualizationWidth] = React.useState(0);
    const [visualizationTicks, SetVisualizationTicks] = React.useState([]);
    const [recipeDuration, SetRecipeDuration] = React.useState(0);
    const [totalDurationDisplay, SetTotalDurationDisplay] = React.useState("");


    const [timeline, SetTimeline] = React.useState([]);
    const [germinationCycles, SetGerminationCycles] = React.useState([]);
    const [nurseryCycles, SetNurseryCycles] = React.useState([]);
    const [growZoneCycles, SetGrowZoneCycles] = React.useState([]);


    const SelectManageTimeline = React.useCallback(() => {
       if (manageTimelineSelected !== undefined)  {
        manageTimelineSelected()
       }
    });

    const timelineItemClicked = React.useCallback((timelineItem) => {
        if (selectedTimelineItem.id != timelineItem.id)  {
            if (timelineItemSelected !== undefined)    {
                timelineItemSelected(timelineItem)
            }
        }
    })
 
    
  React.useEffect(() => {
    if (recipe === undefined)   {
        SetTimeline([])
        SetRecipeDuration(0)
        SetVisualizationTicks([])
        SetTotalDurationDisplay("")
        return
    }



    
    //Produce the timeline
    let timeline = []
    let currentGerminationCycles = []
    let currentNurseryCycles = []
    let currentGrowZoneCycles = []

    let totalDuration = 0
    if (recipe.timeline_items) {
        let recipeTimelineItems = [...recipe.timeline_items].sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
        for (let timelineItem of recipeTimelineItems)    {
            if (timelineItem.item)  {
                timeline.push({...timelineItem})
                switch (timelineItem.type)  {
                    case "duration_action":
                        if (timelineItem.item.type === "place_into_germination_chamber_duration") {
                            totalDuration += timelineItem.item.duration
                            currentGerminationCycles.push({...timelineItem})
                        }
                        break
                    case "germination_cycle":
                        currentGerminationCycles.push({...timelineItem})
                        totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                        break
                    case "nursery_cycle":
                        currentNurseryCycles.push({...timelineItem})
                        totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                        break
                    
                    case "grow_zone_cycle":
                        currentGrowZoneCycles.push({...timelineItem})
                        totalDuration += timelineItem.item.iterations * timelineItem.item.duration
                        break
                    
                    default:
                        break
                }
            }
        }
    }
    
    SetRecipeDuration(totalDuration)

    //Cycle selection options and visualization sizing
    let currentCycleSelectionOptions = []
    let currentCycleContribution = 0;
    for (let timelineItem of timeline) {
      if (timelineItem.type === "duration_action") {
        if (timelineItem.item.type === "place_into_germination_chamber_duration") {
            let cycle = timelineItem.item
            let cycleContribution = cycle.duration / totalDuration
            timelineItem.width = visualizationWidth * cycleContribution
            timelineItem.x = visualizationWidth * currentCycleContribution
            timelineItem.startsOn = currentCycleContribution * totalDuration * 1000
            currentCycleContribution += cycleContribution
            timelineItem.endsOn = currentCycleContribution * totalDuration * 1000
            
            if (timelineSelectable) {
            //currentCycleSelectionOptions.push({ value: (cycle.temp_id ? "t" + cycle.temp_id.toString() : cycle.id), label: cycle.name })
            }
        }
      }else if (timelineItem.type === "germination_cycle" || timelineItem.type === "nursery_cycle" || timelineItem.type === "grow_zone_cycle") {
        let cycle = timelineItem.item

        let cycleContribution = (cycle.duration * cycle.iterations) / totalDuration
        timelineItem.width = visualizationWidth * cycleContribution
        timelineItem.x = visualizationWidth * currentCycleContribution
        timelineItem.startsOn = currentCycleContribution * totalDuration * 1000
        currentCycleContribution += cycleContribution
        timelineItem.endsOn = currentCycleContribution * totalDuration * 1000
        
        if (timelineSelectable) {
          currentCycleSelectionOptions.push({ value: (cycle.temp_id ? "t" + cycle.temp_id.toString() : cycle.id), label: cycle.name })
        }
      }
    }
    SetTimeline(timeline)
    SetGerminationCycles(currentGerminationCycles)
    SetNurseryCycles(currentNurseryCycles)
    SetGrowZoneCycles(currentGrowZoneCycles)
    



    //Calculate visualization
    const desiredNumberOfTicks = 12
    const hourDuration = 60 * 60
    const dayDuration = hourDuration * 24
    const totalDurationInHours = Math.floor(totalDuration / hourDuration)
    const totalDurationInDays = Math.floor(totalDuration / dayDuration)
    const remainingHours = (totalDuration - (totalDurationInDays * dayDuration)) / hourDuration
    let tickDayDelta = Math.ceil(totalDurationInDays / desiredNumberOfTicks)
    let numberOfTicks, startingDayTick, ticksCoveringDuration, currentDay;
    if (tickDayDelta == 0)  {
      tickDayDelta = 1
      numberOfTicks = totalDurationInDays
      ticksCoveringDuration = currentDay = startingDayTick = 1
    }else {
      numberOfTicks = Math.floor(totalDurationInDays / tickDayDelta)
      ticksCoveringDuration = (numberOfTicks - 1) * tickDayDelta
      startingDayTick = Math.floor((totalDurationInDays - ticksCoveringDuration) / 2)

      currentDay = startingDayTick
    }

    let ticks = []
    while (startingDayTick < totalDurationInDays) {
        if (startingDayTick !== 0)  {
            ticks.push({
                day: startingDayTick, relativePosition: startingDayTick / totalDurationInDays
            })
        }
        startingDayTick += tickDayDelta
    }
    SetVisualizationTicks(ticks)


    let currentTotalDuration = ""
    if (totalDurationInDays !== 0) {
      currentTotalDuration += totalDurationInDays.toString() + "d"
      if (remainingHours !== 0)  {
        currentTotalDuration += " " + remainingHours.toString() + "h"
      }
    }else {
      currentTotalDuration += remainingHours.toString() + "h"
    }
    
    SetTotalDurationDisplay(currentTotalDuration)


  }, [recipe, visualizationWidth])

  
    return (
        <div className="RecipeTimelineBar-Container">
            <div className="RecipeTimelineBar">
                <div className="RecipeTimelineBar-Content" ref={visualizationRef}>
                    <div className="RecipeTimelineBar-Bars">
                        {timeline.map((timelineItem) => {
                            if ((timelineItem.type === "duration_action" && timelineItem.item.type === "place_into_germination_chamber_duration") || timelineItem.type === "germination_cycle" || timelineItem.type === "nursery_cycle" || timelineItem.type === "grow_zone_cycle") {
                                let cycle = timelineItem.item

                                
                                let margin = visualizationWidth * 0.005

                                let barStyles = {
                                    marginLeft: margin,
                                    marginRight: margin
                                } 
                                if (timelineSelectable) {
                                    barStyles.cursor = "pointer"
                                }
                                let isSelected = false
                                if (selectedTimelineItem !== undefined && selectedTimelineItem.id == timelineItem.id && timelineSelectable)  {
                                    barStyles.fontWeight = "bold"
                                    isSelected = true
                                }

                                

                                let barProps = {
                                    style:barStyles
                                }
                                if (timelineSelectable)  {
                                    barProps.onClick = () => {timelineItemClicked(timelineItem)}
                                }

                                

                                let barProgress = 0
                                if (showProgress)   {
                                    if (currentProgress !== undefined)  {
                                        if (currentProgress >= timelineItem.startsOn && currentProgress <= timelineItem.endsOn) {
                                            barProgress = 1 - ((timelineItem.endsOn - currentProgress) / (timelineItem.endsOn - timelineItem.startsOn))
                                        }else if (currentProgress > timelineItem.endsOn)   {
                                            barProgress = 1
                                        }
                                    }
                                }

                                let barContentWidth = timelineItem.width - (margin * 2)

                                let progressBarWidth = showProgress ? barContentWidth * barProgress : 0


                                let timelineXPositionEntries = []
                                for (let eventEntry of recipeTimelineEventEntries.filter((rTEE) => rTEE.timeline_item_id === timelineItem.id))   {
                                    let positionOfEntry = eventEntry.elapsed / (timelineItem.item.duration * 1000 * timelineItem.item.iterations)
                                    if (positionOfEntry < 0)    {
                                        positionOfEntry = 0
                                    }else if (positionOfEntry > 1)  {
                                        positionOfEntry = 1
                                    }
                                    let eventXPosition = barContentWidth * positionOfEntry
                                    timelineXPositionEntries.push({
                                        x: eventXPosition,
                                        key: eventEntry.key,
                                    })
                                }

                                timelineXPositionEntries.sort((a, b) => a.x - b.x)
                                //console.log(timelineXPositionEntries)
                                
                                let timelineEntries = []
                                let lastEntry = null
                                let groupedEventsSpace = 20
                                for (let xPositionEntry of timelineXPositionEntries)    {
                                    let lastEntryX = 0
                                    if (lastEntry !== null) {
                                        lastEntryX = lastEntry.xTotal / lastEntry.events.length
                                    }

                                    if (lastEntry === null || lastEntryX < xPositionEntry.x - groupedEventsSpace) {
                                        if (lastEntry !== null) {
                                            lastEntry.x = lastEntryX
                                        }
                                        lastEntry = {
                                            events: [xPositionEntry.key],
                                            xTotal: xPositionEntry.x
                                        }
                                        timelineEntries.push(lastEntry)
                                        
                                    }else {
                                        lastEntry.events.push(xPositionEntry.key)
                                        lastEntry.xTotal += xPositionEntry.x
                                    }
                                }
                                if (lastEntry !== null) {
                                    lastEntry.x = lastEntry.xTotal / lastEntry.events.length
                                }
                                return (
                                    <div className={"RecipeTimelineBar-Bar" + (isSelected ? " RecipeTimelineBar-SelectedBar" : "")}
                                        style={{
                                            width: timelineItem.width, 
                                            left: timelineItem.x,
                                        }} key={timelineItem.id}>
                                        <div className="RecipeTimelineBar-Bar-Content" {...barProps}>
                                            {showProgress && <>
                                                <div 
                                                    className="RecipeTimelineBar-Bar-ProgressBar"
                                                    style={{
                                                        width: progressBarWidth,
                                                    }}></div>
                                                {progressBarWidth > 0 && 
                                                    <div 
                                                        className="RecipeTimelineBar-Bar-ProgressBarLine"
                                                        style={{
                                                            left: progressBarWidth,
                                                            backgroundColor: "#000"
                                                        }}></div>
                                                }
                                            </>}

                                            {timelineEntries.map((entry) => {
                                                //console.log(entry)
                                                let timelineEntryProps = {style:{
                                                    left: entry.x
                                                }}
                                                let eventEntryClicked = () => {
                                                    onTimelineEventEntryClicked(entry.events)
                                                }
                                                let isSelected = entry.events.find((e) => e === selectedEventKey) !== undefined
                                                return <>
                                                    <div className={"RecipeTimelineBar-EventEntry" + (isSelected ? " RecipeTimelineBar-EventEntry-Selected" : "")} {...timelineEntryProps} onClick={eventEntryClicked}>
                                                        <div className="RecipeTimelineBar-EventEntry-Container">
                                                            <div className="RecipeTimelineBar-EventEntry-Content">
                                                                {entry.events.length === 1 && <>
                                                                    <div className={"RecipeTimelineBar-EventEntry-SingleEntry"}></div>
                                                                    <div className={"RecipeTimelineBar-EventEntry-SingleEntry-Selection"}></div>
                                                                </>}
                                                                {entry.events.length > 1 && <>
                                                                    <div className="RecipeTimelineBar-EventEntry-MultiEntry">
                                                                        <div className="RecipeTimelineBar-EventEntry-MultiEntry-Value noselect">
                                                                            {entry.events.length}
                                                                        </div>        
                                                                    </div>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            })}
                                        </div>
                                    </div>
                                )
                            }else {

                                return (
                                <div key={timelineItem.id}>
                                
                                </div>
                                )
                            }}
                        )}
                    </div>

               
                </div>
                
            </div>
        </div>
    )
} 

RecipeTimeline.defaultProps = {
  showDuration: true,
  showDayTicks: true,
  recipeTimelineEventEntries: [],
  selectedEventKey: false
}