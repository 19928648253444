import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo } from '../../../../redux/entities/service/Blade';



const DataStatus_SupplyFanCell = ({ bladeId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [currentSpeedComponentInfo, currentRPMComponentInfo] 
    = useSelector((state) => selectBladeComponentInfo(state, bladeId, "SupplyFan", ["control", "rpm"]), _.isEqual)
   
    let currentSpeed = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, currentSpeedComponentInfo), _.isEqual)
    let currentRPM = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, currentRPMComponentInfo), _.isEqual)
    

    let dataListItems = [
      {label: "Speed", value: currentSpeed.value, suffix: "%", visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 100, status: "normal"}
      }},
      {label: "RPM", value: currentRPM.value, visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 2800, status: "normal"}
      }}
    ]
    


    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Supply Fan</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            <DropDownButton content={<AdditionalOptions/>} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <DataStatus_DataList items={dataListItems}/>
        </div>
      </div>
    </>)
  }
  
  export default DataStatus_SupplyFanCell