import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import AnalogGauge from '../../../../components/AnalogGauge';
import { selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, setBladeRuntimeProperty } from '../../../../redux/entities/service/Blade';



const DataStatus_AeroponicsCell = ({ bladeId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
    const dispatch = useDispatch()

    const [liquidPressureComponentInfo] 
      = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrimaryLiquidSystemPressureTransducer", ["pressure"]), _.isEqual)

    let liquidPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, liquidPressureComponentInfo), _.isEqual)
  
    const [liquidStatusRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_reservoir_control_state"), _.isEqual)
    const liquidStatus = liquidStatusRuntimeInformation ? liquidStatusRuntimeInformation : "Idle"

    const [irrigationTestTimeRemainingRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_system_irrigation_test_finishes_on"), _.isEqual)
    const irrigationTestTimeRemaining = irrigationTestTimeRemainingRuntimeInformation ? Math.floor(parseFloat(irrigationTestTimeRemainingRuntimeInformation) - (new Date().getTime() / 1000)) : 0

    

    let additionalFunctions = [
      {
        key: "run_nozzle_test", label: "Nozzle Test", callback: () => {
          dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_system_requires_irrigation_test": 1 } }))
        }
      },
    ]
    
    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Nozzles</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            <DropDownButton content={<AdditionalOptions/>} options={additionalFunctions} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <div className="FlexContent-15 FlexContent-HFlex">
            <AnalogGauge value={liquidPressure.value} min={0} max={150} desiredMin={100} desiredMax={120} suffix={"psi"}/>
            {liquidStatus === "irrigation_test" && <>
              <div className="FlexContent-H-20 FlexContent-Center">
                <div className="Text-S14">Time Remaining: {Math.floor(irrigationTestTimeRemaining / 60)}:{Math.floor(irrigationTestTimeRemaining % 60).toString().padStart(2, '0')}</div>
                <Button status={"Primary-Inverted"} size={"Small"} content={"Add Time"}/>
              </div>
              <Button status={"Critical"} size={"Small"} content={"Stop"} onClick={() => {
                dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_system_requires_irrigation_test": 0 } }))
              }}/>
            </>}
          </div>
        </div>
      </div>
    </>)
  }
  
  export default DataStatus_AeroponicsCell