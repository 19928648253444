import './Walkthrough.scss';
import React from 'react';

import BadgeTimeline from '../components/BadgeTimeline.js'
import {MdClose} from 'react-icons/md'
import {Close} from '../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import { animated, useSpring } from 'react-spring';
import useMeasure from '../useMeasure.js';


const Walkthrough = ({
  id, title, subtitle, subHeaderContent, description, closeCallback,
  size, hideMainSeparators, contentAlignment,
  hasTimeline, timelineSteps, timelineStep, timelineStepChange,
  children, footer, allowOuterClickToClosePopup,
  
  initialWalkthroughStep, transitionToWalkthroughStep, transitionBackToWalkthroughStep, buildWalkthroughStepCallback,
  
  transitionNextCompletedCallback, transitionPreviousCompletedCallback
}) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const closePopup = () =>  {
    if (allowOuterClickToClosePopup)  {
      if (closeCallback !== undefined)  {
        closeCallback(false)
      }
    }
  }
  const forceClosePopup = () =>  {
    if (closeCallback !== undefined)  {
      closeCallback(true)
    }
  }

  const [currentTimelineStep, SetTimelineStep] = React.useState(undefined)
  React.useLayoutEffect(() => {
    if (timelineStep !== currentTimelineStep) {
      SetTimelineStep(timelineStep)
    }
  }, [timelineStep])
  const timelineStepChanged = (key) =>    {
      if (currentTimelineStep !== key)    {
        SetTimelineStep(key)
          if (timelineStepChange !== undefined) {
            timelineStepChange(key)
          }
      }
  }
  



    const [activeWalkthroughStep, SetActiveWalkthroughStep] = React.useState(null)
    React.useLayoutEffect(() => {
      if (activeWalkthroughStep === null && initialWalkthroughStep !== null && initialWalkthroughStep[0] !== null) {
        //SetWalkthroughStepContent({...walkthroughStepContent, [initialWalkthroughStep[0]]: loadWalkthroughStepCallback(initialWalkthroughStep)})
        SetActiveWalkthroughStep(initialWalkthroughStep[0] + "-" + initialWalkthroughStep[1])
        console.log("Setting initial walkthrough step " + initialWalkthroughStep[0] + " - " + initialWalkthroughStep[1])
      }
    }, [activeWalkthroughStep, initialWalkthroughStep])

    


    const [walkthroughTransitionStep, SetWalkthroughTransitionStep] = React.useState("idle")
    const [walkthroughTransitionDirection, SetWalkthroughTransitionDirection] = React.useState("")
    
    const [transitioningWalkthroughStepForward, SetTransitioningWalkthroughStepForward] = React.useState(null)
    const [pendingTransitionBackToWalkthroughSteps, SetPendingTransitionBackToWalkthroughSteps] = React.useState([])    
    React.useLayoutEffect(() => {
      if (transitionBackToWalkthroughStep)  {
        SetPendingTransitionBackToWalkthroughSteps([...pendingTransitionBackToWalkthroughSteps, transitionBackToWalkthroughStep])
      }
    }, [transitionBackToWalkthroughStep])
    React.useLayoutEffect(() => {
      if (transitioningWalkthroughStepForward === null && pendingTransitionBackToWalkthroughSteps.length > 0)   {
        SetTransitioningWalkthroughStepForward(activeWalkthroughStep)
        SetActiveWalkthroughStep(pendingTransitionBackToWalkthroughSteps[0][0] + "-" + pendingTransitionBackToWalkthroughSteps[0][1])
        SetPendingTransitionBackToWalkthroughSteps(pendingTransitionBackToWalkthroughSteps.filter((s) => s !== pendingTransitionBackToWalkthroughSteps[0]))

        SetWalkthroughTransitionStep("transitioning")
        SetWalkthroughTransitionDirection("previous")
  
  
        currentCardTransitionOutAnimationAPI.start({
          from: { left: 0, scale: 1.0},
          to: { left : walkthroughContainerWidth, scale: 0.7}
        })
        
        previousCardTransitionToCurrentCardAnimationAPI.start({
          from: { left: -walkthroughContainerWidth, scale: 0.7},
          to: { left : 0, scale: 1.0}
        })
  
        console.log("Transitioning back to walkthrough step " + pendingTransitionBackToWalkthroughSteps[0][0] + "-" + pendingTransitionBackToWalkthroughSteps[0][1])
      }
  }, [pendingTransitionBackToWalkthroughSteps, transitioningWalkthroughStepForward])



    const [transitioningWalkthroughStepBack, SetTransitioningWalkthroughStepBack] = React.useState(null)

    //const [transitioningToWalkthroughStep, SetTransitioningToWalkthroughStep] = React.useState(null)
    const [pendingTransitionToWalkthroughSteps, SetPendingTransitionToWalkthroughSteps] = React.useState([])    
    React.useLayoutEffect(() => {
      if (transitionToWalkthroughStep)  {
        SetPendingTransitionToWalkthroughSteps([...pendingTransitionToWalkthroughSteps, transitionToWalkthroughStep])
      }
    }, [transitionToWalkthroughStep])
    React.useLayoutEffect(() => {
        if (transitioningWalkthroughStepBack === null && pendingTransitionToWalkthroughSteps.length > 0)   {
          //SetTransitioningToWalkthroughStep()
          SetTransitioningWalkthroughStepBack(activeWalkthroughStep)
          SetActiveWalkthroughStep(pendingTransitionToWalkthroughSteps[0][0] + "-" + pendingTransitionToWalkthroughSteps[0][1])
          SetPendingTransitionToWalkthroughSteps(pendingTransitionToWalkthroughSteps.filter((s) => s !== pendingTransitionToWalkthroughSteps[0]))

          SetWalkthroughTransitionStep("transitioning")
          SetWalkthroughTransitionDirection("next")
    
    
          currentCardTransitionOutAnimationAPI.start({
            from: { left: 0, scale: 1.0},
            to: { left : -walkthroughContainerWidth, scale: 0.7}
          })
          
          nextCardTransitionToCurrentCardAnimationAPI.start({
            from: { left: walkthroughContainerWidth, scale: 0.7},
            to: { left : 0, scale: 1.0}
          })
    
          console.log("Transitioning to walkthrough step " + pendingTransitionToWalkthroughSteps[0][0] + "-" + pendingTransitionToWalkthroughSteps[0][1])
        }
    }, [pendingTransitionToWalkthroughSteps, transitioningWalkthroughStepBack])

    

    const [walkthroughSteps, SetWalkthroughSteps] = React.useState([])
    const updateWalkthroughSteps = () => {
        let currentWalkthroughSteps = []
        if (activeWalkthroughStep)  {
          currentWalkthroughSteps.push(activeWalkthroughStep)
        }
        if (transitioningWalkthroughStepForward)    {
          currentWalkthroughSteps.push(transitioningWalkthroughStepForward)
        }
        if (transitioningWalkthroughStepBack)    {
          currentWalkthroughSteps.push(transitioningWalkthroughStepBack)
        }
        SetWalkthroughSteps(currentWalkthroughSteps)
    }
    React.useLayoutEffect(() => {
      updateWalkthroughSteps()
    }, [])
    React.useLayoutEffect(() => {
      updateWalkthroughSteps()
    }, [activeWalkthroughStep, transitioningWalkthroughStepForward, transitioningWalkthroughStepBack])


    

  React.useLayoutEffect(() => {
    if (walkthroughTransitionStep === "done") {
      if (walkthroughTransitionDirection === "previous")  {
        currentCardTransitionOutAnimationAPI.start({
          from: { left: 0, scale: 1},
          to: { left : 0, scale: 1}
        })
        previousCardTransitionToCurrentCardAnimationAPI.start({
          from: { left: -walkthroughContainerWidth, scale: 0.7},
          to: { left : -walkthroughContainerWidth, scale: 0.7}
        })

        SetWalkthroughTransitionStep("idle")
        //SetActiveWalkthroughStep(transitioningBackToWalkthroughStep)
        SetTransitioningWalkthroughStepForward(null)
        SetWalkthroughSteps([activeWalkthroughStep])

      }else if (walkthroughTransitionDirection === "next")  {
        currentCardTransitionOutAnimationAPI.start({
          from: { left: 0, scale: 1},
          to: { left : 0, scale: 1}
        })
        nextCardTransitionToCurrentCardAnimationAPI.start({
          from: { left: walkthroughContainerWidth, scale: 0.7},
          to: { left : walkthroughContainerWidth, scale: 0.7}
        })
        
        SetWalkthroughTransitionStep("idle")
        //SetActiveWalkthroughStep(transitioningToWalkthroughStep)
        SetTransitioningWalkthroughStepBack(null)
        //SetTransitioningToWalkthroughStep(null)
        SetWalkthroughSteps([activeWalkthroughStep])
      }
    }
  }, [walkthroughTransitionStep, walkthroughTransitionDirection])




  const [currentCardTransitionOutAnimation, currentCardTransitionOutAnimationAPI] = useSpring(() => ({
    from: { left: 0 },
    onRest: () => {
      SetWalkthroughTransitionStep("completed")
    }
  }))


  React.useLayoutEffect(() => {
    if (walkthroughTransitionStep === "completed") {
      SetWalkthroughTransitionStep("done")

      if (walkthroughTransitionDirection === "previous")  {
        if (transitionPreviousCompletedCallback !== undefined)  {
          transitionPreviousCompletedCallback()
        }
      }else if (walkthroughTransitionDirection === "next")  {
        if (transitionNextCompletedCallback !== undefined)  {
          transitionNextCompletedCallback()
        }
      }

    }
  }, [walkthroughTransitionStep, walkthroughTransitionDirection])







  const [walkthroughContentHandleRef, { width: walkthroughContentWidth }] = useMeasure()

  const [walkthroughContainerHandleRef, { width: walkthroughContainerWidth, height: walkthroughContainerHeight}] = useMeasure()
  const [walkthroughHeaderHandleRef, { width: walkthroughHeaderWidth, height: walkthroughHeaderHeight}] = useMeasure()
  const [walkthroughFooterHandleRef, { width: walkthroughFooterWidth, height: walkthroughFooterHeight}] = useMeasure()

  const [nextCardTransitionToCurrentCardAnimation, nextCardTransitionToCurrentCardAnimationAPI] = useSpring(() => ({
    from: { left: walkthroughContainerWidth },
  }))

  const [previousCardTransitionToCurrentCardAnimation, previousCardTransitionToCurrentCardAnimationAPI] = useSpring(() => ({
    from: { left: walkthroughContainerWidth },
  }))



  const walkthroughScreenWrapperProps = {style:{}}
  if (id) {
    walkthroughScreenWrapperProps.id = id;
  }
  const walkthroughProps = {style:{}}

  //const [{ height: transitionBackToContentHeight, width: transitionBackToContentWidth}, {nodeRef: transitionBackToContentRef, handleNode: transitionBackToContentHandleRef }] = useMeasureWithNode()
  const [activeContentHandleRef, { height: activeContentHeight, width: activeContentWidth}] = useMeasure()
  //const [{ height: transitionToContentHeight, width: transitionToContentWidth}, {nodeRef: transitionToContentRef, handleNode: transitionToContentHandleRef }] = useMeasureWithNode()
  
  /*const [currentContentHeight, SetCurrentContentHeight] = React.useState("auto")
  const [currentContentWidth, SetCurrentContentWidth] = React.useState("auto")
  React.useLayoutEffect(() => {
    if (transitioningWalkthroughStepForward !== null)  {
      SetCurrentContentHeight(transitionBackToContentHeight)
      SetCurrentContentWidth(transitionBackToContentWidth)
    }else if (transitioningWalkthroughStepBack !== null)  {
      SetCurrentContentHeight(transitionToContentHeight)
      SetCurrentContentWidth(transitionToContentWidth)
    }else {
      SetCurrentContentHeight(activeContentHeight)
      SetCurrentContentWidth(activeContentWidth)
    }
  }, [activeContentHeight, transitionToContentHeight, transitionBackToContentHeight,
    activeContentWidth, transitionToContentWidth, transitionBackToContentWidth,
    transitioningWalkthroughStepForward, transitioningWalkthroughStepBack])*/



  let walkthroughStepContent = {}
  if (buildWalkthroughStepCallback !== undefined && walkthroughSteps.length > 0) {
    walkthroughSteps.map((key) =>    {
      let isTransitioning = false
      if (transitioningWalkthroughStepForward === key || transitioningWalkthroughStepBack === key)  {
        isTransitioning = true
      }
      let content = buildWalkthroughStepCallback(key.split("-"), isTransitioning) 
      walkthroughStepContent[key] = content
    })
  }

  if (size === "full_screen") {
    const walkthroughPointerProps = {style:{}}
    if (walkthroughTransitionStep !== "idle") {
      walkthroughPointerProps.style.pointerEvents = "none"
    }
  
    return (
      <div className={"Walkthrough_FullScreen" + (contentAlignment === "center" ? " Walkthrough_CenterContent" : "") + (isMobile ? " Walkthrough_Mobile" : (isTablet ? " Walkthrough_Tablet" : (isDesktop ? " Walkthrough_Desktop" : "") + (isWideDesktop ? " Walkthrough_WideDesktop" : "" ) ) )} {...walkthroughScreenWrapperProps} ref={walkthroughContentHandleRef}>
        <div className="Walkthrough" {...walkthroughPointerProps} ref={walkthroughContainerHandleRef}>
          <div className={"Walkthrough-Header"}> 
            <div className="Walkthrough-HeaderContent">
                <div className="Walkthrough-HeaderContent-Primary">
                  <div className="Walkthrough-HeaderTitle-Container">
                    <div className="Walkthrough-HeaderTitle">
                        {title}
                    </div>
                    {subtitle && <>
                      <div className="Walkthrough-HeaderSubtitle">
                          {subtitle}
                      </div>
                    </>}
                    {description && 
                      <div className="Walkthrough-HeaderTitle-Description">
                          {description}
                      </div>
                    }
                  </div>
                  <div className="Walkthrough-CloseButton" onClick={forceClosePopup}>
                    <Close/>
                  </div>
                </div>
                {(timelineSteps !== false || subHeaderContent) &&
                  <div className="Walkthrough-HeaderContent-Secondary">
                    {timelineSteps !== false &&
                      <BadgeTimeline steps={timelineSteps} currentStep={currentTimelineStep} onStepChanged={timelineStepChanged}/>
                    }
                    {subHeaderContent && 
                      <div className="Walkthrough-HeaderSubContent">
                        {subHeaderContent}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>

            <div className="Walkthrough-Content">
              <div className="Walkthrough-Cards">
              {Object.entries(walkthroughStepContent).map(([key, content], index) => {
                  //console.log(content)
                  //console.log(key, activeWalkthroughStep, transitioningWalkthroughStepBack)
 
                  let cardWrapperProps = {style: {}}
                  if (key === activeWalkthroughStep)  {
                    if (transitioningWalkthroughStepBack !== null)  {
                      cardWrapperProps.style = nextCardTransitionToCurrentCardAnimation
                    }else if (key === activeWalkthroughStep && transitioningWalkthroughStepForward !== null)  {
                      cardWrapperProps.style = previousCardTransitionToCurrentCardAnimation
                    }
                    cardWrapperProps.ref = activeContentHandleRef
                  }else if (key === transitioningWalkthroughStepForward)  {
                    cardWrapperProps.style = currentCardTransitionOutAnimation
                    cardWrapperProps.style.position = "absolute"
                    cardWrapperProps.style.top = 0                    
                  }else if (key === transitioningWalkthroughStepBack) {
                    cardWrapperProps.style = currentCardTransitionOutAnimation
                    cardWrapperProps.style.position = "absolute"
                    cardWrapperProps.style.top = 0
                  }
                  return (
                    <animated.div key={index} className="Walkthrough-Card-Wrapper" {...cardWrapperProps}>
                      {content}
                    </animated.div>
                  )
                })}
              </div>
            </div>

            {footer && 
              <div className={"Walkthrough-Footer" + (hideMainSeparators ? " Walkthrough-NoSeperator" : "")}>
                {footer}
              </div>
            }
        </div>
      </div>
    )

  }else {
    const walkthroughPointerProps = {style:{}}
    if (walkthroughTransitionStep !== "idle") {
      walkthroughPointerProps.style.pointerEvents = "none"
    }
  
    const containerProps = {style: {}}
    if (size === "auto")  {
      containerProps.style.alignItems = "center"
      containerProps.style.justifyItems = "center"
    }else if (size === "full")  {
      /*containerProps.style.alignItems = "center"*/
      containerProps.style.justifyContent = "center"
      //containerProps.style.maxHeight = "100%"
      containerProps.style.overflow = "hidden"
      walkthroughProps.style.alignContent = "center"
      walkthroughProps.style.justifyContent = "center"
      /*walkthroughProps.style.alignItems = "center"*/
    }


    

    let minContentWidth = 0
    if (transitionToWalkthroughStep === null && transitionBackToWalkthroughStep === null) {
      minContentWidth = activeContentWidth
      if (activeContentWidth > walkthroughContentWidth) {
        minContentWidth = walkthroughContentWidth
      }
    }

    let currentContentHeight = activeContentHeight
    if (currentContentHeight > walkthroughContainerHeight)  {
      currentContentHeight = walkthroughContainerHeight
    }
    return (
      <div className={"Walkthrough-ScreenWrapper"} {...walkthroughScreenWrapperProps}>
        <div className="Walkthrough-Wrapper">
          <div onClick={closePopup} className="Walkthrough-Container" {...containerProps} ref={walkthroughContainerHandleRef}>
            <div className="Walkthrough" onClick={(e) => {e.preventDefault(); e.stopPropagation()}} {...walkthroughProps} ref={walkthroughContentHandleRef}>
              <div className={"Walkthrough-Header" + (!hideMainSeparators ? " Walkthrough-HeaderWithSeperator" : "")} {...walkthroughPointerProps} ref={walkthroughHeaderHandleRef}>
                <div className="Walkthrough-HeaderContent">
                  <div className="Walkthrough-HeaderContent-Primary">
                    <div className="Walkthrough-HeaderTitle-Container">
                      <div className="Walkthrough-HeaderTitle">
                          {title}
                      </div>
                      {subtitle && <>
                        <div className="Walkthrough-HeaderSubtitle">
                            {subtitle}
                        </div>
                      </>}
                      {description && 
                        <div className="Walkthrough-HeaderTitle-Description">
                            {description}
                        </div>
                      }
                    </div>
                    <div className="Walkthrough-CloseButton" onClick={forceClosePopup}>
                      <Close/>
                    </div>
                  </div>
                  {(timelineSteps !== false || subHeaderContent) &&
                    <div className="Walkthrough-HeaderContent-Secondary">
                      {timelineSteps !== false &&
                        <BadgeTimeline steps={timelineSteps} currentStep={currentTimelineStep} onStepChanged={timelineStepChanged}/>
                      }
                      {subHeaderContent && 
                        <div className="Walkthrough-HeaderSubContent">
                          {subHeaderContent}
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
                  
              <div className="Walkthrough-Content" style={{minWidth: minContentWidth, height: activeContentHeight}}>
                <div className="Walkthrough-Cards" {...walkthroughPointerProps}>
                  {Object.entries(walkthroughStepContent).map(([key, content], index) => {
                    
                    let cardWrapperProps = {style: {}}
                    if (key === activeWalkthroughStep)  {
                      if (transitioningWalkthroughStepBack !== null)  {
                        cardWrapperProps.style = nextCardTransitionToCurrentCardAnimation
                      }else if (key === activeWalkthroughStep && transitioningWalkthroughStepForward !== null)  {
                        cardWrapperProps.style = previousCardTransitionToCurrentCardAnimation
                      }
                      cardWrapperProps.ref = activeContentHandleRef
                    }else if (key === transitioningWalkthroughStepForward)  {
                      cardWrapperProps.style = currentCardTransitionOutAnimation
                      cardWrapperProps.style.position = "absolute"
                      cardWrapperProps.style.top = 0                    
                    }else if (key === transitioningWalkthroughStepBack) {
                      cardWrapperProps.style = currentCardTransitionOutAnimation
                      cardWrapperProps.style.position = "absolute"
                      cardWrapperProps.style.top = 0
                    }

                    return (
                      <animated.div key={index} className="Walkthrough-Card-Wrapper" {...cardWrapperProps}>
                        <div className="Walkthrough-Card-Container">
                          {content}
                        </div>
                      </animated.div>
                    )
                  })}
                </div>
              </div>

              {footer && 
                <div className="Walkthrough-Footer" {...walkthroughPointerProps} ref={walkthroughFooterHandleRef}>
                  {footer}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
} 

Walkthrough.defaultProps = {
  id: false,
  size: "auto",
  contentAlignment: false,
  footer: false,
  timelineSteps: false,
  hideMainSeparators: false,
  subHeaderContent: false,
  transitionToContent: null,
  transitionBackToContent: null,
  allowOuterClickToClosePopup: true
}


export default Walkthrough