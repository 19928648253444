
import './SelectPlantTypes.scss';
import React from 'react'
import RowGroup from '../components/RowGroup/RowGroup';
import RowGroupSubGroup from '../components/RowGroup/RowGroupSubGroup';
import { useMediaQuery } from 'react-responsive';
import RowGroupRow from '../components/RowGroup/RowGroupRow';
import { getAllPlantTypes, selectAllPlantTypeGroups, selectAllPlantTypes } from '../redux/entities/PlantTypes';
import { useDispatch, useSelector } from 'react-redux';


const SelectPlantTypes = ({selectedPlantTypeId, onPlantTypeSelection}) => {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

  const dispatch = useDispatch()

  const allPlantGroups = useSelector(state => selectAllPlantTypeGroups(state))
  const allPlantTypes = useSelector(state => selectAllPlantTypes(state))

  const loadedAllPlantTypes = useSelector((state) => state.plantTypes.loadedAllPlantTypes)
  React.useEffect(() => {
      if (!loadedAllPlantTypes)   {
          dispatch(getAllPlantTypes({}))
      }
  }, [loadedAllPlantTypes])


  const [plantTypesList, SetPlantTypesList] = React.useState([])
  const [plantGroupsList, SetPlantGroupsList] = React.useState([])
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedPlantTypeId, SetSelectedPlantTypeId] = React.useState(undefined)
  React.useEffect(() => {
    if (selectedPlantTypeId !== currentSelectedPlantTypeId) {
      SetSelectedPlantTypeId(selectedPlantTypeId)
    }
  }, [selectedPlantTypeId])
  
  const [currentGroupByTypeFilter, SetGroupByTypeFilter] = React.useState('*')
  


  React.useEffect(() => {
    let currentPlantTypesList = []
    let currentPlantGroupsList = []

    let currentPlantTypes = []
    let currentPlantGroups = []
    
    let availablePlantGroups = []

    if (currentGroupByTypeFilter === "*") {
      availablePlantGroups = [...allPlantGroups]
    }else {
      availablePlantGroups = [allPlantGroups.find((plantGroup) => plantGroup.id === currentGroupByTypeFilter)]
    }

    if (currentSearchInput === "")  {
      currentPlantGroups = [...availablePlantGroups]
    }else {
      currentPlantGroups.push(...availablePlantGroups.filter((plantGroup) => {
        if (plantGroup.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1)  {
          currentPlantTypes.push(...allPlantTypes.filter((plantType) => {
            if (plantType.group_id === plantGroup.id) {
              return plantType
            }
          }))
          return plantGroup
        }
      }))
    }
    currentPlantTypes.push(...allPlantTypes.filter((plantType) => {

      if ((currentSearchInput === "" || plantType.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1) && currentPlantTypes.indexOf(plantType) === -1)  {
        const foundPlantGroup = availablePlantGroups.find((plantGroup) => plantGroup.id === plantType.group_id)
        if (foundPlantGroup !== undefined)  {
          if (currentPlantGroups.indexOf(foundPlantGroup) === -1) {
            currentPlantGroups.push(foundPlantGroup)
          }
          return plantType
        }
      }
    }))
    
    for (let plantType of currentPlantTypes) {
      if (currentPlantGroupsList.find((pG) => pG.id === plantType.group_id) === undefined) {
        let foundPlantGroup = currentPlantGroups.find((plantGroup) => plantGroup.id === plantType.group_id)
        if (foundPlantGroup !== undefined)  {
          currentPlantGroupsList.push({
            id: foundPlantGroup.id,
            data: foundPlantGroup,
          })
        }
      }
      currentPlantTypesList.push({
        id: plantType.id,
        data: plantType,
        groupKey: plantType.group_id
      })
    }
    SetPlantTypesList(currentPlantTypesList)
    SetPlantGroupsList(currentPlantGroupsList)
  }, [allPlantGroups, allPlantTypes, currentSearchInput, currentGroupByTypeFilter])



  const onSearchInput = (value) => {
    SetSearchInput(value)
  }

  const onGroupFilter = (value) =>  {
    SetGroupByTypeFilter(value)
  }
  

  const handlePlantTypeSelectedStateChanged = (plantTypeId, state) => {
    if (selectedPlantTypeId === plantTypeId)    {
        if (state)  {
            SetSelectedPlantTypeId(plantTypeId)
            if (onPlantTypeSelection !== undefined) {
              onPlantTypeSelection(plantTypeId)
            }
        }else {
            SetSelectedPlantTypeId(null)
            if (onPlantTypeSelection !== undefined) {
              onPlantTypeSelection(null)
            }
        }
    }else if (state) {
        SetSelectedPlantTypeId(plantTypeId)
        if (onPlantTypeSelection !== undefined) {
          onPlantTypeSelection(plantTypeId)
        }
    }
}



  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Plant</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
      }
  }
  
  return (
    <>
      <div id="SelectPlantTypes">
        <RowGroup
          hasGroupHeader={false}
          hasSettings={false}
          hasContainer={false}
          itemsScroll={true}
          isExpandable={false}
          hasColumnHeaders={true}
          columnHeaders={columnHeaders}
          columnSizes={columnSizes}
          hasSubGroups={true}>

          <SelectPlantTypes_PlantTypeRow isPlaceholder={true} columnSizesChanged={(columns) => {
              SetColumnSizes(columns)
          }}/>
          {plantGroupsList.map((plantGroup) => {
            let plantTypes = plantTypesList.filter((plantTypeInfo) => plantTypeInfo.data.group_id === plantGroup.data.id)
            return (
              <RowGroupSubGroup
                key={plantGroup.id}
                title={plantGroup.data.name} 
                expandedState={plantTypes.find((plantTypeInfo) => plantTypeInfo.id === selectedPlantTypeId) !== undefined} 
                rows={plantTypes.map((plantTypeInfo) => {
                  return (
                    <SelectPlantTypes_PlantTypeRow 
                      key={plantTypeInfo.id}
                      plantTypeKey={plantTypeInfo.id} 
                      plantTypeInfo={plantTypeInfo} 
                      isSelected={selectedPlantTypeId === plantTypeInfo.id}
                      onSelectedChanged={(state) => {handlePlantTypeSelectedStateChanged(plantTypeInfo.id, state)}}
                      columnSizes={columnSizes}/>
                  ) 
                })}
                numRowsDisplay="types" 
                emptyRowsDisplay="no plant types found"/>
              )
          })}
      </RowGroup>
      </div>
    </>
  )
} 

export default SelectPlantTypes


const SelectPlantTypes_PlantTypeRow = ({plantTypeKey, plantTypeInfo, isPlaceholder, isSelected, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const plantType = isPlaceholder ? null : plantTypeInfo.data

  let secondaryHeaderContent = []

  let title = isPlaceholder ? <>My Amazing Lettuce is the best</> : plantType.name
  
  //let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className="">#GP{growPlan.uid}</div>)

  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : plantTypeKey}
      title={title}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={false}
      isSelectable={true}
      onSelectedChanged={(state) => {onSelectedChanged(state); isSelected = state}}
      selectedState={isSelected}
      hasAdditionalFunctions={false}
      content={<></>}/>
  </>)
}