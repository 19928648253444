
import './SelectPlantVarieties.scss';
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import RowGroup from '../components/RowGroup/RowGroup';
import RowGroupRow from '../components/RowGroup/RowGroupRow';
import { getPlantVarietiesByTypeId, selectAllPlantTypes, selectAllPlantVarieties } from '../redux/entities/PlantTypes';
import { useDispatch, useSelector } from 'react-redux';



const SelectPlantVarieties = ({selectedPlantTypeId, selectedPlantVariety, onPlantVarietySelection}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  const allPlantTypes = useSelector(state => selectAllPlantTypes(state))
  const allPlantVarieties = useSelector(state => selectAllPlantVarieties(state))
  const activePlantType = allPlantTypes.find((pT) => pT.id === selectedPlantTypeId)

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!activePlantType.loaded_plant_varieties)  {
      dispatch(getPlantVarietiesByTypeId({plantTypeId: activePlantType.id}))
    }
  }, [activePlantType])


  const [plantVaritiesList, SetPlantVaritiesList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedPlantVariety, SetSelectedPlantVariety] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedPlantVariety !== selectedPlantVariety) {
      SetSelectedPlantVariety(selectedPlantVariety)
    }
  }, [selectedPlantVariety])
  

  React.useEffect(() => {
    let currentPlantVarietiesList = {}

    let currentPlantVarieties = []
    
    let availablePlantVarieties = []
    if (selectedPlantTypeId)  {
      availablePlantVarieties = allPlantVarieties.filter((plantVariety) => plantVariety.type_id === selectedPlantTypeId)
    }else {
      availablePlantVarieties = [...allPlantVarieties]
    }

    if (currentSearchInput === "")  {
      currentPlantVarieties = [...availablePlantVarieties]
    }else {
      currentPlantVarieties.push(...availablePlantVarieties.filter((plantVariety) => {
        if (plantVariety.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1 && currentPlantVarieties.indexOf(plantVariety) === -1)  {
          return plantVariety
        }
      }))
    }
    
    
    for (let plantVariety of currentPlantVarieties) {
      currentPlantVarietiesList[plantVariety.id] = {
        data: plantVariety,
      }
    }


    SetPlantVaritiesList(currentPlantVarietiesList)
  }, [allPlantVarieties, currentSearchInput, currentSelectedPlantVariety])




  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const handlePlantVarietySelectedStateChanged = (plantVarietyId, state) => {
    if (selectedPlantVariety === plantVarietyId)    {
        if (state)  {
            SetSelectedPlantVariety(plantVarietyId)
            if (onPlantVarietySelection !== undefined) {
              onPlantVarietySelection(plantVarietyId)
            }
        }else {
            SetSelectedPlantVariety(null)
            if (onPlantVarietySelection !== undefined) {
              onPlantVarietySelection(null)
            }
        }
    }else if (state) {
        SetSelectedPlantVariety(plantVarietyId)
        if (onPlantVarietySelection !== undefined) {
          onPlantVarietySelection(plantVarietyId)
        }
    }
}


  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Varient Name</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
      }
  }

  return (
    <>
      <div id="SelectPlantVarieties">
      <RowGroup
          hasGroupHeader={false}
          hasSettings={false}
          hasContainer={false}
          itemsScroll={true}
          isExpandable={false}
          hasColumnHeaders={true}
          columnHeaders={columnHeaders}
          columnSizes={columnSizes}
          hasSubGroups={true}>

          <SelectPlantVarieties_PlantVarietyRow isPlaceholder={true} columnSizesChanged={(columns) => {
              SetColumnSizes(columns)
          }}/>

          {Object.entries(plantVaritiesList).map(([plantVarietyIdAsString, plantVarietyInfo]) => {
              let plantVarietyId = parseInt(plantVarietyIdAsString)
              return (
                <SelectPlantVarieties_PlantVarietyRow
                  key={plantVarietyId}
                  plantVarietyKey={plantVarietyId} 
                  plantVarietyInfo={plantVarietyInfo} 
                  isSelected={selectedPlantVariety === plantVarietyId}
                  onSelectedChanged={(state) => {handlePlantVarietySelectedStateChanged(plantVarietyId, state)}}
                  columnSizes={columnSizes}/>
              ) 
            })}
      </RowGroup>
      </div>
    </>
  )
} 

export default SelectPlantVarieties

SelectPlantVarieties.defaultProps = {
  selectedPlantType: null,
}


const SelectPlantVarieties_PlantVarietyRow = ({plantVarietyKey, plantVarietyInfo, isPlaceholder, isSelected, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const plantVariety = isPlaceholder ? null : plantVarietyInfo.data

  let secondaryHeaderContent = []

  let title = isPlaceholder ? <>My Amazing Lettuce is the best</> : plantVariety.name
  
  //let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className="">#GP{growPlan.uid}</div>)

  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : plantVarietyKey}
      title={title}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={false}
      isSelectable={true}
      onSelectedChanged={(state) => {onSelectedChanged(state); isSelected = state}}
      selectedState={isSelected}
      hasAdditionalFunctions={false}
      content={<></>}/>
  </>)
}