import './NumberInput.scss';
import React from 'react'

import { RoundToNearest, useMeasure } from '../../helpers'
import {FiPlus, FiMinus} from 'react-icons/fi'

const NumberInput = ({value, stepper, stepAmount, stepResolution, prefix, suffix, type, fontSize, onChange, onBlur, flex, size, maxLength, displayResolution, precision, min, max, disabled, showDisabledVisual, canEdit}) => {
  const [focusState, SetFocusState] = React.useState(false);
  const [pointerId, SetPointerId] = React.useState(null)
  const containerRef = React.useRef(null);

  if (type === "hours") {
    suffix = "h"
    size = 2
  }else if (type === "minutes") {
    suffix = "m"
    size = 2
  }

  if (!canEdit) {
    stepper = false
  }


  React.useEffect(() => {
    if (!containerRef.current)
      return

    
  }, [containerRef, focusState])

  const [numberValue, setNumberValue] = React.useState(value);
  const [tempValue, setTempValue] = React.useState(null)

  const [numberValueDisplay, setNumberValueDisplay] = React.useState(value);
  const numberValueRef = React.useRef(null);
  const numberInput = React.useRef(null);

  const actualIcon = false;
  const [inputBind, { height: inputHeight }] = useMeasure()

  const [inTempEmptyMode, SetInTempEmptyMode] = React.useState(false)


  const buttonUpClicked = React.useCallback(() => {
    if (disabled)
      return
    SetInTempEmptyMode(false)
    let resultantValue = parseFloat(numberValue)
    if (type === "default") {
      resultantValue = numberValue + stepAmount
    }else if (type === "hours") {
      resultantValue = numberValue + (stepAmount * 3600)
    }else if (type === "minutes") {
      resultantValue = numberValue + (stepAmount * 60)
    }
    if (stepResolution) {
      resultantValue = RoundToNearest(resultantValue, stepResolution, "floor")
    }
    processValue(resultantValue)

    /*if (onChange !== undefined) {
      let result = onChange(resultantValue)
      if (result !== undefined) {
        setNumberValue(result)
        return
      }
    }*/
    //numberInput.current.value = numberValue + stepAmount
    //setNumberValue(resultantValue)
  })
  const buttonDownClicked = React.useCallback((e) => {
    if (disabled)
      return
    SetInTempEmptyMode(false)
    let resultantValue = parseFloat(numberValue)
    if (type === "default") {
      resultantValue = numberValue - stepAmount
    }else if (type === "hours") {
      resultantValue = numberValue - (stepAmount * 3600)
    }else if (type === "minutes") {
      resultantValue = numberValue - (stepAmount * 60)
    }
    if (stepResolution) {
      resultantValue = RoundToNearest(resultantValue, stepResolution, "ceil")
    }
    processValue(resultantValue)

    /*if (onChange !== undefined) {
      let result = onChange(resultantValue)
      if (result !== undefined) {
        //numberInput.current.value = result
        setNumberValue(result)
        return
      }
    }*/
    //numberInput.current.value = numberValue - stepAmount
    //setNumberValue(resultantValue)
  })

  const valueChanged = React.useCallback((e) =>  {
    if (disabled)
      return
    
    e.preventDefault()
    setTempValue(e.target.value)
    //processValue(e.target.value)
  })

  const processValue = (newValue) => {
    if (newValue === "")  {
      newValue = 0
    }
    let requestedValue = parseFloat(newValue)
    if (isNaN(requestedValue)) {
      requestedValue = 0
    }
    if (requestedValue == numberValue)  {
      numberInput.current.value = requestedValue
      return requestedValue
    }
    let valid = true
    if (min !== null && requestedValue < min)  {
      requestedValue = min
      valid = false
    }
    if (max !== null && requestedValue > max)  {
      requestedValue = max
      valid = false
    }
      
    if (onChange !== undefined) {
      let result = onChange(requestedValue)
      if (result !== undefined) {
        //numberInput.current.value = result
        setNumberValue(result)
        return result
      }else {
        numberInput.current.value = requestedValue
        setNumberValue(requestedValue)
        return requestedValue
      }
    }else {
      if (!valid) {
        numberInput.current.value = requestedValue
        setNumberValue(requestedValue)
        return requestedValue
      }
    }

    setNumberValue(parseFloat(newValue))
    return parseFloat(newValue)
  }


  React.useEffect(() => {
    //numberInput.current.value = value
    setNumberValue(value)
  }, [value])

  React.useEffect(() => {
    numberValueRef.current = numberValue
  }, [numberValue])


  const widthRatio = 0.75

  let inputProps = {}
  if (maxLength !== false)  {
    inputProps["maxLength"] = maxLength
  }
  if (inTempEmptyMode)  {
    inputProps["placeholder"] = min !== false ? min : 0
  }

  const handleFocus = React.useCallback((e) => {
    if (disabled) {
      SetFocusState(false)
      return
    }
    if (!focusState)  {
      SetFocusState(true)
    }
  })

  
  const blurDetectPointerDown = (e) => {
    if (focusState && containerRef.current) {
      blur()
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }

  const blur = React.useCallback(() => {
    SetFocusState(false)
    SetInTempEmptyMode(false)
    if (numberInput.current) {
      numberInput.current.blur()
    }
    if (tempValue !== null)  {
      const processedValue = processValue(tempValue)
      setTempValue(null)
      if (onBlur !== undefined) {      
        onBlur(processedValue)
      }
    }else if (onBlur !== undefined) {      
      onBlur(numberValueRef.current)
    }
  })

  const focusEnd = (e) => {
    
  }

  const calculateValueDisplay = (value) =>  {
    if (inTempEmptyMode)  {
      return ""
    }
    let valueDisplay = value
    if (type === "default") {
      valueDisplay = value
    }else if (type === "hours") {
      valueDisplay = Math.floor(value / 3600)
    }else if (type === "minutes") {
      valueDisplay = Math.floor((value % 3600) / 60)
    }

    if (precision !== false)  {
      valueDisplay = Math.round(value * (1 / precision)) / (1 / precision)
    }
    if (displayResolution !== false)  {
      valueDisplay = RoundToNearest(value, displayResolution)
    }
    return valueDisplay
  }

  
  React.useEffect(() => {
    let valueDisplay = (tempValue !== null) ? calculateValueDisplay(tempValue) : calculateValueDisplay(numberValue)
    //if (valueDisplay !== numberInput.current.value)  {
      numberInput.current.value = valueDisplay
    //}
  }, [numberValue, tempValue, inTempEmptyMode])

  //let defaultValue = calculateValueDisplay(numberValue)

  const inputHitboxClicked = React.useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
    handleFocus(e)
    numberInput.current.focus()
  })

  let valueToDisplay = (tempValue !== null) ? calculateValueDisplay(tempValue) : calculateValueDisplay(numberValue)

  const containerProps = {style:{}}
  if (flex) {
    containerProps.style.flex = "1"
    containerProps.style.alignSelf = "stretch"
    size = false
    
  }

  return (<>
    <div className={"NumberInput" + (focusState ? " NumberInput-Focused" : "") + ((disabled && showDisabledVisual) ? " NumberInput-Disabled" : "")} onClick={handleFocus} ref={containerRef} {...containerProps}>
      {stepper && 
        <div 
          className="NumberInput_ButtonUp noselect" 
          /*style={{width: inputHeight * widthRatio, height: inputHeight / 2}}   style={{width: inputHeight * widthRatio}}*/
          onClick={buttonDownClicked}>
          <div className="NumberInput_Button noselect">
            <FiMinus/>
          </div>
        </div>
      }
      <div className="NumberInput_ContentWrapper" {...inputBind} onClick={inputHitboxClicked}>
        <div className="NumberInput_Content"
          style={{ width: size ? (size + (suffix ? suffix.length : 0)) + 'ch' : 'auto', fontSize: fontSize}}>
          {(prefix !== false) &&
            <div className="NumberInput_Prefix noselect">
              {prefix}
            </div>
          }
          <div className="NumberInput_DisplayArea">
            <input
              defaultValue={valueToDisplay}
              style={{ width: valueToDisplay.toString().length + 'ch', fontSize: fontSize}}
              onChange={valueChanged} 
              type="text" pattern="[0-9]*"
              ref={numberInput}
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation()
                handleFocus(e)
              }}
              {...inputProps}/>
          </div>
          {(suffix !== false) &&
            <div className="NumberInput_Suffix noselect">
              {suffix}
            </div>
          }
        </div>
      </div>

    {stepper && 
        <div 
          className="NumberInput_ButtonDown noselect" 
          /*style={{width: inputHeight * widthRatio, height: inputHeight / 2}}*/
          onClick={buttonUpClicked}>
          <div className="NumberInput_Button noselect">
            <FiPlus/>
          </div>
        </div>
      }
    </div>
    <div className={"NumberInput-BlurDetect" + (focusState ? " NumberInput-BlurDetect-Active" : "")}
    onPointerDown={blurDetectPointerDown}></div>
    </>
  )
} 

NumberInput.defaultProps = {
  value: "",
  stepper: true,
  stepAmount: 1,
  stepResolution: false,
  prefix: false,
  suffix: false,
  type: "default",
  fontSize: 16,
  flex: false,
  size: 4,
  maxLength: false,
  precision: false,
  displayResolution: false,
  min: null,
  max: null,
  disabled: false,
  showDisabledVisual: true,
  canEdit: true
}


export default NumberInput