import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';



const DataStatus_PrecoolCoilCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const [leavingTempComponentInfo, superheatComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrecoolCoil", ["l-temp", "sh"]), _.isEqual)
  const [EEVPositionComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrecoolCoilEEV", ["output_signal"]), _.isEqual)
  const [PRVPositionComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrecoolCoilPRV", ["output_signal"]), _.isEqual)
  const [coilPressureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "AccumulatorSubcoolPressureTransducer", ["pressure", "sh"]), _.isEqual)

  let EEVPosition = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, EEVPositionComponentInfo), _.isEqual)
  let PRVPosition = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, PRVPositionComponentInfo), _.isEqual)
  let leavingTemp = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, leavingTempComponentInfo), _.isEqual)
  let coilPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, coilPressureComponentInfo), _.isEqual)
  let superheat = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, superheatComponentInfo), _.isEqual)

  let dataListItems = [
    {
      label: "EEV Position", value: EEVPosition.value, suffix: "%", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 100, status: "normal" }
      }
    },
    {
      label: "PRV Position", value: PRVPosition.value, suffix: "%", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 100, status: "normal" }
      }
    },
    {
      label: "Pressure", value: coilPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 95, max: 250, status: "normal" }
      }
    },
    {
      label: "Superheat", value: superheat.value, suffix: "°C", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 30, status: "normal" }
      }
    },
    {
      label: "Leaving Temp", value: leavingTemp.value, suffix: "°C",
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Precool Coil</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default DataStatus_PrecoolCoilCell