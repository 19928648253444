import './GrowManagerPage.scss';
import variables from '../../globals.scss';
import _ from 'underscore';
 
import React from 'react'

import { selectGrowById} from '../../redux/entities/Grow'
import { selectAllGrowPlans} from '../../redux/entities/GrowPlans'
import { selectAllRecipes } from '../../redux/entities/Recipes';
import { useSelector, useDispatch } from 'react-redux'

import {TabControl, TabControlTab} from '../../components/TabControl.js';
import DropDownInput from '../../components/input/DropDownInput.js'

import { FormatDate } from '../../helpers';

import 'react-circular-progressbar/dist/styles.css';
import { RecipeTimeline } from '../RecipesAndPlans/Recipes/RecipeTimeline';
import CircularGauge from '../../components/CircularGauge';

import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import GrowStatus_InfoContent from './GrowStatus/GrowStatus_InfoContent';
import GrowStatus_PerformanceContent from './GrowStatus/GrowStatus_PerformanceContent';
import GrowStatus_GrowProgress from './GrowStatus/GrowStatus_GrowProgress.js';
import { MaintainBladeZoneActiveRecipe, selectAllBladeZones } from '../../redux/entities/service/Blade.js';




const GrowStatusList_Instance = ({growId, isPlaceholder, columnSizes, columnSizesChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const grow = useSelector((state) => selectGrowById(state, growId))

  let allGrowPlans = useSelector(selectAllGrowPlans)
  let recipes = useSelector(selectAllRecipes)

  let activeRecipe = null
  let activeGrowPlan = undefined
  let growStartDate = null
  let growEndDate = null

  if (!isPlaceholder) {
    activeRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
    activeGrowPlan = allGrowPlans.find((gP) => gP.id === grow.grow_plan_id)

    if (activeRecipe !== undefined) {
      if (!grow.pending_start && grow.started_on)  {
        growStartDate = new Date(grow.started_on).getTime()
        if (grow.completed) {
          growEndDate =  new Date(grow.finished_on).getTime()
        }else {
          growEndDate =  growStartDate + activeRecipe.expected_duration * 1000
        }
      }else {
        if (grow.scheduled_type === "nad" || grow.scheduled_type === "start_date") {
          growStartDate = new Date().getTime()
          growEndDate = growStartDate + (activeRecipe.expected_duration * 1000) 
        }else if (new Date().getTime() + (activeRecipe.expected_duration * 1000) > new Date(grow.scheduled_for).getTime()) {
          growStartDate = new Date().getTime()
          growEndDate = growStartDate + (activeRecipe.expected_duration * 1000) 
        }else {
          growEndDate = new Date(grow.scheduled_for).getTime()
          growStartDate = growEndDate - (activeRecipe.expected_duration * 1000)
        }
      }
    }else {
      growStartDate = new Date().getTime()
    }
  }

  //console.log(activeGrowPlan)


  let additionalFunctions = [
    {key: "manage_lights", label: "Manage Lights"},
    {key: "rename", label: "Rename Rack"},
    {key: "move", label: "Move Rack"},
    {key: "change_sides", label: "Change Sides"},
    {key: "light_up", label: "Light Up Rack"},
    {key: "delete", label: "Delete", status: "Critical"},
  ]

  //console.log(growInfo)


  let currentProgress = 0
  if (!isPlaceholder) {
    if (growStartDate && !grow.completed) {
      currentProgress = new Date().getTime() - new Date(growStartDate).getTime()
    }
  }
  
  let timelineComponent = <>
    {activeRecipe && 
      <RecipeTimeline 
        recipe={activeRecipe}
        showProgress={!grow.completed}
        currentProgress={growStartDate !== null ? new Date().getTime() - new Date(growStartDate).getTime() : 0}
        showDuration={false}
        showDayTicks={false}
      />}
    {!activeRecipe && <>
      <div style={{textAlign:"center"}}>No Recipe Found</div>
    </>}
  </>

  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "timing", content: <>
      <div style={{fontSize:14}}>
        <div>{(!isPlaceholder ? FormatDate(new Date(growStartDate), "MM/dd/yyyy") : "00/00/0000")}</div>
        <div>{(!isPlaceholder ? (growEndDate ? FormatDate(new Date(growEndDate), "MM/dd/yyyy") : "N/A") : "00/00/0000")}</div>
      </div>
    </>
    })
    secondaryHeaderContent.push({
      key: "timeline", content: timelineComponent, flex: true
    })
  }


  let havePercentage = false
  let percentage = 0
  if (!isPlaceholder) {
    percentage = grow.totalScore
  }


  secondaryHeaderContent.push(
    {key: "score", content: <>
      <div style={{width:"40px"}}>
        <CircularGauge value={parseInt(percentage)} text={havePercentage ? percentage : "N/A"} unit={""} valueDisplayFontSize={12}/>
      </div>
    </>}
  )
  

  let extendedHeaderContent = null
  if (isMobile) {
    extendedHeaderContent = {triggersExpandToggle: true, content: <div style={{display: "flex", alignSelf: "stretch", margin:10, flexDirection: "column"}}>
        {timelineComponent}
      </div>
    }
  }


  const [expandedContentPage, SetExpandedContentPage] = React.useState("grow_info")




  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }





  const [activeBladeZoneUIDs, SetActiveBladeZoneUIDs] = React.useState([])
  React.useLayoutEffect(() => {
    if (grow !== undefined && grow !== null && grow.grow_out_zone_info !== null) {
      SetActiveBladeZoneUIDs(grow.grow_out_zone_info.map((gO) => gO.zone_uid))
    }
  }, [grow])


  const allBladeZones = useSelector((state) => selectAllBladeZones(state))
  
  const [zoneOptions, SetZoneOptions] = React.useState([])
  const [selectedZoneOption, SetSelectedZoneOption] = React.useState(null)
  React.useLayoutEffect(() => {
    if (grow !== undefined && grow !== null) {
      let updatedZoneOptions = []

      if (grow.grow_out_zone_info)  {
        for (let zoneInfo of grow.grow_out_zone_info) {
          let foundZone = allBladeZones.find((bZ) => bZ.uid === zoneInfo.zone_uid)
          //console.log(allBladeZones)
          if (foundZone !== undefined)  {
            updatedZoneOptions.push({
              label: foundZone.display_name + (!zoneInfo.active ? " - Completed" : ""),
              value: foundZone.uid,
            })
          }
        }
      }

      if (selectedZoneOption === null && updatedZoneOptions.length > 0)  {
        SetSelectedZoneOption(updatedZoneOptions[0].value)
      }
      SetZoneOptions(updatedZoneOptions)
    }
  }, [grow, selectedZoneOption, allBladeZones])
  const zoneSelectionChanged = (zone) => {
    SetSelectedZoneOption(zone)
  }


  

  return (<>
    <MaintainBladeZoneActiveRecipe bladeZoneUIDs={activeBladeZoneUIDs} interval={10000}/>
    <RowGroupRow
      key={isPlaceholder ? 0 : grow.id}
      title={isPlaceholder ? "Placeholder" : activeGrowPlan !== undefined ? activeGrowPlan.name : "Unnamed Plan"}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      subtitle={<div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{isPlaceholder ? "#G12512312" : "#G" + grow.id}</div>}
      showStatusIndicator={true}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      status={"Connected"}
      statusColor={"#4DBE3B"}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={true}
      customRowPadding={0}
      hasAdditionalFunctions={true}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={additionalFunctions}
      content={<>

        {(() => {
          if (isPlaceholder)  {
            return <></>
          }

          return (
            <div style={{display:"flex", flexDirection:"column", border:"1px solid #DDE3EB", gap:0, borderRadius: 4, backgroundColor:"#FFF"}}>
              <div style={{padding: "12px 20px 0px 20px"}}>
                {!isMobile && <>
                  <TabControl 
                    onTabSelected={tabSelected}
                    tabLocation={"flex"}
                    tabs={[
                      new TabControlTab("Grow Info", "grow_info", "", expandedContentPage === "grow_info"),
                      new TabControlTab("Performance", "performance", "", expandedContentPage === "performance"),
                      /*new TabControlTab("Metrics", "metrics", "", expandedContentPage === "metrics"),*/
                      new TabControlTab("Grow Progress", "grow_progress", "", expandedContentPage === "grow_progress"),
                      /*new TabControlTab("KPI's", "kpis", "", expandedContentPage === "kpis")*/
                    ]}
                  />
                </>}
                {isMobile && <>
                  <DropDownInput 
                    status="Neutral"
                    value={expandedContentPage}
                    options={[
                      {key: 'grow_info', label: 'Grow Info'},
                      {key: 'performance', label: 'Performance'},
                      /*{key: 'metrics', label: 'Metrics'},*/
                      {key: 'grow_progress', label: 'Grow Progress'},
                      /*{key: 'kpis', label: 'KPI\'s'}*/
                    ]} 
                    optionsKey="key"
                    onSelectionChange={(action) => {
                      SetExpandedContentPage(action)
                    }}/>
                </>}
              </div>

              {expandedContentPage === "grow_info" && <>
                <GrowStatus_InfoContent activeGrow={grow} activeGrowPlan={activeGrowPlan} activeRecipe={activeRecipe}/> 
              </>}

              {expandedContentPage === "performance" && <>
                <GrowStatus_PerformanceContent 
                  activeGrow={grow} 
                  activeGrowPlan={activeGrowPlan} 
                  activeRecipe={activeRecipe}
                  zoneOptions={zoneOptions}
                  selectedZoneUID={selectedZoneOption}
                  onZoneSelectionChanged={zoneSelectionChanged}/> 
              </>}
                     
              {expandedContentPage === "grow_progress" && <>
                <GrowStatus_GrowProgress 
                  activeGrow={grow} 
                  activeGrowPlan={activeGrowPlan} 
                  activeRecipe={activeRecipe}
                  allBladeZones={allBladeZones}
                  zoneOptions={zoneOptions}
                  selectedZoneUID={selectedZoneOption}
                  onZoneSelectionChanged={zoneSelectionChanged}/> 
              </>}       
            </div>
            )
        })()}
      </>}
    />
</>)
}
GrowStatusList_Instance.defaultProps = {
  isPlaceholder: false,
}
export default GrowStatusList_Instance
