import './TasksPage.scss';
import React from 'react';


import Badge from '../../components/Badge.js'
import { FormatDate } from '../../helpers'
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux'

import {MdClose} from 'react-icons/md'
import Button from '../../components/Button';
import { getTaskById, markTaskAsCompleted, markTaskAsStarted } from '../../redux/entities/Tasks';
import TaskPopup_NDSSensorCalibration from './TaskPopups/TaskPopup_NDSSensorCalibration';
import TaskPopup_NDSNutrientTopUp from './TaskPopups/TaskPopup_NDSNutrientTopUp';
import PopupModal from '../../model_components/PopupModal';
import TaskPopup_PlantRootBaseInGrowOut from './TaskPopups/TaskPopup_PlantRootBaseInGrowOut';
import { useMediaQuery } from 'react-responsive';
import TextInput from '../../components/input/TextInput.js';
import { UpArrow } from '../../assets/icons/Icons.js';
import TransplantToNurseryFromGerminationTask from '../GrowManager/Tasks/TransplantToNurseryFromGerminationTask/TransplantToNurseryFromGerminationTask.js';
import TransplantToNurseryFromGerminationTaskReview from '../GrowManager/Tasks/TransplantToNurseryFromGerminationTask/TransplantToNurseryFromGerminationTaskReview.js';
import TransplantToGrowOutFromNurseryTask from '../GrowManager/Tasks/TransplantToGrowOutFromNurseryTaskSteps/TransplantToGrowOutFromNurseryTask.js';
import PlantSeedsForGerminationTask from '../GrowManager/Tasks/PlantSeedsForGerminationSteps/PlantSeedsForGerminationTask.js';
import TransplantToGrowOutFromNurseryTaskReview from '../GrowManager/Tasks/TransplantToGrowOutFromNurseryTaskSteps/TransplantToGrowOutFromNurseryTaskReview.js';
import Blade_CleanseTask from '../Services/GrowRacks/Tasks/Blade_CleanseTask/Blade_CleanseTask.js';
import HarvestTask from '../GrowManager/Tasks/HarvestTaskSteps/HarvestTask.js';
import MoveGrowBagsIntoGrowOutTask from '../GrowManager/Tasks/MoveGrowBagsIntoGrowOutTaskSteps/MoveGrowBagsIntoGrowOutTask.js';
  

const TaskPopup = ({taskId, onCloseTask}) => {
  let data = useSelector((state) => state.tasks)
  let allTasks = data.tasks

  const {URLTaskId } = useParams();
  const [activeTaskId, SetActiveTaskId] = React.useState(0)
  const [isUsingURLTaskId, SetIsUsingURLTaskId] = React.useState(false)
  React.useLayoutEffect(() => {
    if (taskId !== undefined) {
      SetActiveTaskId(taskId)
    }else if (URLTaskId !== undefined)  {
      SetActiveTaskId(URLTaskId)
      SetIsUsingURLTaskId(true)
    }
  }, [taskId, URLTaskId])

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [task, SetTask] = React.useState(undefined)
  const loadingTasksStatus = useSelector((state) => state.tasks.loadingTasksStatus)
  React.useLayoutEffect(() => {
    let foundTask = allTasks.find(e => e.id.toString() === activeTaskId.toString())
    if (foundTask === undefined)  {
      if (loadingTasksStatus !== "pending") {
        dispatch(getTaskById({taskId: activeTaskId}))
      }
    }else {
      SetTask(foundTask)
    }
  }, [activeTaskId, allTasks, loadingTasksStatus])


  const navigate = useNavigate();
  const dispatch = useDispatch()
  React.useLayoutEffect(() => {
    if (isUsingURLTaskId) {
      if ((!/\d+/.test(URLTaskId))) {
        navigate(-1);
      }
    }
  }, [URLTaskId, navigate, task, isUsingURLTaskId]);

 
  const closeTask = () => {
    SetTaskInProgress(false)
    SetReviewingTask(false)
  }

  const closePopup = () =>  {
    if (isUsingURLTaskId) {
      navigate(-1);
    }else {
      if (onCloseTask !== undefined)  {
        onCloseTask()
      }
    }
  }

  const completeTaskClicked = React.useCallback(() => {
    dispatch(markTaskAsCompleted({taskId: task.id}))
    if (isUsingURLTaskId) {
      navigate(-1);
    }else {
      if (onCloseTask !== undefined)  {
        onCloseTask()
      }
    }
  })

  const [taskInProgress, SetTaskInProgress] = React.useState(false)
  const startTaskClicked = () =>  {
    dispatch(markTaskAsStarted({taskId: task.id, callback: (success) => {
      if (success)    {
        SetTaskInProgress(true)
      }
    }}))
  }
  const resumeTaskClicked = () => {
    SetTaskInProgress(true)
  }

  const [reviewingTask, SetReviewingTask] = React.useState(false)
  const reviewTaskClicked = () => {
    SetReviewingTask(true)
  }

  if (task === undefined) {
    return (<>

    </>) //Change to loading in popup view
  }else if (!taskInProgress && !reviewingTask) {
    return <PopupModal 
      title={task.name}
      size={"full"}
      closeCallback={closePopup}
      footer={<>
        <div className="PopupModal-FooterButtons">
          <Button content={"Close"} status={"Neutral"} onClick={closePopup} size={"Flex50"}/>
          {!task.completed && <>
            {!task.started &&
              <Button status="Primary" content="Begin Task" onClick={startTaskClicked} size={"Flex50"}/>
            }
            {task.started &&
              <Button status="Primary" content="Resume Task" onClick={resumeTaskClicked} size={"Flex50"}/>
            }
          </>}
          {task.completed && 
            <Button status="Primary" content="Review Task" onClick={reviewTaskClicked} size={"Flex50"}/>
          }
        </div>
      </>}>
      <div className={isMobile ? "FlexContent-20" : "FlexContent-30" + " FlexContent-HFill"}>
        <div className="FlexContent-5">
          <div className="Text-FormHeader">Description</div>
          <div className="Text-FormValue">A description</div>
        </div>
        <div className={isMobile ? "FlexContent-20" : "Grid-Space40 Grid2"}>
          <div className="FlexContent-5">
            <div className="Text-FormHeader Linebreak-10">Task Info</div>
            <div className="FlexContent-12">

              <div className="FlexContent-H-10">
                <div className="FormLabel">Date:</div>
                <div>{FormatDate(new Date(task.scheduled_for), "MMMM dth yyyy")}</div>
              </div>

              <div className="FlexContent-H-10">
                <div className="FormLabel">Status:</div>
                <div>Todo</div>
              </div>

              <div className="FlexContent-H-10">
                <div className="FormLabel">Assigned To:</div>
                <div>4 People</div>
              </div>


            </div>
          </div>
          <div className="FlexContent-5">
            <div className="Text-FormHeader Linebreak-10">Timeline</div>
              <div className="FlexContent-20">

                <div className="FlexContent-5">
                  <div className="FlexContent-H-10">
                    <div className="Text-PrimarySubtext">Name</div>
                    <div className="Text-AuxSubtext-Small">{FormatDate(new Date(task.created_on), "MMMM dth yyyy")}</div>
                  </div>
                  <div>Scheduled task for {FormatDate(new Date(task.scheduled_for), "MMMM dth yyyy")}</div>
                </div>


                <TextInput placeHolder="Add Comment" submitButton={<UpArrow fill={"#1D65F0"}/>}/>

              </div>
          </div>
        </div>
        <div className="FlexContent-5">
          <div className="Text-FormHeader">Notes</div>
          <TextInput multiline={true} placeHolder={"Add Description"}/>
        </div>
      </div>
    </PopupModal>
  }else if (taskInProgress) {
    switch (task.key) {
      case "blade_cleanse":
        return <Blade_CleanseTask task={task} onClosePopup={closePopup} onCloseTask={closeTask}/>
      case "grow_plant_seeds_for_germination":
        return <PlantSeedsForGerminationTask task={task} onClosePopup={closePopup} onCloseTask={closeTask}/>
      case "grow_transplant_to_nursery_from_germination":
        return <TransplantToNurseryFromGerminationTask task={task} onClosePopup={closePopup} onCloseTask={closeTask}/>
      case "grow_transplant_to_grow_zone_from_nursery":
        return <TransplantToGrowOutFromNurseryTask task={task} onClosePopup={closePopup} onCloseTask={closeTask}/>
      case "grow_plant_root_base_in_grow_out":
        return <MoveGrowBagsIntoGrowOutTask task={task} onClosePopup={closePopup} onCloseTask={closeTask}/>
      case "grow_harvest":
        return <HarvestTask task={task} onClosePopup={closePopup} onCloseTask={closeTask}/>
    



      case "nds_sensor_calibration":
        return <TaskPopup_NDSSensorCalibration task={task} onClosePopup={closePopup}/>
      case "nds_nutrient_top_up":
        return <TaskPopup_NDSNutrientTopUp task={task} onClosePopup={closePopup}/>
      default:
        return (
          <div className="Popup_Standard" id="Task_Popup" onClick={closePopup}>
            <div className="Popup_Standard-Modal" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
              <div className="Popup_Standard-Modal_Header">
                <div className="Popup_Standard-Modal_HeaderContent">
                  <div className="t_DisplayMedium">
                    {task.name}
                  </div>
                </div>
                <div className="Popup_Standard-Modal_Close" onClick={closePopup}>
                  <MdClose/>
                </div>
              </div>
              Task type unknown
              <Button status="Primary" content="Complete task" onClick={completeTaskClicked}/>
            </div>
          </div>
        )
    }
  }else if (reviewingTask)  {


    switch (task.key) {
      case "grow_transplant_to_nursery_from_germination":
        return <TransplantToNurseryFromGerminationTaskReview task={task} onClose={closeTask} onCloseTask={closeTask}/>
      
      case "grow_transplant_to_grow_zone_from_nursery":
        return <TransplantToGrowOutFromNurseryTaskReview task={task} onClosePopup={closePopup} onCloseTask={closeTask}/>
      default:
        return (
          <div className="Popup_Standard" id="Task_Popup" onClick={closePopup}>
            <div className="Popup_Standard-Modal" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
              <div className="Popup_Standard-Modal_Header">
                <div className="Popup_Standard-Modal_HeaderContent">
                  <div className="t_DisplayMedium">
                    {task.name}
                  </div>
                </div>
                <div className="Popup_Standard-Modal_Close" onClick={closePopup}>
                  <MdClose/> 
                </div>
              </div>
              Task type unknown
              <Button status="Primary" content="Complete task" onClick={completeTaskClicked}/>
            </div>
          </div>
        )
    }
  }
} 

export default TaskPopup