import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import _ from 'underscore';


const processRecipeFromAPI = (recipe) =>  {
  console.log(recipe)
  //if (!recipe.initialized)  {
    //Instead of checking for initialized, check if we have the same version as the cloud and ignore the push in that case
    recipe.rack_type = "Gen S"
    

    if (recipe.is_working_version)  {
      recipe.stack_index = recipe.current_stack_index
      recipe.canUndo = recipe.stack_size > 0 && recipe.stack_index > 0
      recipe.canRedo = recipe.stack_index < recipe.stack_size - 1
      recipe.canSave = recipe.stack_index > 0
    } 
 
    recipe.expected_duration = 0
    if (recipe.currentTimelineItemTempId === undefined)  {
      recipe.currentTimelineItemTempId = 1
    }
    if (recipe.timeline_items) {
      for (let timelineItem of recipe.timeline_items)  {
        if (timelineItem.id >= recipe.currentTimelineItemTempId)  {
          recipe.currentTimelineItemTempId = timelineItem.id + 1
        } 

        if (timelineItem.type === "duration_action" && timelineItem.item !== null && timelineItem.item.type === "place_into_germination_chamber_duration")  {
          if (timelineItem.item) {
            recipe.expected_duration += timelineItem.item.duration          
          }
        } else if (timelineItem.type === "germination_cycle" || timelineItem.type === "nursery_cycle" || timelineItem.type === "grow_zone_cycle")  {
          if (timelineItem.item) {
            recipe.expected_duration += timelineItem.item.iterations * timelineItem.item.duration
            if (timelineItem.item.currentSetpointTempId === undefined)  {
              timelineItem.item.currentSetpointTempId = 1;
            }
            if (timelineItem.item.currentLightingSetpointTempId === undefined)  {
              timelineItem.item.currentLightingSetpointTempId = 1;
            }
            if (timelineItem.item.setpoint_zones)  {
              for (let setpointZone of timelineItem.item.setpoint_zones) {
                for (let setpoint of setpointZone.setpoints) {
                  if (setpoint.id >= timelineItem.item.currentSetpointTempId)  {
                    timelineItem.item.currentSetpointTempId = setpoint.id + 1
                  } 
                }
              }
            }
            if (timelineItem.item.lighting_intensity_setpoint_zones)  {
              for (let setpointZone of timelineItem.item.lighting_intensity_setpoint_zones) {
                for (let setpoint of setpointZone.setpoints) {
                  if (setpoint.id >= timelineItem.item.currentLightingSetpointTempId)  {
                    timelineItem.item.currentLightingSetpointTempId = setpoint.id + 1
                  } 
                }
              }
            }
          }
        }
      }
    }

    

    recipe.creator = "Deryk Richardson"

    recipe.shared_with = []
  

    recipe.initialized = true
 
  /*}else {

  }*/


  return recipe
}


export const createRecipe = createAsyncThunk('recipe/createRecipe', async ({recipe, optionalParams, callback}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    new_recipe: recipe
  }
  if (optionalParams !== undefined) {
    payload["opt_params"] = optionalParams
  }
  return await FetchPost(APIRepository.Recipes.CreateRecipe, payload)
},
{
  condition: (args, { getState }) => {
    const { recipes } = getState()
    if (recipes.managingRecipeStatus === 'pending') {
      return false
    }
  },
})


export const removeRecipe = createAsyncThunk('recipe/removeRecipe', async ({recipeId, recipeVersion, callback}, { getState }) => {
  return await FetchPost(APIRepository.Recipes.RemoveRecipe, {
    ...getAccountSessionData(getState()),
    recipe_id: recipeId,
    recipe_version: recipeVersion,
  })
},
{
  condition: (args, { getState }) => {
    const { recipes } = getState()
    if (recipes.managingRecipeStatus === 'pending') {
      return false
    }
  },
})




export const getAllRecipes = createAsyncThunk('recipe/getAllRecipes', async ({}, { getState }) => {
  return await FetchPost(APIRepository.Recipes.GetAllRecipes, {
    ...getAccountSessionData(getState()),
    have_recipes: []
  })  
})

export const getRecipeById = createAsyncThunk('recipes/getRecipeById', async ({recipeId, recipeVersion, recipes}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (recipeId !== undefined) {
    payload.recipe_id = recipeId
    if (recipeVersion !== undefined)  { //assume latest if not provided
      payload.recipe_version = recipeVersion
    }
  }
  if (recipes !== undefined) {
    payload.recipes = recipes
  }
  return await FetchPost(APIRepository.Recipes.GetRecipeById, payload)  
})

export const getWorkingRecipeById = createAsyncThunk('recipe/getWorkingRecipeById', async ({recipeId, haveStackIndex}, { getState }) => {
  return await FetchPost(APIRepository.Recipes.GetWorkingRecipeById, {
    ...getAccountSessionData(getState()),
    recipe_id: recipeId,
    have_stack_index: haveStackIndex
  })
})

export const pushRecipeChange = createAsyncThunk('recipe/pushRecipeChange', async ({recipe}, { getState }) => {
  return await FetchPost(APIRepository.Recipes.PushRecipeChange, {
    ...getAccountSessionData(getState()),
    recipe: recipe
  })
})

export const setRecipeRevisionIndex = createAsyncThunk('recipe/setRecipeRevisionIndex', async ({recipeId, stackIndex}, { getState }) => {
  return await FetchPost(APIRepository.Recipes.SetRecipeRevisionIndex, {
    ...getAccountSessionData(getState()),
    recipe_id: recipeId,
    revision_index: stackIndex
  })
})

export const saveRecipe = createAsyncThunk('recipe/saveRecipe', async ({recipeId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Recipes.SaveRecipe, {
    ...getAccountSessionData(getState()),
    recipe_id: recipeId,
  })
})

export const discardRecipeChanges = createAsyncThunk('recipe/discardRecipeChanges', async ({recipeId, callback}, { getState }) => {
  return await FetchPost(APIRepository.Recipes.DiscardRecipeChanges, {
    ...getAccountSessionData(getState()),
    recipe_id: recipeId,
  })
})





export const recipesSlice = createSlice({
  name: 'recipes',
  initialState: {
    recipes: [],
    currentRecipeTempId: 1,
    status: 'idle',
    lastRecipeRequestOn: null,
    error: null,
    haveInitialData: false,
    loadingData: false,
    managingRecipeStatus: 'idle',
    pushingChangeStatus: 'idle',

    loadingRecipesByIds: [],
  },
  reducers: {
    

    rowUpdate: (state, action) => {
      let hasChanged = false
      let newRows = { ...state, rows: state.rows.map((row, index) => {
        if (row.id !== action.payload.id) {
          return row
        }
    
        if (row[action.payload.prop] === undefined || row[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...row,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newRows
      }
    },

    
    recipeChanged: (state, action) => {
      let hasChanged = false
      let newRecipes = { ...state, recipes: state.recipes.map((recipe, index) => {
        if (recipe.id !== action.payload.recipeId)
          return recipe


        hasChanged = true // TODO perform some sort of validation that changes were actually made to the working revision
        let working_revision = {}
        if (recipe.working_revision)  {
          working_revision = {...recipe.working_revision, ...action.payload.revisionChanges}
        }else {
          working_revision = {...recipe.active_revision , ...action.payload.revisionChanges}
        }
        
        return {
          ...recipe,
          ...action.payload.changes,
          working_revision: working_revision,
          current_revision: working_revision
        }
      })}
      if (hasChanged) {
        return newRecipes
      }
    },

    timelineItemChanged: (state, action) => {
      let hasChanged = false
      state.recipes = state.recipes.map((recipe, recipeIndex) => {
        if (recipe.id !== action.payload.recipeId)
          return recipe

        recipe.timeline_items = recipe.timeline_items.map((timelineItem, timelineItemIndex) => {
          if ((timelineItem.temp_id && timelineItem.temp_id === action.payload.timelineItem.temp_id) || (!timelineItem.temp_id && timelineItem.id === action.payload.timelineItem.id))  {
            hasChanged = true // TODO perform some sort of validation that changes were actually made to the working revision
            return { 
              ...timelineItem,
              item: {...timelineItem.item, ...action.payload.changes}
            }
          }
          return timelineItem
        })


        return {
          ...recipe,
        }
      })
      
      if (hasChanged) {
        //return newRecipes
      }
      
    },

  },
  extraReducers: {
    [createRecipe.pending]: (state, action) => {
      state.managingRecipeStatus = 'pending';
      state.currentRecipeTempId = parseInt(action.meta.arg.recipe["temp_id"]) + 1
      state.recipes = [...state.recipes, processRecipeFromAPI({...action.meta.arg.recipe})]
    },
    [createRecipe.fulfilled]: (state, action) => {
      let foundRecipe = state.recipes.find((r) => r["temp_id"] === action.meta.arg.recipe["temp_id"])
      if (action.payload.error !== undefined && action.payload.error)  {
        console.log(action.payload.error)
        state.recipes.splice(state.recipes.indexOf(foundRecipe), 1)
        return
      }
      if (foundRecipe !== undefined)  {
        foundRecipe.initialSave = true
        foundRecipe.id = action.payload.new_recipe_id
      }
      state.managingRecipeStatus = 'fulfilled';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true, {recipeId: action.payload.new_recipe_id})
      }
    },
    [createRecipe.rejected]: (state, action) => {
      let foundRecipe = state.recipes.find((r) => r["temp_id"] === action.meta.arg.recipe["temp_id"])
      state.recipes.splice(state.recipes.indexOf(foundRecipe), 1)

      
      state.managingRecipeStatus = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false, {})
      }
    },



    [removeRecipe.pending]: (state, action) => {
      state.managingRecipeStatus = 'pending';
    },
    [removeRecipe.fulfilled]: (state, action) => {
      state.recipes = state.recipes.filter((r) => r.id === action.meta.arg.recipeId && r.version === action.meta.arg.recipeVersion)

      state.managingRecipeStatus = 'fulfilled';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(true, {removedRecipeId: action.meta.arg.recipeId, removedRecipeVersion: action.meta.arg.recipeVersion})
      }
    },
    [removeRecipe.rejected]: (state, action) => {
      state.managingRecipeStatus = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false, {})
      }
    },


    [getAllRecipes.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllRecipes.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      state.lastRecipeRequestOn = new Date().getTime();
      action.payload.recipes.map(function(recipe){ processRecipeFromAPI(recipe); return recipe });
      console.log(action.payload.recipes)
      state.recipes = action.payload.recipes;
    },

    [getAllRecipes.rejected]: (state) => {
      state.status = 'rejected';
    },

    [getRecipeById.pending]: (state, action) => {
      state.status = 'pending';

      if (action.meta.arg.recipeId !== undefined) {
        let recipeVersionLoading = action.meta.arg.recipeVersion ?? -1
        let alreadyExists = state.loadingRecipesByIds.find((info) => info.id === action.meta.arg.recipeId && info.version === recipeVersionLoading)
        if (!alreadyExists) {
          state.loadingRecipesByIds.push({id: action.meta.arg.recipeId, version: recipeVersionLoading})
        }
      }
      if (action.meta.arg.recipes !== undefined) {
        for (let recipeInfo of action.meta.arg.recipes) {
          let recipeVersionLoading = recipeInfo.version ?? -1
          let alreadyExists = state.loadingRecipesByIds.find((info) => info.id === recipeInfo.id && info.version === recipeVersionLoading)
          if (!alreadyExists) {
            state.loadingRecipesByIds.push({id: recipeInfo.id, version: recipeVersionLoading})
          }
        }
      }
    },

    [getRecipeById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.lastRecipeRequestOn = new Date().getTime();
      if (action.payload.recipes !== null) {
        console.log(action.meta.arg, action.payload.recipes)
        for (let recipe of action.payload.recipes)  {
          state.recipes.push(processRecipeFromAPI(recipe))

          state.loadingRecipesByIds = state.loadingRecipesByIds.filter((info) => {
            if (info.id === recipe.id && (info.version === recipe.version || (info.version === -1 && recipe.is_active_version)))  {
              return false
            }
            return true
          })
        }
      }
    },

    [getRecipeById.rejected]: (state, action) => {
      state.status = 'rejected';

      if (action.meta.arg.recipeId !== undefined) {
        let recipeVersionLoading = action.meta.arg.recipeVersion ?? -1
        state.loadingRecipesByIds = state.loadingRecipesByIds.filter((info) => {
          if (info.id === action.meta.arg.recipeId && info.version === recipeVersionLoading)  {
            return false
          }
          return true
        })
      }
      if (action.meta.arg.recipes !== undefined) {
        for (let recipeInfo of action.meta.arg.recipes) {
          let recipeVersionLoading = recipeInfo.version ?? -1
          state.loadingRecipesByIds = state.loadingRecipesByIds.filter((info) => {
            if (info.id === recipeInfo.id && info.version === recipeVersionLoading)  {
              return false
            }
            return true
          })
        }
      }
    },

    [getWorkingRecipeById.pending]: (state) => {
      state.status = 'pending';
    },

    [getWorkingRecipeById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (state.pushingChangeStatus === "pending")  {
        return
      }
      if (action.payload.recipe !== null) {
        let foundRecipe = false
        state.recipes = state.recipes.map((r) => {
          if (r.id !== action.payload.recipe.id || r.version !== action.payload.recipe.version) {
            return r
          }
          foundRecipe = true
          if (action.payload.current_stack_index < r.current_stack_index) {
            return {...r, is_pending_push: true}
          }
          return processRecipeFromAPI({
            ...action.payload.recipe, 
            current_stack_index: action.payload.current_stack_index,
            stack_size: action.payload.stack_size,
          })
        })

        if (!foundRecipe)  {
          state.recipes.push(processRecipeFromAPI({
            ...action.payload.recipe, 
            current_stack_index: action.payload.current_stack_index,
            stack_size: action.payload.stack_size,
          }))
        }
      }
    },

    [getWorkingRecipeById.rejected]: (state) => {
      state.status = 'rejected';
    },


    [pushRecipeChange.pending]: (state, action) => {
      state.pushingChangeStatus = 'pending';
      state.recipes = state.recipes.map((r) => {
        if (r.id !== action.meta.arg.recipe.id || !r.is_working_version) {
          return r
        }
        const newStackIndex = action.meta.arg.recipe.stack_index + 1
        //console.log(action.meta.arg.recipe)
        return processRecipeFromAPI({...action.meta.arg.recipe, stack_index: newStackIndex, current_stack_index: newStackIndex})
      })
    },

    [pushRecipeChange.fulfilled]: (state, action) => {
      state.pushingChangeStatus = 'fulfilled';
      if (action.payload.new_stack_size !== null) {
        state.recipes = state.recipes.map((r) => {
          if (r.id !== action.meta.arg.recipe.id || !r.is_working_version) {
            return r
          }
          const newStackIndex = action.meta.arg.recipe.stack_index + 1
          return processRecipeFromAPI({...action.meta.arg.recipe, stack_index: newStackIndex, current_stack_index: newStackIndex, stack_size: action.payload.new_stack_size})
        })
      }
    },

    [pushRecipeChange.rejected]: (state) => {
      state.pushingChangeStatus = 'rejected';
    },

    
    [setRecipeRevisionIndex.pending]: (state, action) => {
      state.status = 'pending';
    },

    [setRecipeRevisionIndex.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.recipe !== undefined) {
        state.recipes = state.recipes.map((r) => {
          if (r.id !== action.meta.arg.recipeId || !r.is_working_version) {
            return r
          }
          return processRecipeFromAPI({
            ...r,
            ...action.payload.recipe,
            current_stack_index: action.meta.arg.stackIndex
          })
        })
      }
    },

    [setRecipeRevisionIndex.rejected]: (state) => {
      state.status = 'rejected';
    },
    

    [saveRecipe.pending]: (state, action) => {
      state.status = 'pending';
    },

    [saveRecipe.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.error !== undefined)  {
        console.log(action.payload.error)
      }
      if (action.payload.recipe !== null) {
        console.log(action.payload.recipe)
        state.recipes = state.recipes.filter((r) => {
          if (r.id !== action.meta.arg.recipeId && (r.is_working_version || r.is_active_version)) {
            return true
          }
          return false
        })
        state.recipes.push(processRecipeFromAPI(action.payload.recipe))

        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(true, {recipeId: action.meta.arg.recipeId, recipeVersion: action.payload.recipe.version})
        }
      }
    },

    [saveRecipe.rejected]: (state) => {
      state.status = 'rejected';
    },


    [discardRecipeChanges.pending]: (state, action) => {
      state.status = 'pending';
    },

    [discardRecipeChanges.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.error !== undefined)  {
        console.log(action.payload.error)
      }
      state.recipes = state.recipes.filter((r) => {
        if (r.id !== action.meta.arg.recipeId) {
          return true
        }
        if (r.is_working_version) {
          return false
        }
        return true
      })
    },

    [discardRecipeChanges.rejected]: (state) => {
      state.status = 'rejected';
    },
    

    
  }
})





export const MaintainRecipeVersions = ({ activeRecipes, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allRecipes = useSelector((state) => selectAllRecipes(state), _.isEqual)
  
  /*Load recipes */
  const validateRecipesToLoad = () => {

    let recipesToLoad = []
    for (let recipeInfo of activeRecipes) {
        let foundRecipe = allRecipes.find((r) => r.id === recipeInfo.id && r.version === recipeInfo.version)
        if (foundRecipe === undefined)  {
            recipesToLoad.push({
                id: recipeInfo.id,
                version: recipeInfo.version
            })
        }
    }

    if (recipesToLoad.length > 0) {
        console.log("Loading recipes: ", recipesToLoad)
        dispatch(getRecipeById({recipes: recipesToLoad}))
    }
  }
  useEffect(() => {
    const recipesLoadInterval = setInterval(() => {
      validateRecipesToLoad()
    }, interval / 10);
    validateRecipesToLoad()
    return () => clearInterval(recipesLoadInterval);
  }, [allRecipes, activeRecipes]);
}




// Action creators are generated for each case reducer function
export const {recipeChanged, timelineItemChanged, rowUpdate} = recipesSlice.actions

export default recipesSlice.reducer

export const selectAllRecipes = state => state.recipes.recipes

export const selectRecipeById = (state, recipeId) =>
  state.recipes.recipes.find(recipe => recipe.id === recipeId)


  
export const selectAllRecipeIDs = (state) => {
  return state.recipes.recipes.map((r) => r.id)
}
export const selectAllAccountIdsForRecipes = (state, recipeIds) => {
  let accountIds = []
  for (let recipe of state.recipes.recipes) {
    if (!accountIds.includes(recipe.created_by_account_id)) {
      accountIds.push(recipe.created_by_account_id)
    }
  }
  return accountIds
}


export const GetCurrentGrowZoneTimelineItem = (timelineItems, elapsedTime, cycle_type = null) => {
  let currentTime = 0
  let currentCycleTime = 0
  let nextTime = 0
  let returningItem = null
  let relevantTimelineItems = 
  timelineItems.filter((t) => {
    if (cycle_type === null || cycle_type === t.type) {
      if (t.type === "nursery_cycle" || t.type === "grow_zone_cycle") {
        return true
      }
    }
    return false
  }) 

  for (let timelineItem of relevantTimelineItems) {
    if (timelineItem.item)  {
      if (returningItem) {
        currentCycleTime += returningItem.item.duration * 1000 * returningItem.item.iterations
      }
      returningItem = timelineItem
      for (let i = 0; i < timelineItem.item.iterations; i++)  {
        nextTime = currentTime + timelineItem.item.duration * 1000
        if (nextTime >= elapsedTime)  {
          return [timelineItem, elapsedTime - currentCycleTime]
        }
        currentTime = nextTime
      }
    }
  }
  if (returningItem)  {
    while(true) {
      nextTime = currentTime + returningItem.item.duration * 1000
      if (nextTime >= elapsedTime)  {
        return [returningItem, elapsedTime]
      }
      currentTime = nextTime
    }
  }
  return [null, 0]

}



export const GetCLIForCycle = (cycle, toTime) =>  {
  let CLI = 0
  if (toTime === undefined)  {
    toTime = cycle.duration
  }
  let lastSetpoint = null
  for (const setpoint of cycle.lighting_intensity_setpoints)  {
    let currentSpectrumRatios = cycle["lighting_spectrum_ratios"]
    if (setpoint.lighting_spectrum_ratios)  {
      currentSpectrumRatios = setpoint.lighting_spectrum_ratios
    }
    if (lastSetpoint) {
      const [PARtoAdd, CLItoAdd] = GetCLIFromPoint(currentSpectrumRatios, lastSetpoint, setpoint.value, lastSetpoint.time, setpoint.time);
      CLI += CLItoAdd
    }

    lastSetpoint = setpoint
  }

  if (lastSetpoint !== null) {
    let currentSpectrumRatios = cycle["lighting_spectrum_ratios"]
    if (lastSetpoint.lighting_spectrum_ratios)  {
      currentSpectrumRatios = lastSetpoint.lighting_spectrum_ratios
    }
    
    const [PARtoAdd, CLItoAdd] = GetCLIFromPoint(currentSpectrumRatios, lastSetpoint, lastSetpoint.value, lastSetpoint.time, cycle.duration);
    CLI += CLItoAdd
  }

  return CLI
}

export const GetCLIFromPoint = (ratios, setpoint, toValue, fromTime, toTime) => {
  let PAR = 0
  let CLI = 0

  const farRedIntensity = 100 / ratios["farred"]

  if (setpoint.function == "instant") {
    let currentPARIntensity = farRedIntensity * setpoint.value;
    PAR += currentPARIntensity * (toTime - fromTime) / 1000000;
    CLI += setpoint.value * (toTime - fromTime) / 1000000;
  } else if (setpoint.function == "gradual") {
    let currentPointPARIntensity = farRedIntensity * setpoint.value;
    let nextPointPARIntensity = farRedIntensity * toValue;
    let currentPARIntensity = ((nextPointPARIntensity - currentPointPARIntensity) / 2 + currentPointPARIntensity);
    let currentPPFDIntensity = ((toValue - setpoint.value) / 2 + setpoint.value);
    PAR += currentPARIntensity * (toTime - fromTime) / 1000000;
    CLI += currentPPFDIntensity * (toTime - fromTime) / 1000000;
  } else if (setpoint.function == "sine_wave") {
    let frequency = 0
    let amplitude = 0
    if (setpoint.function_params.a !== undefined) {
      amplitude = setpoint.function_params.a
    }
    if (setpoint.function_params.f !== undefined) {
      frequency = setpoint.function_params.f
    }

    let b = 2 * Math.PI * frequency;
    let waveAdjustment = ((amplitude * Math.cos(b * (fromTime / 3600)) / b) - (amplitude * Math.cos(b * (fromTime / 3600)) / b)) * 3600 / 1000000;

    let PPFDContribution = (setpoint.value * (toTime - fromTime) / 1000000) + waveAdjustment;
    let currentPARIntensity = farRedIntensity * setpoint.value;
    PAR += PPFDContribution * (currentPARIntensity / 100);
    CLI += PPFDContribution;


    /*} else {
      let currentPARIntensity = farRedIntensity * psetpointoint.value;
      PAR += currentPARIntensity * (toTime - fromTime) / 1000000;
      CLI += setpoint.value * (toTime - fromTime) / 1000000;
    }*/

  } else if (setpoint.function == "square_wave") {
    let frequency = 0
    let amplitude = 0
    if (setpoint.function_params.a !== undefined) {
      amplitude = setpoint.function_params.a
    }
    if (setpoint.function_params.f !== undefined) {
      frequency = setpoint.function_params.f
    }


    //waveAdjustment is intensity per time
    let a = 2 * amplitude / Math.PI
    let b = 2 * Math.PI * frequency

    //(2 * (double)point.FunctionParameters["a"] / Math.PI) * Math.Atan(Math.Sin(2 * Math.PI * n * hz / sampleRate) / delta);

    let waveAdjustment = ((a * Math.atan(Math.cos(b * (fromTime / 3600))) / b) - (a * Math.atan(Math.cos(b * (fromTime / 3600))) / b)) * 3600 / 1000000;

    let PPFDContribution = (setpoint.value * (toTime - fromTime) / 1000000) + waveAdjustment;
    let currentPARIntensity = farRedIntensity * setpoint.value;
    PAR += PPFDContribution * (currentPARIntensity / 100);
    CLI += PPFDContribution;


  /*} else {
    let currentPARIntensity = farRedIntensity * setpoint.value;
    PAR += currentPARIntensity * (toTime - fromTime) / 1000000;
    CLI += setpoint.value * (toTime - fromTime) / 1000000;
  }*/
  }


  return [PAR, CLI]
}