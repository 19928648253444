import './BladeIssuesPopup.scss';
import _ from 'underscore';
import React from 'react';
import PopupModal from '../../../model_components/PopupModal';
import RowGroup from '../../../components/RowGroup/RowGroup';
import { useDispatch, useSelector } from 'react-redux';
import { markActiveIssueAsManuallyResolved, selectBladeIssuesByBladeId } from '../../../redux/entities/service/Blade';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { SoftWarning } from '../../../assets/icons/Icons';
import Button from '../../../components/Button';
  

const BladeIssuesPopup = ({bladeId, closeCallback}) => {
   
    const allBladeIssues = useSelector((state) => selectBladeIssuesByBladeId(state, bladeId), _.isEqual)

    const activeBladeIssues = Object.values(allBladeIssues).filter((bI) => bI.active)

    const dispatch = useDispatch()
    const [markingBladeIssueAsResolved, SetMarkingBladeIssueAsResolved] = React.useState(false)
    const markIssueAsResolved = (issueId) => {
        SetMarkingBladeIssueAsResolved(true)
        dispatch(markActiveIssueAsManuallyResolved({bladeId: bladeId, issueId: issueId, callback: (success) => {
            SetMarkingBladeIssueAsResolved(false)
        }}))
    }

    return (<>
        <PopupModal
            id={"ManageBladeWalkthrough_Popup"}
            title={<>
                <div className="FlexContent-H-10 FlexContent-Center"><SoftWarning fill={"#EC3C3C"}/>Blade Issues</div>
            </>}
            hideMainSeparators={true}
            closeCallback={closeCallback}
            size={"full"}
            footer={<>
                <Button content={"Done"} status={"Primary"} onClick={closeCallback} width={"Flex100"}/>
            </>}>
            <>
                {activeBladeIssues.length === 0 && <>
                    <div>No active issues</div>
                </>}
                {activeBladeIssues.length > 0 && <>
                    <div className="BladeIssuesPopup-Issues">
                        {activeBladeIssues.map((bladeIssue) => {
                            const issueKeyMap = {
                                "primary_aero": {
                                    label: "Aeroponics",
                                    subkeyMap: {
                                        "over_pressure": {content: <>Over Pressure</>},
                                        "low_pressure": {content: <>Low Pressure</>},
                                        "low_liquid_volume": {content: <>Reservoir Severely Low</>}
                                    }
                                },
                                "canopy_air": {
                                    label: "Canopy Air",
                                    subkeyMap: {
                                        "temp_too_high": {content: <>Temperature in the canopy has risen 3C above setpoint</>}
                                    }
                                },
                                "variable_speed_compressor":    {
                                    label: "AC Compressor",
                                    subkeyMap: {
                                        "error_code": {content: <>Error Code: {bladeIssue.info["code"]}</>},
                                        "discharge_over_temp": {content: <>Discharge temperature has risen above 95C</>}
                                    }
                                },
                                "motion_distance_sensor": {
                                    label: "Motion Distance Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "water_level_sensor_4": {
                                    label: "Nursery A (Lower) Water Level Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "water_level_sensor_3": {
                                    label: "Primary Water Level Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "water_level_sensor_2": {
                                    label: "Nursery B (Upper) Water Level Sensor",
                                    subkeyMap: {
                                        "com_fault": {content: <>Failed to communicate</>}
                                    }
                                },
                                "primary_dosing": {
                                    label: "Primary Dosing",
                                    subkeyMap: {
                                        "NDS_locked_out": {content: <>
                                            <div className="FlexContent-H-10">
                                                <div>Dosing has been locked out</div>
                                                <div>Restore Now</div>
                                            </div></>
                                        },
                                        "over_pressure": {content: <>Pressure Exceeded 130PSI</>}
                                    }  
                                }
                            }
                            let issueName = bladeIssue.key
                            let issueInfo = bladeIssue.sub_key
                            let foundIssueKey = issueKeyMap[bladeIssue.key]
                            if (foundIssueKey !== undefined)    {
                                issueName = foundIssueKey.label
                                let foundIssueSubkey = foundIssueKey.subkeyMap[bladeIssue.sub_key]
                                if (foundIssueSubkey !== undefined) {
                                    issueInfo = foundIssueSubkey.content
                                }
                            }
                            let issueDate = new Date(Date.parse(bladeIssue.triggered_on))
                            return <>
                                <div key={bladeIssue.local_id} className={"BladeIssuesPopup-Issue-Container" + (bladeIssue !== activeBladeIssues[activeBladeIssues.length - 1] ? " Linebreak-20" : "")}>
                                    <div className="BladeIssuesPopup-Issue">
                                        <div className="BladeIssuesPopup-Issue-Title">
                                            <div className="Text-S16">{issueName}</div>
                                            <div className="Text-AltI14">{issueDate.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>
                                        </div>
                                        <div className="Text-S14">{issueInfo}</div>
                                    </div>
                                    <Button status={"Hyperlink"} content={"Mark as resolved"} contentPadding={0} onClick={() => {markIssueAsResolved(bladeIssue.local_id)}} disabled={markingBladeIssueAsResolved}/>
                                </div>
                            </>
                        })}
                    </div>
                </>}
            </>
        </PopupModal>
        
    </>)

}



export default BladeIssuesPopup