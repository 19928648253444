import './TabControl.scss';

import React, {useCallback, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

export class TabControlTab {
  constructor(name, path, page, defaultSelected = false) {
    this.name = name;
    this.path = path;
    this.key = this.path;
    this.page = page;
    this.selected = defaultSelected;
  }
}

export const TabControl = ({tabs, content, activePathIndex, hideTabs, tabClickedRef, tabLocation, onTabSelected}) => {
  const [ActiveTab, SetActiveTab] = useState();

  const navigate = useNavigate();
  const SelectActiveTab = useCallback((tab) => {
      if (onTabSelected !== undefined)  {
        onTabSelected(tab.path)
      }else {
        for (let tab of tabs) {
          tab.selected = false;
        }
        SetActiveTab(tab);
        tab.selected = true;
        navigate(tab.path, { replace: true });  
      }
    }
  );

  const tabClickedRefCallback = (tab) => {
    SelectActiveTab(tab);
  }

  if (tabClickedRef !== undefined)  {
    tabClickedRef(tabClickedRefCallback)
  }

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const activePath = activePathIndex ? (splitLocation.length >= activePathIndex ? splitLocation[activePathIndex] : "") : splitLocation[splitLocation.length - 1]
  if (activePathIndex !== false)  {
    for (let tab of tabs) {
      if (tab.path === activePath )  {
        tab.selected = true;
      }else {
        tab.selected = false;
      }
    }
  }

 
  return (
    <div className="TabControl">
      {!hideTabs && 
        <div className={"TabControl_Tabs-Container" + (tabLocation === "center" ? " TabControl_Tabs-Center" : "")}>
          <div className={"TabControl_Tabs" + (tabLocation === "flex" ? " TabControl_Tabs-Flex" : "")}>
            {tabs.map((tab) => (
                <div onClick={() => SelectActiveTab(tab)} key={tab.key} className={"TabControl_Tab" + (tab.selected === true ? " TabControl_Tab-Selected" : "")}>
                  <div className="TabControl_TabLabel noselect">{tab.name}</div>
                  {tab.selected === true &&
                    <div className="TabControl_TabSelectedIndicator"/>
                  }
                </div>
            ))}
          </div>
        </div>
      }
      {content && <>
        <div className="TabControl_ContentWrapper">
            {content}
        </div>
      </>}
    </div>
  )
} 

TabControl.defaultProps = {
  activePathIndex: false,
  tabs: [new TabControlTab("Default Tab", "", null, true)],
  hideTabs: false,
  tabLocation: "left",
  onTabSelected: undefined
}
