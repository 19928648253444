import './HarvestTask.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import HarvestTask_ZoneListWalkthroughStep from './HarvestTask_ZoneListWalkthroughStep';
import { getAllServicesForFacility, selectAllFacilities } from '../../../../redux/entities/Facilities';
import { getGerminationChamberByServiceId, selectAllGerminationChamber } from '../../../../redux/entities/service/GerminationChamber';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllGrowSeeds } from '../../../../redux/entities/PlantTypes';
import { getRecipeById } from '../../../../redux/entities/Recipes';
import { getGrowById, getGrowGrowoutBoardLocations } from '../../../../redux/entities/Grow';
import { getGrowPlanById } from '../../../../redux/entities/GrowPlans';
import Walkthrough from '../../../../components/Walkthrough';
import { getBladeZoneByUID, selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import HarvestTask_ContainerOptionsWalkthroughStep from './HarvestTask_ContainerOptionsWalkthroughStep';
import HarvestTask_ContainerContentsWalkthroughStep from './HarvestTask_ContainerContentsWalkthroughStep';
import HarvestTask_ContainerWeightWalkthroughStep from './HarvestTask_ContainerWeightWalkthroughStep';
import { selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import HarvestTask_ContainerWeightSubstepWalkthroughStep from './HarvestTask_ContainerWeightSubstepWalkthroughStep';
import HarvestTask_ContainerListWalkthroughStep from './HarvestTask_ContainerListWalkthroughStep';
import HarvestTask_PrepareForHarvestWalkthroughStep from './HarvestTask_PrepareForHarvestWalkthroughStep';
import HarvestTask_ZoneCleanOptionsWalkthroughStep from './HarvestTask_ZoneCleanOptionsWalkthroughStep';


const HarvestTask = ({task, onCloseTask, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeTask = () =>  {
    onCloseTask()
  }
  const closePopup = () =>  {
    onClosePopup()
  }


  const allFacilities = useSelector(selectAllFacilities)




  let allGrows = useSelector((state) => state.grows.grows)
  const activeGrow = allGrows.find((g) => g.id === task.reference_id)
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow === undefined)  {
      dispatch(getGrowById({growId: task.reference_id}))
    }
  }, [task, allGrows])


  let allGrowPlans = useSelector((state) => state.growPlans.growPlans)
  const activeGrowPlan =  activeGrow !== undefined ? allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundGrowPlan = allGrowPlans.find((gP) => gP.id === activeGrow.grow_plan_id)
      if (foundGrowPlan === undefined)  {
        dispatch(getGrowPlanById({growPlanId: activeGrow.grow_plan_id}))
      }
    }
  }, [activeGrow, allGrowPlans])
  
  
  let allRecipes = useSelector((state) => state.recipes.recipes)
  const activeRecipe =  activeGrow !== undefined ? allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version) : undefined
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let foundRecipe = allRecipes.find((r) => r.id === activeGrow.recipe_id && r.version === activeGrow.recipe_version)
      if (foundRecipe === undefined)  {
        dispatch(getRecipeById({recipes: [{id: activeGrow.recipe_id, version: activeGrow.recipe_version}]}))
      }
    }
  }, [activeGrow, allGrowPlans])
  

  
  React.useEffect(() => {
    let foundGrow = allGrows.find((g) => g.id === task.reference_id)
    if (foundGrow !== undefined)  {
      if (!foundGrow.loaded_growout_locations && !foundGrow.loading_growout_locations) {
        dispatch(getGrowGrowoutBoardLocations({growId: foundGrow.id}))
      }
    }
  }, [allGrows])


  const allPlantVarieties = useSelector((state) => selectAllPlantVarieties(state))



  const allBladeZones = useSelector((state) => selectAllBladeZones(state))
  
  React.useEffect(() => {
    if (activeGrow !== undefined) {
      let bladeZonesToLoad = []
      if (activeGrow.growout_location_entries !== null) {
        for (let zoneInfo of activeGrow.growout_location_entries) {
          let foundBladeZone = allBladeZones.find((z) => z.uid === zoneInfo.grow_out_uid)
          if (foundBladeZone === undefined) {
            bladeZonesToLoad.push(zoneInfo.grow_out_uid)
          }
        }
      }
      if (bladeZonesToLoad.length > 0) {
        dispatch(getBladeZoneByUID({UIDs: bladeZonesToLoad}))
      }
    }
  }, [allBladeZones, activeGrow])


  const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))
  


  const [harvestingZoneUID, SetHarvestingZoneUID] = React.useState(null)
  const beginHarvestingZoneConfirmed = ({zoneUID}) => {
    SetHarvestingZoneUID(zoneUID)
  }

  const [containerOption, SetContainerOption] = React.useState(null)
  const containerOptionConfirmed = ({containerOption}) => {
    SetContainerOption(containerOption)
  }

  const [containerContents, SetContainerContents] = React.useState(null)
  const containerContentsConfirmed = ({containerContents}) => {
    SetContainerContents(containerContents)
  }

  const [containerWeight, SetContainerWeight] = React.useState(null)
  const containerWeightConfirmed = ({containerWeight}) => {
    SetContainerWeight(containerWeight)
  }
  
  const [containerSubstepIndex, SetContainerSubstepIndex] = React.useState(null)
  const containerContentsSubstepUpdated = (containerSubstepIndex) => {
    SetContainerSubstepIndex(containerSubstepIndex)
  }



  const [containers, SetContainers] = React.useState([])
  const containersListConfirmed = ({containers}) => {
    SetContainers(containers)
  }

  const resetForNewContainer = () => {
    SetContainerOption(null)
    SetContainerContents(null)
    SetContainerWeight(null)
    SetContainerSubstepIndex(null)
  }
   
  const taskConfirmedCompleted = () =>  {
    onCloseTask()
  }


  /* Walkthrough requirements */
  let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["zone_list", null])
  let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
  let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
  const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })
  const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })

  const transitionToPreviousStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetPreviousWalkthroughStep(stepKeys)
  }
  const transitionToNextStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetNextWalkthroughStep(stepKeys)
  }




  const loadWalkthroughStep = (stepKeys) =>    {
      const stepKey = stepKeys[0]
      const subStepKey = stepKeys[1]
      switch (stepKey)    {
        case "zone_list":
          return (<HarvestTask_ZoneListWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            beginHarvestingZoneConfirmedCallback={beginHarvestingZoneConfirmed}
            cancelTaskCallback={onCloseTask}
            containerContentsConfirmedCallback={containerContentsConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 

        /*case "prepare_for_harvest":
          return (<HarvestTask_PrepareForHarvestWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            allBladeZones={allBladeZones}
            harvestingZoneUID={harvestingZoneUID}
            cancelTaskCallback={onCloseTask}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) */

        /*case "container_options":
          return (<HarvestTask_ContainerOptionsWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerOptionConfirmedCalback={containerOptionConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) */
    
        case "container_contents":
          return (<HarvestTask_ContainerContentsWalkthroughStep
            task={task}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerOption={containerOption}
            initialContainerContents={containerContents}
            containerContentsConfirmedCallback={containerContentsConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>)

        case "container_weight":
          return (<HarvestTask_ContainerWeightWalkthroughStep
            task={task}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerOption={containerOption}
            containerContents={containerContents}
            initialContainerWeight={containerWeight}
            containerWeightConfirmedCallback={containerWeightConfirmed}
            containerContentsSubstepCallback={containerContentsSubstepUpdated}
            containers={containers}
            containersListConfirmedCallback={containersListConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>) 
        

        case "container_weight_substep":
          return (<HarvestTask_ContainerWeightSubstepWalkthroughStep
            task={task}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerOption={containerOption}
            containerContents={containerContents}
            containerWeight={containerWeight}
            containerSubstepIndex={containerSubstepIndex}
            containerWeightConfirmedCallback={containerWeightConfirmed}
            containerContentsSubstepCallback={containerContentsSubstepUpdated}
            containers={containers}
            containersListConfirmedCallback={containersListConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>) 
    
    
        case "container_list":
          return (<HarvestTask_ContainerListWalkthroughStep
            task={task}
            allPlantVarieties={allPlantVarieties}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            containerOption={containerOption}
            containerContents={containerContents}
            containerWeight={containerWeight}
            containers={containers}
            containersListConfirmedCallback={containersListConfirmed}
            resetForNewContainerCallback={resetForNewContainer}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}/>) 
            
        case "clean_options":
          return (<HarvestTask_ZoneCleanOptionsWalkthroughStep
            task={task}
            activeGrow={activeGrow}
            activeGrowPlan={activeGrowPlan}
            harvestingZoneUID={harvestingZoneUID}
            containers={containers}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep}
            taskCompletedCallback={taskConfirmedCompleted}/>) 
            
        default:
            return <></>
      }
  }


  /*let initialWalkthroughStep = null
  if (activeGrowPlan !== undefined) {
    if (activeGrowPlan.plant_variants.length > 1) {
      initialWalkthroughStep = "seeding_list"
    }else {
      initialWalkthroughStep = "seeding"
    }
    console.log(activeGrowPlan.plant_variants)
  }*/


  return (<>
    <Walkthrough
      title="Harvest"
      subtitle={<>#G{activeGrow !== undefined ? activeGrow.id : ""}</>}
      size="full_screen"
      closeCallback={closePopup}
      hideMainSeparators={false}
      initialWalkthroughStep={["zone_list", null]}
      transitionToWalkthroughStep={nextWalkthroughStep}
      transitionBackToWalkthroughStep={previousWalkthroughStep}
      buildWalkthroughStepCallback={loadWalkthroughStep}
      transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
      transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}

    />
  </>)
} 

export default HarvestTask