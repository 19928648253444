import './MoveGrowBagsIntoGrowOutTask.scss';


import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
 
const MoveGrowBagsIntoGrowOutTask_TransplantList = ({
    allBladeZones,
    activeGrow,
    cancelTaskCallback,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const startTransplantClicked = () => {
        transitionToNextStep(["select_grow_out_zone", null])
    }


    const finishedTransplantingClicked = () =>  {
        transitionToNextStep(["final_review", null])
    }
    



    const [transplantEntries, SetTransplantEntriesList] = React.useState([])

    React.useLayoutEffect(() => {
        let allCompletedTransplantEntries = activeGrow !== undefined ? activeGrow.growout_location_entries ?? [] : []
        SetTransplantEntriesList([...allCompletedTransplantEntries, {
            zone_uid: "",
            is_placeholder: true,
        }])
    }, [activeGrow])



    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Zone Name</>, setPosition: (bounds, contentWidth) => {return {left: isMobile ? bounds.left - 30 : bounds.left, width: bounds.width}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
        units: {label: <>Units</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        action: {label: <></>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        }
    }
    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className={isMobile ? "" : "FlexContent-15"}>
                        <div className="Text-SubHeader Text-Wrap">
                            Step 1: Select start move to begin
                        </div>
                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false}
                            hasContainer={false}
                            itemsScroll={true}
                            isExpandable={false}
                            hasColumnHeaders={true}
                            columnHeaders={columnHeaders}
                            columnSizes={columnSizes}
                            hasSubGroups={true}>

                            <TransplantToGrowoutEntryRow isPlaceholder={true} columnSizesChanged={(columns) => {
                                SetColumnSizes(columns)
                            }}/>
                            {transplantEntries.map((transplantEntry, index) => {
                                return <>
                                    <TransplantToGrowoutEntryRow 
                                        transplantEntry={transplantEntry}
                                        index={index} 
                                        allBladeZones={allBladeZones}
                                        startTransplantClicked={startTransplantClicked}
                                        columnSizes={columnSizes}/>
                                </>
                            })}
                        </RowGroup>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelTaskCallback} size={"Flex50"}/>
                <Button status="Primary" content={"Finished"} onClick={finishedTransplantingClicked} size={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default MoveGrowBagsIntoGrowOutTask_TransplantList








export const TransplantToGrowoutEntryRow = ({transplantEntry, index, allBladeZones, isPlaceholder, columnSizes, columnSizesChanged, startTransplantClicked}) => {
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  

    const isExistingTransplant = (!isPlaceholder && transplantEntry.is_placeholder !== undefined && transplantEntry.is_placeholder)
    let foundBladeZone = undefined
    if (!isPlaceholder && !isExistingTransplant)  {
        foundBladeZone = allBladeZones.find((bZ) => bZ.uid === transplantEntry.grow_out_uid)
    }

    let secondaryHeaderContent = []
    if (!isMobile) {
      secondaryHeaderContent.push({
        key: "units", content: <>
        <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                    <div className="Text-SoftTruncate">{transplantEntry.number_of_units} Units</div>
                    <div className="Text-SoftTruncate">{transplantEntry.number_of_grow_out_rafts} Bags</div>
                </> : <>
                    <div className="Text-SoftTruncate">1720 Units</div>
                    <div className="Text-SoftTruncate">136 Bags</div>
                </>
            )}</div>
        </div>
      </>
      })
      secondaryHeaderContent.push({
        key: "action", content: <>
          <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                    {isExistingTransplant && <>
                        <Button content={"Start Move"} status={"Primary-Inverted"} onClick={startTransplantClicked}/>                    
                    </>}
                    {!isExistingTransplant && <>
                        <Button content={"Edit"} status={"Primary-Inverted"}/>                    
                    </>}
                </> : <>
                    <Button content={"Start Move"} status={"Primary-Inverted"}/>
                </>
            )}</div>
          </div>
        </>
      })
      
    }
    

    //transplantEntry.zone_uid
    let title = isPlaceholder ? <>Unnamed zone for transplanting</> : (isExistingTransplant ? "Pending" : (foundBladeZone !== undefined ? foundBladeZone.display_name : "Unnamed Zone"))
    
    return (<>
      <RowGroupRow
        key={isPlaceholder ? 0 : index}
        title={<div className="Text-SubHeader">{title}</div>}
        titleFontSize={14}
        cardBackgroundColor={"transparent"}
        cardBorderColor={"transparent"}
        showRowSeperator={true}
        showExpandedStatusContainerStyles={false}
        statusContainerPadding={0}
        minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
        hasFlexCell={true}
        showStatusIndicator={false}
        isPlaceholderRow={isPlaceholder}
        columnSizesChanged={columnSizesChanged ?? (() => {})}
        columnSizes={columnSizes}
        secondaryContent={secondaryHeaderContent}
        hasIssues={false}
        numberOfIssues={0}
        isSelectable={false}
        isExpandable={isMobile}
        hasAdditionalFunctions={false}
        content={<>
          {(() => {
            if (isMobile && !isPlaceholder) {
              return (<>
                <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                    {isExistingTransplant && <>
                        <Button content={"Start Transplant"} status={"Primary-Inverted"} onClick={startTransplantClicked}/>                    
                    </>}
                    {!isExistingTransplant && <>
                        <Button content={"Edit"} status={"Primary-Inverted"}/>                    
                    </>}
                </div>
              </>)
            }
        
          })()}
        </>}
      />
    </>)
  }