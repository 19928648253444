import { useSpring, animated } from 'react-spring';
import './RowGroupRow.scss';

import * as React from 'react';
import { AdditionalOptions, CollapseContent, ExpandContent, Gear, SoftWarning } from '../../assets/icons/Icons';
import Button from '../Button';
import { useMediaQuery } from 'react-responsive';
import DropDownButton from '../DropDownButton';
import useMeasure from '../../useMeasure';




const RowGroupRow_HeaderCell = ({contentInfo, cellBoundsChanged, columnSizes, rowWidth, isPlaceholderRow}) => {
  let cellStyleProps = {}

  
  if (columnSizes[contentInfo.key] !== undefined) {
    cellStyleProps.width = columnSizes[contentInfo.key].width
  }else {
    if (contentInfo.flex === true)  {
      cellStyleProps.flex = 1
    }else {
      if (!isPlaceholderRow) {
        cellStyleProps.flex = "0 1 auto"
      }
    }
  }

  const [cellHandleRef, {width: cellWidth, documentLeft: cellLeft}] = useMeasure()
  const [cellContentHandleRef, {width: cellContentWidth}] = useMeasure()
  React.useLayoutEffect(() => {
    if (cellBoundsChanged !== undefined && contentInfo.key !== undefined)  {
      cellBoundsChanged(contentInfo.key, {width: cellWidth, contentWidth: cellContentWidth, documentLeft: cellLeft})
    }
  }, [cellWidth, cellContentWidth, cellLeft, rowWidth])


  let customCellStyle = {...contentInfo.style} ?? {}
  if (isPlaceholderRow) {
    customCellStyle.whiteSpace = "nowrap"
  }

  return (
    <div className="RowGroupRow-Header-CellWrapper" style={cellStyleProps} ref={cellHandleRef}>
      <div className="RowGroupRow-Header-SecondaryContent-Cell" style={customCellStyle} ref={cellContentHandleRef}>
        {contentInfo.content}
      </div>
    </div>
  )
}


const RowGroupRow = ({
                  title, titleFontSize, minimumTitleWidth, titleAuxContent, subtitle, 
                  content, secondaryContent, extendedHeaderContent,
                  cardBackgroundColor, cardBorderColor,
                  status, showExpandedStatusContainerStyles, statusColor, statusColumnWidth, statusContainerPadding, 
                  showStatusIndicator, hasFlexCell, statusContainerClicked, 
                  isExpandable, expandedState, onContentExpandChanged,
                  isSelectable, selectedState, onSelectedChanged, showNeutralSelectionColor,
                  hasAdditionalFunctions,
                  customRowPadding,
                  additionalFunctions, secondaryContentTriggersExpandToggle,
                  hasIssues, numberOfIssues, customIssuesContent,
                  issuesClicked,
                  isPlaceholderRow, placeholderRowWidthCallback, columnSizes, columnSizesChanged,
                  renderContentOnlyWhenOpen
                }) => {


  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop
  

  const [isExpanded, SetIsExpanded] = React.useState(false)
  React.useLayoutEffect(() => {
    SetIsExpanded(expandedState)
  }, [expandedState])
  const toggleExpandedState = (state) =>  {
    if (isExpandable) {
      SetIsExpanded(state)
      if (onContentExpandChanged !== undefined) {
        onContentExpandChanged(state)
      }
    }
  }


  const [isSelected, SetIsSelected] = React.useState(false)
  React.useLayoutEffect(() => {
    SetIsSelected(selectedState)
  }, [selectedState])
  const toggleSelectedState = (state) =>  {
    if (isSelectable) {
      if (onSelectedChanged === undefined || onSelectedChanged(state) !== false) {
        SetIsSelected(state)
      }
    }
  }
  

  const [rowContentRefHandler, {height: rowContentHeight}] = useMeasure()

  const rowPadding = (customRowPadding !== false) ? customRowPadding : (isMobile ? 9 : 9)
  /*const [nextCardTransitionToCurrentCardAnimation, nextCardTransitionToCurrentCardAnimationAPI] = useSpring(() => ({
    from: { left: walkthroughContentWidth },
  }))*/

  let footerShouldShow = false
  if (isExpandable) {
    footerShouldShow = true
  } 

  let isStatusContainerActive = false
  let statusContainerProps = {style:{padding: statusContainerPadding}}
  if (statusContainerClicked !== undefined) {
    statusContainerProps.onClick = statusContainerClicked
    statusContainerProps.style.cursor = "pointer"
  }
  if (hasIssues && numberOfIssues > 0)  {
    isStatusContainerActive = true
    statusContainerProps.style.backgroundColor = "#FBEAEA"
    statusContainerProps.style.borderColor = "#EC3C3C"
  }else if (isExpandable && isExpanded && showExpandedStatusContainerStyles) {
    isStatusContainerActive = true
    statusContainerProps.style.backgroundColor = "#F0F4FB"
    statusContainerProps.style.borderColor = "#2E72D2"
  }else if (isSelectable && isSelected) {
    statusContainerProps.style.backgroundColor = "#F0F4FB"
    statusContainerProps.style.borderColor = "#2E72D2"
  }else if (isSelectable && showNeutralSelectionColor)  {
    statusContainerProps.style.borderColor = "#DDE3EB"
  }

  let issuesContent = <></>
  if (customIssuesContent !== false)  {
    issuesContent = customIssuesContent
  }else {
    issuesContent = <>
      <SoftWarning fill={"#EC3C3C"} width={21}/>
      <div className="RowGroupRow-Issues-Content">
        {numberOfIssues} Issue{numberOfIssues > 1 && "s"}
      </div>
    </>
  }

  const issuesCellClicked = () => {
    if (issuesClicked !== undefined)  {
      issuesClicked()
    }
  }


  let haveSecondaryContent = false
  if (!isMobile && hasIssues && numberOfIssues > 0)  {
    haveSecondaryContent = true
  }else if (secondaryContent !== undefined && secondaryContent !== null && secondaryContent.length > 0) {
    haveSecondaryContent = true
  }



  const [columnBounds, SetColumnBounds] = React.useState({})

  const [rowContainerRefHandler, {width: rowContainerWidth}] = useMeasure()
  React.useLayoutEffect(() => {
    if (placeholderRowWidthCallback !== undefined)  {
      placeholderRowWidthCallback(rowContainerWidth)
    }
  }, [rowContainerWidth, placeholderRowWidthCallback])

  const [rowGroupHeaderHandleRef, {width: rowGroupHeaderWidth, documentLeft: rowGroupHeaderLeft}] = useMeasure()

  const [expandedContentToggleCellHandleRef, {width: expandedContentToggleCellWidth, documentLeft: expandedContentToggleCellLeft}] = useMeasure()
  let expandedContentToggleCellStyleProps = {flexShrink: "0"}
  if (columnSizes["expanded_content_toggle"] !== undefined) {
    expandedContentToggleCellStyleProps.width = columnSizes["expanded_content_toggle"].width
  }

  const [statusContentCellHandleRef, {width: statusContentCellWidth, documentLeft: statusContentCellLeft}] = useMeasure()
  //console.log(statusContentCellWidth)
  let statusContentCellStyleProps = {}
  if (columnSizes["status"] !== undefined) {
    statusContentCellStyleProps.width = columnSizes["status"].width
  }else if (statusColumnWidth !== null) {
    if (statusColumnWidth.indexOf("ch") !== -1) {

    }
  }else {
    statusContentCellStyleProps.flex = "1 1 auto" 
  }


  const [additionalFunctionsCellHandleRef, {width: additionalFunctionsCellWidth, documentLeft: additionalFunctionsCellLeft}] = useMeasure()
  let additionalFunctionsCellStyleProps = {}
  if (columnSizes["additional_functions_button"] !== undefined) {
    additionalFunctionsCellStyleProps.width = columnSizes["additional_functions_button"].width
  }

  const cellBoundsChanged = React.useCallback((key, bounds) =>  {
    SetColumnBounds(prev => {
      return {
        ...prev,
        "container_width": rowContainerWidth,
        [key]: {width: bounds.width, contentWidth: bounds.contentWidth, left: bounds.documentLeft - rowGroupHeaderLeft + rowPadding}
      }
    });
  })

  React.useLayoutEffect(() => {
    SetColumnBounds(prev => {
      return {
        ...prev,
        "container_width": rowContainerWidth,
        "expanded_content_toggle": {width: expandedContentToggleCellWidth, left: expandedContentToggleCellLeft - rowGroupHeaderLeft + rowPadding},
        "status": {width: statusContentCellWidth, left: statusContentCellLeft - rowGroupHeaderLeft + rowPadding},
        "additional_functions_button": {width: additionalFunctionsCellWidth, left: additionalFunctionsCellLeft - rowGroupHeaderLeft + rowPadding,}
      }
    });

  }, [rowGroupHeaderLeft, 
    expandedContentToggleCellWidth, expandedContentToggleCellLeft,
    statusContentCellWidth, statusContentCellLeft,
    additionalFunctionsCellWidth, additionalFunctionsCellLeft,
  ])
  


  

  React.useLayoutEffect(() => {
    //console.log(columnBounds)
    if (columnSizesChanged !== undefined) {
      columnSizesChanged(columnBounds)
    }
  }, [columnBounds, rowContainerWidth])


  const headerTitleProps = {style:{}}
  if (isPlaceholderRow && minimumTitleWidth !== false)  {
    headerTitleProps.style.width = minimumTitleWidth
  }
  const headerTitleContentProps = {style:{fontSize: titleFontSize}}

  const secondaryContentProps = {style:{}}
  if (secondaryContentTriggersExpandToggle) {
    secondaryContentProps.onClick = () => toggleExpandedState(!isExpanded)
  }


  let hasSecondaryContent = false
  if (hasIssues && numberOfIssues > 0)  {
    hasSecondaryContent = true
  }else if (secondaryContent !== undefined && secondaryContent !== null && secondaryContent.length > 0) {
    hasSecondaryContent = true    
  }



  
  if (!isExpandable)  {
    statusContentCellStyleProps.marginLeft = 10
  }
  if (!hasAdditionalFunctions)  {
    if (hasSecondaryContent)  {
      secondaryContentProps.style.marginRight = 10
    }else {
      statusContentCellStyleProps.marginRight = 10
    }
  }

  /*useRenderingTrace("RowGroupRow", {
    rowContainerRefHandler,
    rowGroupHeaderHandleRef

  })*/
  return (<>
    <div className={"RowGroupRow-Container" + (isPlaceholderRow ? " RowGroupRow-PlaceholderRow" : "")} ref={rowContainerRefHandler}>
      <div className={"RowGroupRow-StatusContainer" + (isStatusContainerActive ? " RowGroupRow-StatusContainer-Active" : "")} {...statusContainerProps}>
        <div className={"RowGroupRow"} style={{padding: "0px " + rowPadding + "px"}} onClick={() => {toggleSelectedState(!isSelected)}}>
          <div className="RowGroupRow-Cards" style={{backgroundColor: cardBackgroundColor, borderColor: cardBorderColor}}>
            <div className={"RowGroupRow-Header"}  ref={rowGroupHeaderHandleRef}>
              <div className="RowGroupRow-Header-PrimaryContent" onClick={() => {toggleExpandedState(!isExpanded)}} style={{paddingLeft: isExpandable ? 0 : 0, flex: (hasFlexCell === true) ? "1 1 auto" : "1 0 auto"}}>
                {isExpandable && 
                  <div className="RowGroupRow-Header-CellWrapper" style={expandedContentToggleCellStyleProps} ref={expandedContentToggleCellHandleRef}>
                    <div className="RowGroupRow-Header-ExpandedContentToggle">
                      <div className="RowGroupRow-Header-ExpandedContentToggle-Button">
                        {isExpanded && <CollapseContent/>}
                        {!isExpanded && <ExpandContent/>}
                      </div>
                    </div>
                  </div>
                }
                <div className="RowGroupRow-Header-CellWrapper" style={statusContentCellStyleProps} ref={statusContentCellHandleRef}>
                  <div className="RowGroupRow-Header-StatusContent">
                    <div className="RowGroupRow-Header-IdentifyingContent">
                      <div className="RowGroupRow-Header-IdentifyingContent-Primary">
                        <div className="RowGroupRow-Header-IdentifyingContent-Title" {...headerTitleProps}>
                          <div className="RowGroupRow-Header-IdentifyingContent-Title-Content noselect" {...headerTitleContentProps}>
                            {title}
                          </div>
                          {showStatusIndicator && 
                            <div className="RowGroupRow-Header-IdentifyingContent-StatusIndicator" style={{backgroundColor: statusColor}}></div>
                          }
                          {titleAuxContent && 
                            <div className="RowGroupRow-Header-IdentifyingContent-Title-AuxContent">{titleAuxContent}</div>
                          }
                        </div>
                      </div>
                      {subtitle && 
                        <div className="RowGroupRow-Header-IdentifyingContent-Secondary">
                          <div className="RowGroupRow-Header-IdentifyingContent-Subtitle">
                            <div className="RowGroupRow-Header-IdentifyingContent-Subtitle-Content noselect">
                              {subtitle}
                            </div>
                          </div>
                        </div>
                      }
                    </div>


                  </div>
                </div>
              </div>
              {hasSecondaryContent && 
                <div className="RowGroupRow-Header-SecondaryContent" style={{flex: (hasFlexCell === true) ? "1 1 auto" : "1 0 auto"}} {...secondaryContentProps}>
                  {!isMobile && <>
                    {(hasIssues && numberOfIssues > 0) && 
                      <div className="RowGroupRow-Header-SecondaryContent-Cell" onClick={issuesCellClicked}>
                        <div className="RowGroupRow-Header-Issues-Container">
                          <div className="RowGroupRow-Header-Issues noselect">
                            {issuesContent}  
                          </div>
                        </div>
                      </div>
                    }
                  </>}
                  {(secondaryContent !== undefined && secondaryContent !== null && secondaryContent.length > 0) && <>
                    {secondaryContent.map((contentInfo) => {

                      return (<RowGroupRow_HeaderCell key={contentInfo.key} contentInfo={contentInfo} cellBoundsChanged={cellBoundsChanged} columnSizes={columnSizes} rowWidth={rowContainerWidth} isPlaceholderRow={isPlaceholderRow}/>)
                    })}
                  </>}
                </div>
              }
              {hasAdditionalFunctions &&
                <div className="RowGroupRow-Header-AdditionalFunctions">
                  <div className="RowGroupRow-Header-CellWrapper" style={additionalFunctionsCellStyleProps} ref={additionalFunctionsCellHandleRef}>
                    <div className="RowGroupRow-Header-AdditionalFunctions-Button">
                      <DropDownButton content={<div style={{display:"flex", padding:"7px 0px"}}><AdditionalOptions/></div>} options={additionalFunctions} 
                        onOptionSelected={((option) => {
                          if (option.callback !== undefined) {
                            option.callback()
                          }
                        })}/>
                    </div>
                  </div>
                </div>
              }
            </div>

            {extendedHeaderContent !== undefined && extendedHeaderContent !== null && <>
              {(() => {
                const contentProps = {}
                if (extendedHeaderContent.triggersExpandToggle === true) {
                  contentProps.onClick = () => toggleExpandedState(!isExpanded)
                }
                return (
                  <div className="RowGroupRow-ExtendedHeader" {...contentProps}>
                    <div className="RowGroupRow-ExtendedHeader-Content">
                      {extendedHeaderContent.content}
                    </div>
                  </div>  
                )
              })()}
            </>}

          </div>

          {isExpandable && 
            <div className="RowGroupRow-Content-Container RowGroupRow-Content-Container-Expandable" style={{height:isExpanded ? rowContentHeight : 0}}>
              <div className="RowGroupRow-Content" ref={rowContentRefHandler}>
                {(!renderContentOnlyWhenOpen || isExpanded) && content}
              </div>
            </div>
          }
          {!isExpandable &&
            <div className="RowGroupRow-Content-Container">
              <div className="RowGroupRow-Content">
                {content}
              </div>
            </div>
          }
        
          {isMobile && <>
            {(hasIssues && numberOfIssues > 0) && 
              <div className="RowGroupRow-Issues-Container" onClick={issuesCellClicked}>
                <div className="RowGroupRow-Issues">
                  {issuesContent}  
                </div>
              </div>
            }
          </>}
        </div>
      </div>
    </div>
  </>)
} 

RowGroupRow.defaultProps = {
  title: "",
  titleAuxContent: false,
  titleFontSize: 16,
  subtitle: false,
  status: "Active",
  statusColor: "#4DBE3B",
  statusColumnWidth: null,
  statusContainerPadding: 6,
  customRowPadding: false,
  showExpandedStatusContainerStyles: true,
  cardBackgroundColor: "#FFF",
  cardBorderColor: "#DDE3EB",
  minimumTitleWidth: false,
  secondaryContent: [],
  secondaryContentTriggersExpandToggle: false,
  hasFlexCell: false,
  extendedHeaderContent: null,
  isExpandable: false,
  showNeutralSelectionColor: false,
  expandedState: false,
  hasAdditionalFunctions: false,
  additionalFunctions: [],
  hasIssues: false,
  numberOfIssues: 0,
  customIssuesContent: false,
  isPlaceholderRow: false,
  columnSizes: {},
  renderContentOnlyWhenOpen: false
}

export default RowGroupRow