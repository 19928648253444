
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRaftTypes, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import DropDownInput from '../../../../components/input/DropDownInput';
import NumberInput from '../../../../components/input/NumberInput';
  

const CreateGrowPlan_SelectSeedingMethodWalkthroughStep = ({
        selectedSeed,
        initialSelectedSeedingMethod,
        initialSelectedSeedSelector,
        initialSelectedSeedsPerPlug,
        seedlingMethodConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const dispatch = useDispatch()

    const allSeedSelectors = [
        {
            id: 1,
            name: "8mm Seeder",
            number_of_plant_sites: 8,
            seed_manifold_type_id: 2
        }
    ]

    const [selectedSeedingMethod, SetSelectedSeedingMethod] = React.useState("hand")
    React.useLayoutEffect(() => {
        if (initialSelectedSeedingMethod !== undefined && initialSelectedSeedingMethod !== null)    {
            SetSelectedSeedingMethod(initialSelectedSeedingMethod)
        }
    }, [initialSelectedSeedingMethod])
    const seedingMethodSelected = (seedingMethod) => {
        SetSelectedSeedingMethod(seedingMethod)
    }


    const [selectedSeedSelector, SetSelectedSeedSelector] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedSeedSelector !== undefined)  {
            SetSelectedSeedSelector(initialSelectedSeedSelector)
        }
    }, [initialSelectedSeedSelector])
    const seedSelectorSelected = (seedSelector) => {
        SetSelectedSeedSelector(seedSelector)
    }


    const [selectedSeedsPerPlug, SetSelectedSeedsPerPlug] = React.useState(1)
    React.useLayoutEffect(() => {
        if (initialSelectedSeedsPerPlug !== undefined && initialSelectedSeedsPerPlug !== null)    {
            SetSelectedSeedsPerPlug(initialSelectedSeedsPerPlug)
        }
    }, [initialSelectedSeedsPerPlug])
    const seedsPerPlugSelected = (seedsPerPlug) => {
        SetSelectedSeedsPerPlug(seedsPerPlug)
    }



    const backClicked = () => {
        transitionToPreviousStep(["select_seedling_raft_type", null])
    }
    const nextClicked = () =>   {
        seedlingMethodConfirmedCallback({
            seedingMethod: selectedSeedingMethod,
            seedSelector: selectedSeedSelector,
            seedsPerPlug: selectedSeedsPerPlug
        })
        transitionToNextStep(["select_seedling_success_rate", null])
    }
 

    let seedSelectorOptions = []
    for (let seedSelector of allSeedSelectors)  { //filter by something
        seedSelectorOptions.push({
            value: seedSelector.id,
            label: seedSelector.name
        })
    }
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"} style={{minWidth: isMobile ? 100 : isTablet ? 300 : 500}}>
                    <div className={isMobile ? "FlexContent-15" : "FlexContent-30"} style={{padding: isMobile ? "0" : "20px 40px"}}>
                        <div className="FlexContent-10">
                            <div className="Text-S14">Seeding Method</div>
                            <div className={isMobile ? "FlexContent-5" : "FlexContent-H-5"}>
                                <Button status={"Primary-Toggle"} state={selectedSeedingMethod === "hand"} content={"Hand"} onClick={() => {seedingMethodSelected("hand")}} width={"Flex100"}/>
                                <Button status={"Primary-Toggle"} state={selectedSeedingMethod === "drop"} content={"Drop"} onClick={() => {seedingMethodSelected("drop")}} width={"Flex100"}/>
                                <Button status={"Primary-Toggle"} state={selectedSeedingMethod === "needle"} content={"Needle"} onClick={() => {seedingMethodSelected("needle")}} width={"Flex100"}/>
                            </div>
                        </div>
                        {(selectedSeedingMethod === "hand" || selectedSeedingMethod === "needle") && <>
                            <div className="FlexContent-10">
                                <div className="Text-S14">Seeds Per Plug</div>
                                <NumberInput value={selectedSeedsPerPlug} onBlur={seedsPerPlugSelected}/>
                            </div>
                        </>}
                        {(selectedSeedingMethod === "drop") && <>
                            <div className="FlexContent-10">
                                <div className="Text-S14">Seed Selector</div>
                                <DropDownInput placeholder={"Pick a seed selector"} options={seedSelectorOptions} value={selectedSeedSelector} onSelectionChange={seedSelectorSelected}/>
                            </div>
                        </>}
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedSeedingMethod === null}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectSeedingMethodWalkthroughStep
