import './TransplantToNurseryFromGerminationTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../../../components/input/DropDownInput';
import TextInput from '../../../../components/input/TextInput';
import Tag from '../../../../components/Tag';
import { update } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks';
import { selectAllRecipes } from '../../../../redux/entities/Recipes';
import { SoftWarning } from '../../../../assets/icons/Icons';
import { addZoneInfoToGrow } from '../../../../redux/entities/Grow';

const TransplantToNurseryFromGernimationTask_ReviewStep = ({
    task,
    allBladeZones,
    allRecipeSetpointTypes,
    allRecipes,
    activeGrow,
    activeRecipe,
    allGerminationChambers,
    nurseryLocations,
    taskCompletedCallback,
    transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    
    const dispatch = useDispatch()
    

    

    const calculateNurseryUpdates = () => {
        //Based on the nursery locations selected, we need to modify the recipe if there is one running on the nursery
        let nurseryUpdates = {}


        let foundNurseryTimelineItem = undefined
        if (activeRecipe.timeline_items !== undefined)  {
            foundNurseryTimelineItem = activeRecipe.timeline_items.find((tI) => tI.type === "nursery_cycle")
            //console.log(foundNurseryTimelineItem)
        }

        for (let [nurseryUID, nurseryRowLocations] of Object.entries(nurseryLocations))   {
            nurseryUpdates[nurseryUID] = {locations: nurseryRowLocations, recipe: undefined, is_recipe_new: true}


            //Determine which water zones are active
            let activeWaterZonesList = []
            for (let nurseryRowString of Object.keys(nurseryRowLocations))    {
                let nurseryRow = parseInt(nurseryRowString)
                let rootZoneIndex = 0
                if (nurseryRow >= 1 && nurseryRow <= 4) {
                    rootZoneIndex = 1
                }else if (nurseryRow >= 5 && nurseryRow <= 7)   {
                    rootZoneIndex = 2
                }
                if (activeWaterZonesList.indexOf(rootZoneIndex) === -1) {
                    activeWaterZonesList.push(rootZoneIndex)
                }
            }
            
            //Find existing recipe
            let foundNursery = allBladeZones.find((bZ) => bZ.uid === nurseryUID)
            if (foundNursery.active_zone_recipe !== null)   {
                let foundRecipe = allRecipes.find((r) => r.id === foundNursery.active_zone_recipe.recipe_id && r.version === foundNursery.active_zone_recipe.recipe_version)
                if (foundRecipe !== undefined)  {
                    nurseryUpdates[nurseryUID].recipe =  JSON.parse(JSON.stringify(foundRecipe))
                }
            }

            //Check if the recipe already exists and if so, manipulate the water zones to include the new list
            if (nurseryUpdates[nurseryUID].recipe !== undefined && nurseryUpdates[nurseryUID].recipe !== null)    {
                nurseryUpdates[nurseryUID].is_recipe_new = false

                console.log(nurseryUpdates[nurseryUID].recipe)

                let foundExistingNurseryTimelineItem = nurseryUpdates[nurseryUID].recipe.timeline_items.find((tI) => tI.type === "nursery_cycle")
                if (foundExistingNurseryTimelineItem !== undefined) {
                    let existingWaterZones = JSON.parse(foundExistingNurseryTimelineItem.item.active_water_zones)
                    for (let existingWaterZone of existingWaterZones)   {
                        if (activeWaterZonesList.indexOf(existingWaterZone) === -1) {
                            activeWaterZonesList.push(existingWaterZone)
                        }
                    }
                }

                foundExistingNurseryTimelineItem.item.active_water_zones = JSON.stringify(activeWaterZonesList)      
                
                //Then do some magic to join the active recipes

            }else {

                //Generate a new recipe (copy)

                nurseryUpdates[nurseryUID].is_recipe_new = true
                nurseryUpdates[nurseryUID].recipe = {
                    name: "Manual Recipe",
                    type: foundNursery.zone_type,
                    type_reference_id: foundNursery.uid,
                    starter_product_id: "",
                    currentTimelineItemTempId: 2,
                    temp_id: 1,
                    recipe_type: "nursery",
                    grow_out_type: foundNursery.zone_type,
                    version: 1,
                    created_on: new Date().toISOString(),
                    modified_on: new Date().toISOString(),
                    timeline_items: [{
                        id: 1,
                        index: 1,
                        type: "nursery_cycle",
                        item: {
                            created_on: new Date().toISOString(),
                            /*currentSetpointTempId: 2,*/
                            duration: 60 * 60 * 24,
                            index: 1,
                            iterations: 1,
                            lighting_intensity_setpoint_zones: [],
                            active_water_zones: JSON.stringify(activeWaterZonesList),
                            name: "Cycle 1",
                            nutrient_recipe_zones: [],
                            relationships: [],
                            setpoint_zones: [],
                        }
                    }],
                    created_by_account_id: 29,
                    public: false,
                    enabled: true,
                    is_active_version: true,
                    version_name: "Auto Recipe-Created",
                    stack_index: 1,
                    revision_stack_size: 1,
                    initialSave: false
                }
            }
            if (foundNurseryTimelineItem !== undefined) {


                let airZoneSetpointTypeIdentifiers = ["air_temp", "air_rh", "air_vpd", "air_flow", "air_co2"]
                let rootZoneSetpointTypeIdentifiers = ["water_temp", "water_ph", "water_ec","water_orp", "ebbflow_frequency"]

                nurseryUpdates[nurseryUID].recipe.timeline_items = nurseryUpdates[nurseryUID].recipe.timeline_items.map((timelineItem) => {
                    if (timelineItem.type !== "nursery_cycle")    {
                        return timelineItem
                    }

                    //DO SOME CALCULATIONS HERE!!!!
                    let updatedTimelineItem = {...timelineItem}


                    for (let setpointZone of foundNurseryTimelineItem.item.setpoint_zones)  {
                        let foundRecipeSetpointType = allRecipeSetpointTypes.find((rST) => rST.id === setpointZone.type_id)
                        if (foundRecipeSetpointType !== undefined)  {

                            if (airZoneSetpointTypeIdentifiers.indexOf(foundRecipeSetpointType.name) !== -1)    {
                                //Its air, only zone index is 0!
                                let foundExistingAirZone = timelineItem.item.setpoint_zones.find((sZ) => sZ.type_id === setpointZone.type_id && sZ.index === 0)
                                if (foundExistingAirZone !== undefined) {

                                    //CALCULATE!!!

                                }else {
                                    timelineItem.item.setpoint_zones.push(setpointZone)
                                }
                            }


                            if (rootZoneSetpointTypeIdentifiers.indexOf(foundRecipeSetpointType.name) !== -1)    {
                                //Its water, check for matching water zones
                                for (let nurseryRowString of Object.keys(nurseryRowLocations))    {
                                    let nurseryRow = parseInt(nurseryRowString)
                                    let rootZoneIndex = 0
                                    if (nurseryRow >= 1 && nurseryRow <= 4) {
                                        rootZoneIndex = 1
                                    }else if (nurseryRow >= 5 && nurseryRow <= 7)   {
                                        rootZoneIndex = 2
                                    }
                                    let foundExistingWaterZone = timelineItem.item.setpoint_zones.find((sZ) => sZ.type_id === setpointZone.type_id && sZ.zone_index === rootZoneIndex)
                                    if (foundExistingWaterZone !== undefined) {

                                        //CALCULATE!!!

                                    }else {
                                        timelineItem.item.setpoint_zones.push({...setpointZone, zone_index: rootZoneIndex})
                                    }
                                }
                            }
                        }
                    }


                    for (let lightingIntensitySetpointZone of foundNurseryTimelineItem.item.lighting_intensity_setpoint_zones)  {
                        for (let nurseryRowString of Object.keys(nurseryRowLocations))    {
                            let nurseryRow = parseInt(nurseryRowString)
                            let foundExistingLightingZone = timelineItem.item.lighting_intensity_setpoint_zones.find((sZ) => sZ.zone_index === nurseryRow)
                            if (foundExistingLightingZone !== undefined) {

                                //CALCULATE!!!

                            }else {
                                timelineItem.item.lighting_intensity_setpoint_zones.push({...lightingIntensitySetpointZone, zone_index: nurseryRow})
                            }
                        }
                    }

                    for (let nutrientRecipeZone of foundNurseryTimelineItem.item.nutrient_recipe_zones) {
                        for (let nurseryRowString of Object.keys(nurseryRowLocations))    {
                            let nurseryRow = parseInt(nurseryRowString)
                            let rootZoneIndex = 0
                            if (nurseryRow >= 1 && nurseryRow <= 4) {
                                rootZoneIndex = 1
                            }else if (nurseryRow >= 5 && nurseryRow <= 7)   {
                                rootZoneIndex = 2
                            }
                            let foundExistingNutrientZone = timelineItem.item.nutrient_recipe_zones.find((nRZ) => nRZ.zone_index === rootZoneIndex)
                            if (foundExistingNutrientZone !== undefined) {

                                //CALCULATE!!!

                            }else {
                                timelineItem.item.nutrient_recipe_zones.push({...nutrientRecipeZone, zone_index: rootZoneIndex})
                            }
                        }
                    }

                    return updatedTimelineItem
                    
                })

            }
        }


        return nurseryUpdates
    }





    let nurseryUpdates = calculateNurseryUpdates()

    
    const backClicked = () =>   {
        transitionToPreviousStep(["move_crops", null])
    }
    


    const [performingTransplantToNurseryCompleted, SetPerformingTransplantToNurseryCompleted] = React.useState(false)
    const [pendingUpdateGrowWithZoneInfo, SetPendingUpdateGrowWithZoneInfo] = React.useState(null)
    const finalizeTaskClicked = () => {
        SetPerformingTransplantToNurseryCompleted(true)
        dispatch(markTaskAsCompleted({taskId: task.id, params: {
            nursery_updates: nurseryUpdates,
        }, callback: (success) => {
            if (success)    {
                let newGrowOutZoneInfo = []
                for (let nurseryUID of Object.keys(nurseryLocations))   {
                    newGrowOutZoneInfo.push({
                        id: null,
                        started_on: new Date().getTime(),
                        zone_uid:nurseryUID,
                        active: true                        
                    })
                }
                SetPendingUpdateGrowWithZoneInfo(newGrowOutZoneInfo)
            }
            SetPerformingTransplantToNurseryCompleted(false)
        }}))
    }

    React.useLayoutEffect(() => {
        if (pendingUpdateGrowWithZoneInfo)  {
            dispatch(addZoneInfoToGrow({growId: activeGrow.id, zone_info: pendingUpdateGrowWithZoneInfo}))
            SetPendingUpdateGrowWithZoneInfo(null)
            taskCompletedCallback()
        }
    }, [pendingUpdateGrowWithZoneInfo])
    
    


    


    


    let haveGerminationLocations = activeGrow !== undefined && activeGrow.loaded_germination_locations
    let nurseryRowOptions = [
        {value: 1, label: "Row A", tagLabel: "A"},
        {value: 2, label: "Row B", tagLabel: "B"},
        {value: 3, label: "Row C", tagLabel: "C"},
        {value: 4, label: "Row D", tagLabel: "D"},
        {value: 5, label: "Row E", tagLabel: "E"},
        {value: 6, label: "Row F", tagLabel: "F"},
        {value: 7, label: "Row G", tagLabel: "G"},
    ]


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-30" style={{padding: isMobile ? "0 10px" : "0 100px"}}>
                        <div className="FlexContent-20">
                            <div className="Text-SubHeader">Confirm that these new locations are accurate</div>
                            <div className="FlexContent-H-10 FlexContent-Center">
                                <SoftWarning fill={"#EC3C3C"}/>
                                <div className="Text-Subtext">
                                    Confirming will activate this grow in the following nursery(s) immediately.
                                </div>
                            </div>
                        </div> 
                        <div className="FlexContent-20">
                            <div className="FlexContent-10 Linebreak-20">
                                <div className="FlexContent-H-10" style={{alignSelf:"flex-start"}}>
                                    <div className="Text-SubHeader">Germination Locations</div>
                                </div>
                                {haveGerminationLocations && <>
                                    {Object.entries(activeGrow.germination_locations).length === 0 && <>
                                        <div className="Text-AuxSubtext">Germination locations were not tracked</div>
                                    </>}
                                    {Object.entries(activeGrow.germination_locations).map(([germinationUID, locations]) => {
                                        let germinationChamber = allGerminationChambers.find((gC) => gC.uid === germinationUID)
                                        return (
                                            <div className="FlexContent-10">
                                                <div className="Text-Subtext">{germinationChamber !== undefined ? germinationChamber.display_name : ""}</div>
                                                <div className="FlexWrap FlexWrap-Size2" style={{flexGrow: 0}}>
                                                    {locations.map((location) => {
                                                        return (
                                                            <Tag content={location} status={"Neutral"}/>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>}
                            </div>
                            <div className="FlexContent-10">
                                <div className="FlexContent-H-10" style={{alignSelf:"flex-start"}}>
                                    <div className="Text-SubHeader">Nursery Locations</div>
                                </div>
                                {Object.entries(nurseryLocations).map(([nurseryUID, nurseryRowLocations]) => {
                                    let nurseryZone = allBladeZones.find((bZ) => bZ.uid === nurseryUID)
                                    return (
                                        <div className="FlexContent-10">
                                            <div className="Text-Subtext">{nurseryZone !== undefined ? nurseryZone.display_name : ""}</div>
                                            <div className="FlexWrap FlexWrap-Size2" style={{flexGrow: 0}}>
                                                {Object.entries(nurseryRowLocations).map(([rowIndex, locations]) => {
                                                    let foundRowOption = nurseryRowOptions.find((nRO) => nRO.value === parseInt(rowIndex))
                                                    return <>
                                                        {locations.map((location) => {
                                                            return (
                                                                <Tag content={foundRowOption.tagLabel + "-" + location} status={"Neutral"}/>
                                                            )
                                                        })}
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Complete Task"} onClick={finalizeTaskClicked} size={"Flex50"} disabled={performingTransplantToNurseryCompleted}/>
            </div>
        </div>
    )
    
}


export default TransplantToNurseryFromGernimationTask_ReviewStep