import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import NumberInput from '../../../../components/input/NumberInput';
import DropDownInput from '../../../../components/input/DropDownInput';
import { useSelector, useDispatch} from 'react-redux'
import Walkthrough from '../../../../components/Walkthrough';
import CreateGrowPlan_SelectPlantTypeWalkthroughStep from './CreateGrowPlan_SelectPlantTypeWalkthroughStep';
import CreateGrowPlan_SelectPlantVarietyWalkthroughStep from './CreateGrowPlan_SelectPlantVarietyWalkthroughStep';
import CreateGrowPlan_SelectSeedWalkthroughStep from './CreateGrowPlan_SelectSeedWalkthroughStep';
import CreateGrowPlan_SelectSeedlingRaftTypeWalkthroughStep from './CreateGrowPlan_SelectSeedlingRaftTypeWalkthroughStep';
import CreateGrowPlan_SelectSeedingMethodWalkthroughStep from './CreateGrowPlan_SelectSeedingMethodWalkthroughStep';
import CreateGrowPlan_SelectSeedlingSuccessRateWalkthroughStep from './CreateGrowPlan_SelectSeedlingSuccessRateWalkthroughStep';
import CreateGrowPlan_PlantVariantListWalkthroughStep from './CreateGrowPlan_PlantVariantListWalkthroughStep';
import CreateGrowPlan_SelectRecipeWalkthroughStep from './CreateGrowPlan_SelectRecipeWalkthroughStep';
import CreateGrowPlan_SelectGrowOutRaftTypeWalkthroughStep from './CreateGrowPlan_SelectGrowOutRaftTypeWalkthroughStep';
import CreateGrowPlan_SelectGrowPlanNameWalkthroughStep from './CreateGrowPlan_SelectGrowPlanNameWalkthroughStep';
import CreateGrowPlan_FinalReviewWalkthroughStep from './CreateGrowPlan_FinalReviewWalkthroughStep';
import CreateGrowPlan_SelectFinalProductWalkthroughStep from './CreateGrowPlan_SelectFinalProductWalkthroughStep';
import { getAllPlantTypes } from '../../../../redux/entities/PlantTypes';

  

const CreateGrowPlanWalkthrough = ({completeCallback, closeCallback}) => {
    const closePopup = () =>  {
        if (closeCallback !== undefined)  {
            closeCallback()
        }
    }

    const [currentTimelineStep, SetCurrentTimelineStep] = React.useState("type")
    const timelineSteps = {
        type: { render: (<span>Type</span>) },
        variety: { render: (<span>Variety</span>) },
        recipe: { render: (<span>Recipe</span>) },
        details: { render: (<span>Details</span>) },
    }
    const timelineStepChanged = (key) =>    {
        if (currentTimelineStep !== key)    {
            SetCurrentTimelineStep(key)
        }
    }
    const previousStepClicked = () =>   {
        switch (currentTimelineStep)    {
            case "variety":
                SetCurrentTimelineStep("type")
                break
            case "recipe":
                SetCurrentTimelineStep("variety")
                break
            case "details":
                SetCurrentTimelineStep("recipe")
                break
            default:
                break
        }
    }


    const [currentPlantVarientIndex, SetCurrentPlantVarientIndex] = React.useState(0)
    const changePlantVariantIndex = (index) => {
        SetCurrentPlantVarientIndex(index)
    }

    const [selectedPlantType, SetSelectedPlantType] = React.useState({})
    const plantTypeConfirmed = ({plantType}) =>    {
        SetSelectedPlantType({...selectedPlantType, [currentPlantVarientIndex]: plantType})
    }

    const [selectedPlantVariety, SetSelectedPlantVariety] = React.useState({})
    const plantVarietyConfirmed = ({plantVariety}) =>    {
        SetSelectedPlantVariety({...selectedPlantVariety, [currentPlantVarientIndex]: plantVariety})
    }

    const [selectedSeed, SetSelectedSeed] = React.useState({})
    const seedConfirmed = ({seed}) =>    {
        SetSelectedSeed({...selectedSeed, [currentPlantVarientIndex]: seed})
    }

    const [selectedSeedlingRaftType, SetSelectedSeedlingRaftType] = React.useState({})
    const seedlingRaftTypeConfirmed = ({seedlingRaftType}) =>    {
        SetSelectedSeedlingRaftType({...selectedSeedlingRaftType, [currentPlantVarientIndex]: seedlingRaftType})
    }


    const [selectedSeedingMethod, SetSelectedSeedingMethod] = React.useState({})
    const [selectedSeedSelector, SetSelectedSeedSelector] = React.useState({})
    const [selectedSeedsPerPlug, SetSelectedSeedsPerPlug] = React.useState({})
    const seedingMethodConfirmed = ({seedingMethod, seedSelector, seedsPerPlug}) =>    {
        SetSelectedSeedingMethod({...selectedSeedingMethod, [currentPlantVarientIndex]: seedingMethod})
        SetSelectedSeedSelector({...selectedSeedSelector, [currentPlantVarientIndex]: seedSelector})
        SetSelectedSeedsPerPlug({...selectedSeedsPerPlug, [currentPlantVarientIndex]: seedsPerPlug})
    }
    

    
    const [selectedSeedlingSuccessRate, SetSelectedSeedlingSuccessRate] = React.useState({})
    const seedlingSuccessRateConfirmed = ({seedlingSuccessRate}) =>    {
        SetSelectedSeedlingSuccessRate({...selectedSeedlingSuccessRate, [currentPlantVarientIndex]: seedlingSuccessRate})
    }

    
    const [selectedSeedRatios, SetSelectedSeedRatios] = React.useState({0: 100})
    const seedRatiosChanged = (seedlingRatios) =>    {
        SetSelectedSeedRatios(seedlingRatios)
    }
    

    const [selectedRecipeId, SetSelectedRecipeId] = React.useState(null)
    const [selectedRecipeVersion, SetSelectedRecipeVersion] = React.useState(null)
    const recipeConfirmed = ({recipeId, recipeVersion}) =>    {
        SetSelectedRecipeId(recipeId)
        SetSelectedRecipeVersion(recipeVersion)
    }

    const [selectedGrowOutRaftType, SetSelectedGrowOutRaftType] = React.useState(null)
    const [selectedGrowOutRaftUsage, SetSelectedGrowOutRaftUsage] = React.useState(null)
    const growOutRaftTypeConfirmed = ({growOutRaftType, growOutRaftUsage}) =>    {
        SetSelectedGrowOutRaftType(growOutRaftType)
        SetSelectedGrowOutRaftUsage(growOutRaftUsage)
    }
    

    const [selectedGrowPlanName, SetSelectedGrowPlanName] = React.useState(null)
    const growPlanNameConfirmed = ({growPlanName}) =>    {
        SetSelectedGrowPlanName(growPlanName)
    }

    
    const [selectedProduct, SetSelectedProduct] = React.useState(null)
    const finalProductConfirmed = ({product}) => {
        SetSelectedProduct(product)
    }

    /*const [estimatedYield, SetEstimatedYield] = React.useState(0)
    const onEstimatedYieldChanged = (value) =>    {
        SetEstimatedYield(value)
    }
    const [selectedUnit, SetSelectedUnit] = React.useState("weight")
    const onUnitSelectionChanged = (value) =>    {
        SetSelectedUnit(value)
    }
    const [planNotes, SetPlanNotes] = React.useState("")
    const onPlanNotesChanged = (value) =>    {
        SetPlanNotes(value)
    }*/

    const completedGrowPlan = () => {
        if (completeCallback !== undefined) {
            completeCallback()
        }
    }

    

    const loadWalkthroughStep = (stepKeys) =>    {
        const stepKey = stepKeys[0]
        const subStepKey = stepKeys[1]
        switch (stepKey)    {
            case "select_plant_type":
                return (<CreateGrowPlan_SelectPlantTypeWalkthroughStep
                    initialSelectedPlantType={selectedPlantType[currentPlantVarientIndex]}
                    plantTypeConfirmedCallback={plantTypeConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
            
            case "select_plant_variety":
                return (<CreateGrowPlan_SelectPlantVarietyWalkthroughStep
                    selectedPlantType={selectedPlantType[currentPlantVarientIndex]}
                    initialSelectedPlantVariety={selectedPlantVariety[currentPlantVarientIndex]}
                    plantVarietyConfirmedCallback={plantVarietyConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)

            case "select_seed":
                return (<CreateGrowPlan_SelectSeedWalkthroughStep
                    selectedPlantVariety={selectedPlantVariety[currentPlantVarientIndex]}
                    initialSelectedSeed={selectedSeed[currentPlantVarientIndex]}
                    seedConfirmedCallback={seedConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
            
            case "select_seedling_raft_type":
                return (<CreateGrowPlan_SelectSeedlingRaftTypeWalkthroughStep
                    initialSelectedSeedlingRaftType={selectedSeedlingRaftType[currentPlantVarientIndex]}
                    seedlingRaftTypeConfirmedCallback={seedlingRaftTypeConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
            
            case "select_seeding_method":
                return (<CreateGrowPlan_SelectSeedingMethodWalkthroughStep
                    initialSelectedSeedingMethod={selectedSeedingMethod[currentPlantVarientIndex]}
                    initialSelectedSeedSelector={selectedSeedSelector[currentPlantVarientIndex]}
                    initialSelectedSeedsPerPlug={selectedSeedsPerPlug[currentPlantVarientIndex]}
                    seedlingMethodConfirmedCallback={seedingMethodConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)

            case "select_seedling_success_rate":
                return (<CreateGrowPlan_SelectSeedlingSuccessRateWalkthroughStep
                    initialSelectedSeedlingSuccessRate={selectedSeedlingSuccessRate[currentPlantVarientIndex]}
                    seedlingSuccessRateConfirmedCallback={seedlingSuccessRateConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
            
            case "plant_variant_list":
                return (<CreateGrowPlan_PlantVariantListWalkthroughStep
                    selectedPlantType={selectedPlantType}
                    selectedPlantVariety={selectedPlantVariety}
                    selectedSeed={selectedSeed}
                    selectedSeedlingRaftType={selectedSeedlingRaftType}
                    selectedSeedingMethod={selectedSeedingMethod}
                    selectedSeedSelector={selectedSeedSelector}
                    selectedSeedsPerPlug={selectedSeedsPerPlug}
                    selectedSeedlingSuccessRate={selectedSeedlingSuccessRate}
                    selectedSeedRatios={selectedSeedRatios}
                    changeSeedRatios={seedRatiosChanged}
                    changePlantVariantIndex={changePlantVariantIndex}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
            
            case "select_recipe":
                return (<CreateGrowPlan_SelectRecipeWalkthroughStep
                    initialSelectedRecipeId={selectedRecipeId}
                    initialSelectedRecipeVersion={selectedRecipeVersion}
                    recipeConfirmedCallback={recipeConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
            
            case "select_grow_out_raft_type":
                return (<CreateGrowPlan_SelectGrowOutRaftTypeWalkthroughStep
                    selectedRecipeId={selectedRecipeId}
                    selectedRecipeVersion={selectedRecipeVersion}
                    initialSelectedGrowOutRaftType={selectedGrowOutRaftType}
                    initialSelectedGrowOutRaftUsage={selectedGrowOutRaftUsage}
                    growOutRaftTypeConfirmedCallback={growOutRaftTypeConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
            

            case "select_grow_plan_name":
                return (<CreateGrowPlan_SelectGrowPlanNameWalkthroughStep
                    selectedRecipeId={selectedRecipeId}
                    selectedRecipeVersion={selectedRecipeVersion}
                    initialSelectedGrowPlanName={selectedGrowPlanName}
                    growPlanNameConfirmedCallback={growPlanNameConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)

            case "select_product":
                return (<CreateGrowPlan_SelectFinalProductWalkthroughStep
                    initialSelectedProduct={selectedProduct}
                    finalProductConfirmedCallback={finalProductConfirmed}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)
        
            case "final_review":
                return (<CreateGrowPlan_FinalReviewWalkthroughStep
                    selectedPlantType={selectedPlantType}
                    selectedPlantVariety={selectedPlantVariety}
                    selectedSeed={selectedSeed}
                    selectedSeedlingRaftType={selectedSeedlingRaftType}
                    selectedSeedingMethod={selectedSeedingMethod}
                    selectedSeedSelector={selectedSeedSelector}
                    selectedSeedsPerPlug={selectedSeedsPerPlug}
                    selectedSeedlingSuccessRate={selectedSeedlingSuccessRate}
                    selectedSeedRatios={selectedSeedRatios}
                    selectedRecipeId={selectedRecipeId}
                    selectedRecipeVersion={selectedRecipeVersion}
                    selectedGrowOutRaftType={selectedGrowOutRaftType}
                    selectedGrowOutRaftUsage={selectedGrowOutRaftUsage}
                    selectedGrowPlanName={selectedGrowPlanName}
                    selectedFinalProduct={selectedProduct}
                    completedGrowPlanCallback={completedGrowPlan}
                    transitionToPreviousStep={transitionToPreviousStep}
                    transitionToNextStep={transitionToNextStep}/>)

                default:
            return <></>
        }
    }


        
    /* Walkthrough requirements */
    let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["select_plant_type", null])
    let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
    let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
    const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })
    const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
        SetPreviousWalkthroughStep(null)
        SetNextWalkthroughStep(null)
    })

    const transitionToPreviousStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetPreviousWalkthroughStep(stepKeys)
    }
    const transitionToNextStep = (stepKeys) =>  {
        SetCurrentWalkthroughStep(stepKeys)
        SetNextWalkthroughStep(stepKeys)
    }


    return (<>
        <Walkthrough
            title="Create Grow Plan"
            size="full"
            closeCallback={closePopup}
            hideMainSeparators={false}
            initialWalkthroughStep={["select_plant_type", null]}
            transitionToWalkthroughStep={nextWalkthroughStep}
            transitionBackToWalkthroughStep={previousWalkthroughStep}
            buildWalkthroughStepCallback={loadWalkthroughStep}
            allowOuterClickToClosePopup={false}
            transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
            transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}/>

            
        {/*

            {currentTimelineStep === "recipe" && 
                <SelectRecipes selectedRecipe={selectedRecipe} onRecipeSelection={recipeSelected}/>
            }
            {currentTimelineStep === "details" &&  
                <div className="CreateGrowPlanWalkthrough-DetailsForm">
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            What do you want to name your grow plan?
                        </div>
                        <div className="PopupModal-FormItem-Answer">
                            <TextInput value={planName} placeHolder="" onChange={onPlanNameChanged}/>
                        </div>
                    </div>
                    
                    <div className="CreateGrowPlanWalkthrough-RaftSelection-Container">
                        <div className="CreateGrowPlanWalkthrough-RaftSelection-Label">Raft Type</div>
                        <SelectRaftType selectedRaftType={raftType} onRaftTypeSelect={onRaftTypeSelected}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", gap: 8}}>
                        <NumberInput label={"Estimated Yield"} value={estimatedYield} suffix={(selectedUnit === "weight" ? "kg" : "units")} onChange={onEstimatedYieldChanged}/>
                        <DropDownInput label={"Unit"} options={[{ value: 'weight', label: 'Weight' }, { value: 'plant', label: 'Plant' }]} value={selectedUnit} onSelectionChange={onUnitSelectionChanged}/>
                    </div>
                    <div className="PopupModal-FormItem">
                        <div className="PopupModal-FormItem-Question">
                            Notes
                        </div>
                        <div className="PopupModal-FormItem-Answer">
                            <TextInput multiline={true} label={"Notes"} value={planNotes} placeHolder="" onChange={onPlanNotesChanged}/>
                        </div>
                    </div>

                    
                </div>
            }
        </PopupModal>*/}
    </>)
} 

export default CreateGrowPlanWalkthrough