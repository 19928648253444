import './PlantSeedsForGerminationTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllGrowSeeds, selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';

const PlantSeedsForGermination_SeedingWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        activeRecipe,
        seederChoice,
        seedingPlantVariantId,
        seedingPlantVariantIndex,
        setVariantIndexCallback,
        plantVariant,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["seeding_list", null])
    }
    const seedingCompleted = () =>  {
        transitionToNextStep(["location_tracking_options", null])
    }
    
    
    const allRaftTypes = useSelector(state => selectAllRaftTypes(state))
    const allPlantTypes = useSelector(state => selectAllPlantTypes(state))
    const allPlantVarities = useSelector(state => selectAllPlantVarieties(state))
    const allSeeds = useSelector((state) => selectAllGrowSeeds(state))

          
    const plantType = allPlantTypes.find((pT) => pT.id === plantVariant.plant_type_id)
    const plantVariety = allPlantVarities.find((pV) => pV.id === plantVariant.plant_variety_id)
    const growSeed = allSeeds.find((s) => s.id === plantVariant.seed_id)
    const seedlingRaftType = allRaftTypes.find((rT) => rT.id === plantVariant.seedling_raft_type_id)


    
    let numberOfSeedsPerPlug = plantVariant.seeds_per_plug
    let seedSelector = null
    let numberOfSeedlingBoards = 0

    if (seedlingRaftType !== undefined) {
        numberOfSeedlingBoards = Math.ceil((activeGrow.number_of_units * (plantVariant.ratio / 100)) / seedlingRaftType.info["number_of_plant_sites"])
    }
    
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    {(plantVariant !== undefined && activeGrowPlan !== undefined && growSeed !== undefined) && <>
                        <div className="FlexContent-H-10" style={{justifyContent:"center"}}>
                            <div>
                                {activeGrowPlan.plant_variants.length > 1 && <>
                                    {seedingPlantVariantIndex + 1}/{activeGrowPlan.plant_variants.length}
                                </>}
                            </div>
                            <div>{growSeed.name} - Seed Info</div>
                        </div>
                    </>}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className="FlexContent-20 FlexContent-HFill" style={{padding: isMobile ? "0 10px" : "0 50px"}}>
                        <div>
                            <div className={isMobile ? "FlexContent-30" : "Grid2 Grid-Space50"}>
                                
                                <div className={"FlexContent-30" + (isMobile ? "" : " FlexContent-HFlex")}>
                                    <div className="FlexContent-5">
                                        <div className="Text-FormHeader-Medium">Plant the following</div>
                                        <div className="Text-FormValue">{plantType !== undefined ? plantType.name : ""}</div>
                                        <div className="Text-AuxSubtext">{plantVariety !== undefined ? plantVariety.name : ""}</div>
                                    </div>
                                    <div className="FlexContent-5">
                                        <div className="Text-FormHeader-Medium">Seedling Board Type</div>
                                        <div className="Text-FormValue">
                                        <div></div>
                                        <div>{seedlingRaftType !== null ? seedlingRaftType.name : ""}</div>
                                        </div>
                                    </div>
                                    {(plantVariant !== undefined && plantVariant.seeding_method === "drop" && seederChoice === "use_seeder") && 
                                        <div className="FlexContent-5">
                                            <div className="Text-FormHeader-Medium">Configure The Seeding Manifold</div>
                                            <div className="Text-FormValue">{seedSelector !== null ? seedSelector.name : ""}</div>
                                        </div> 
                                    }
                                </div>
                                
                                <div className={"FlexContent-30" + (isMobile ? "" : " FlexContent-HFlex")}>
                                    <div className="FlexContent-5">
                                        <div className="Text-FormHeader-Medium">Plant Sites</div>
                                        <div className="Text-FormValue">{numberOfSeedlingBoards} Seedling Boards</div>
                                        <div className="Text-AuxSubtext">{activeGrow !== undefined ? activeGrow.number_of_units : 0} Plant Sites</div>
                                    </div>
                                    <div className="FlexContent-5">
                                        <div className="Text-FormHeader-Medium">Number of seeds</div>
                                        <div className="Text-FormValue">{numberOfSeedsPerPlug} Seed{numberOfSeedsPerPlug > 1 && "s"}</div>
                                    </div> 
                                    {(plantVariant !== undefined && plantVariant.seeding_method === "drop" && seederChoice === "use_seeder") && 
                                        <div className="FlexContent-5">
                                            <div className="Text-FormHeader-Medium">Configure The Seeding Selector</div>
                                            <div className="Text-FormValue">{seedSelector !== null ? seedSelector.name : ""}</div>
                                        </div> 
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                {seedingPlantVariantIndex === 0 && 
                    <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex100"}/>
                }
                <Button status="Primary" content={"Next"} onClick={seedingCompleted} size={"Flex100"}/>
            </div>
        </div>
    )
    
}


export default PlantSeedsForGermination_SeedingWalkthroughStep