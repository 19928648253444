import './ScheduleGrowPopup.scss';

import _ from 'underscore';
import React from 'react';
import Button from '../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { FormatDate, FormatTime, RoundToNearest } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import RowGroup from '../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { getAllGrowPlans, selectAllGrowPlans, selectAllUsableGrowPlans } from '../../../redux/entities/GrowPlans';
import Badge from '../../../components/Badge';
import TextInput from '../../../components/input/TextInput';
import { SearchIcon } from '../../../assets/icons/Icons';
import { getRecipeById, selectAllRecipes } from '../../../redux/entities/Recipes';
import { getRaftTypeById, selectAllRaftTypes } from '../../../redux/entities/RaftTypes';
import { Raft } from '../../../model_components/RaftType';

  

const ScheduleGrow_SelectGrowPlanWalkthroughStep = ({
        initialSelectedGrowPlan,
        cancelScheduelGrowCallback, 
        growPlanConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


    const activeAccountId = useSelector((state) => state.account.id, _.isEqual)
    const allGrowPlans = useSelector((state) => selectAllUsableGrowPlans(state, activeAccountId), _.isEqual)
    const [growPlansList, SetGrowPlansList] = React.useState({})

    const [currentSearchInput, SetSearchInput] = React.useState("")
    const onSearchInput = (value) => {
        SetSearchInput(value)
    }

    React.useLayoutEffect(() => {
        let currentGrowPlansList = {}

        let currentGrowPlans = []
        

        if (currentSearchInput === "")  {
            currentGrowPlans = [...allGrowPlans]
        }else {
            currentGrowPlans.push(...allGrowPlans.filter((growPlan) => {
            
            if (growPlan.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1 && currentGrowPlans.indexOf(growPlan) === -1)  {
                return true
            }
            return false
        }))
        }
        
        
        for (let growPlan of currentGrowPlans) {
            //Check if the recipe is in working mode
            currentGrowPlansList[growPlan.id] = {
                data: growPlan,
            }
        }
        
        SetGrowPlansList(currentGrowPlansList)
    }, [allGrowPlans, currentSearchInput])


    const dispatch = useDispatch()
    const loadedAllGrowPlans = useSelector((state) => state.growPlans.loadedAllGrowPlans)
    const allGrowPlansLoadingStatus = useSelector((state) => state.growPlans.loadingAllGrowPlansStatus)
    const [validateLoadingAllGrowPlansCallback, SetValidateLoadingAllGrowPlansCallback] = React.useState(null)
    const performLoadAllGrowPlansRequest = () => {
        if (allGrowPlansLoadingStatus !== "pending") {
            SetValidateLoadingAllGrowPlansCallback(null)
            
            if (!loadedAllGrowPlans) {
                console.log("Loading all available grow plans")
                dispatch(getAllGrowPlans({}))
            }
        }else {
            if (validateLoadingAllGrowPlansCallback === null)    {
                SetValidateLoadingAllGrowPlansCallback(setTimeout(() => {performLoadAllGrowPlansRequest()}, 5000))
            }
        }
    }

    React.useEffect(() => {
        if (!loadedAllGrowPlans) {
            if (allGrowPlansLoadingStatus === "idle") {
                performLoadAllGrowPlansRequest()
            }else if (allGrowPlansLoadingStatus === "fulfilled" || allGrowPlansLoadingStatus === "rejected") {
                if (validateLoadingAllGrowPlansCallback === null)    {
                    SetValidateLoadingAllGrowPlansCallback(setTimeout(() => {performLoadAllGrowPlansRequest()}, 5000))
                }
            }
        }
    }, [loadedAllGrowPlans, allGrowPlansLoadingStatus, validateLoadingAllGrowPlansCallback])


    const allRecipes = useSelector((state) => selectAllRecipes(state))
    const activelyLoadingRecipesByIds = useSelector((state) => state.recipes.loadingRecipesByIds)
    const [validateRecipesPendingCallback, SetValidateRecipesPendingCallback] = React.useState(null)
    const validateRecipesToLoad = () => {
        let recipesToLoad = []
        SetValidateRecipesPendingCallback(null)
        for (let growPlan of Object.values(growPlansList)) {
            let foundRecipe = allRecipes.find((r) => r.id === growPlan.data.recipe_id && r.version === growPlan.data.recipe_version)
            if (foundRecipe === undefined)  {
                let foundAlreadyLoading = activelyLoadingRecipesByIds.find((info) => info.id === growPlan.data.recipe_id && info.version === growPlan.data.recipe_version)
                if (foundAlreadyLoading === undefined)  {
                    recipesToLoad.push({
                        id: growPlan.data.recipe_id,
                        version: growPlan.data.recipe_version
                    })
                }
            }
        }

        if (recipesToLoad.length > 0) {
            console.log("Loading recipes: ", recipesToLoad)
            dispatch(getRecipeById({recipes: recipesToLoad}))
        }
    }

    React.useEffect(() => {
        if (validateRecipesPendingCallback === null)    {
            SetValidateRecipesPendingCallback(setTimeout(() => {validateRecipesToLoad()}, 5000))
        }
    }, [validateRecipesPendingCallback, activelyLoadingRecipesByIds])

    React.useEffect(() => {
        validateRecipesToLoad()
    }, [growPlansList, activelyLoadingRecipesByIds])




    const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))
    const activelyLoadingRaftTypesByIds = useSelector((state) => state.raftTypes.loadingRaftTypesByIds)
    const [validateRaftTypesPendingCallback, SetValidateRaftTypesPendingCallback] = React.useState(null)
    const validateRaftTypesToLoad = () => {
        let raftTypesToLoad = []
        SetValidateRaftTypesPendingCallback(null)
        for (let growPlan of Object.values(growPlansList)) {
            let foundSeedlingRaftType = allRaftTypes.find((rT) => rT.id === growPlan.data.seedling_raft_type_id)
            if (foundSeedlingRaftType === undefined && raftTypesToLoad.indexOf(growPlan.data.seedling_raft_type_id) === -1 && activelyLoadingRaftTypesByIds.indexOf(growPlan.data.seedling_raft_type_id) === -1)  {
                raftTypesToLoad.push(growPlan.data.seedling_raft_type_id)
            }

            let foundGrowOutRaftType = allRaftTypes.find((rT) => rT.id === growPlan.data.grow_out_raft_type_id)
            if (foundGrowOutRaftType === undefined && raftTypesToLoad.indexOf(growPlan.data.grow_out_raft_type_id) === -1 && activelyLoadingRaftTypesByIds.indexOf(growPlan.data.grow_out_raft_type_id) === -1)  {
                raftTypesToLoad.push(growPlan.data.grow_out_raft_type_id)
            }
        }

        if (raftTypesToLoad.length > 0) {
            console.log("Loading raft types: ", raftTypesToLoad)
            dispatch(getRaftTypeById({raftTypeIds: raftTypesToLoad}))
        }
    }

    React.useEffect(() => {
        if (validateRaftTypesPendingCallback === null)    {
            SetValidateRaftTypesPendingCallback(setTimeout(() => {validateRaftTypesToLoad()}, 5000))
        }
    }, [validateRaftTypesPendingCallback, activelyLoadingRaftTypesByIds])

    React.useEffect(() => {
        validateRaftTypesToLoad()
    }, [growPlansList, activelyLoadingRaftTypesByIds])







    const [selectedGrowPlan, SetSelectedGrowPlan] = React.useState(null)
    React.useLayoutEffect(() => {
        SetSelectedGrowPlan(initialSelectedGrowPlan)
    }, [initialSelectedGrowPlan])
    const handleGrowPlanSelectedStateChanged = (growPlanId, state) => {
        if (selectedGrowPlan === growPlanId)    {
            if (state)  {
                SetSelectedGrowPlan(growPlanId)
            }else {
                SetSelectedGrowPlan(null)
            }
        }else if (state) {
            SetSelectedGrowPlan(growPlanId)
        }
    }
    const cancelClicked = () => {
        cancelScheduelGrowCallback()
    }
    const nextClicked = () =>   {
        growPlanConfirmedCallback({growPlan: selectedGrowPlan})
        transitionToNextStep(["select_grow_info", null])
    }


    
    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Plan Name</>, setPosition: (bounds, contentWidth) => {return {left: isMobile ? bounds.left - 30 : bounds.left, width: bounds.width}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
        plant: {label: <>Plant</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        recipe: {label: <>Recipe</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        yield: {label: <>D&Y</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
        grow_out_raft: {label: <>Grow Out</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"center"}}}  
        }
    }
  
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div style={{display:"flex", alignContent: "flex-start", alignSelf: "stretch"}}>What Grow Plan do you want to use?</div>
                    <TextInput icon={<SearchIcon/>} placeHolder={"Search"} onChange={onSearchInput}/>
                    <RowGroup
                        hasGroupHeader={false}
                        hasSettings={false}
                        hasContainer={false}
                        itemsScroll={true}
                        isExpandable={false}
                        hasColumnHeaders={true}
                        columnHeaders={columnHeaders}
                        columnSizes={columnSizes}
                        hasSubGroups={true}>

                        <GrowPlans_GrowPlanRow isPlaceholder={true} columnSizesChanged={(columns) => {
                            SetColumnSizes(columns)
                        }}/>
                        {Object.entries(growPlansList).map(([growPlanIdAsString, growPlanInfo]) => {
                            let growPlanId = parseInt(growPlanIdAsString)
                            return <>
                                <GrowPlans_GrowPlanRow 
                                    growPlanKey={growPlanId} 
                                    growPlanInfo={growPlanInfo} 
                                    columnSizes={columnSizes}
                                    isSelected={selectedGrowPlan === growPlanId}
                                    onSelectedChanged={(state) => {handleGrowPlanSelectedStateChanged(growPlanId, state)}}/>
                            </>
                        })}
                    </RowGroup>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Cancel"} onClick={cancelClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedGrowPlan === null}/>
            </div>
        </div>
    )
    
}


export default ScheduleGrow_SelectGrowPlanWalkthroughStep






const GrowPlans_GrowPlanRow = ({growPlanKey, growPlanInfo, isPlaceholder, isSelected, openTask, columnSizes, columnSizesChanged, onSelectedChanged}) => {
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
  
    const growPlan = isPlaceholder ? null : growPlanInfo.data

    const allRecipes = useSelector((state) => selectAllRecipes(state))
    let foundRecipe = allRecipes.find((r) => growPlan && r.id === growPlan.recipe_id && r.version === growPlan.recipe_version)

    const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))
    let foundSeedlingRaftType = null
    let numberOfSeedlingPlantSites = "N/A"
    let foundGrowOutRaftType = null
    let numberOfGrowOutPlantSites = "N/A"
    if (growPlan)   {
        foundSeedlingRaftType = allRaftTypes.find((rT) => rT.id === growPlan.seedling_raft_type_id)
        foundGrowOutRaftType = allRaftTypes.find((rT) => rT.id === growPlan.grow_out_raft_type_id)

        if (foundSeedlingRaftType !== undefined)    {
            numberOfSeedlingPlantSites = foundSeedlingRaftType.info["number_of_plant_sites"]
        }

        if (foundGrowOutRaftType !== undefined) {

        }
        numberOfGrowOutPlantSites = growPlan.grow_out_raft_usage
    }

    //console.log(foundSeedlingRaftType)


    let secondaryHeaderContent = []
    if (!isMobile) {
      secondaryHeaderContent.push({
        key: "plant", content: <>
        <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                    <div className="Text-SoftTruncate">Blackberries</div>
                    <div className="Text-SoftTruncate">Bitter Blue</div>
                </> : <>
                    <div className="Text-SoftTruncate">Blackberries</div>
                    <div className="Text-SoftTruncate">Bitter Blue</div>
                </>
            )}</div>
        </div>
      </>
      })
      secondaryHeaderContent.push({
        key: "recipe", content: <>
          <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                    <div className="Text-SoftTruncate">{foundRecipe !== undefined ? foundRecipe.name : "Unknown Recipe"}</div>
                    <div>{foundRecipe !== undefined && 
                        <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                            <div>v{foundRecipe.version}</div>
                            <div>{FormatDate(new Date(foundRecipe.modified_on), "MM/dd/yyyy")}</div>
                        </div>
                    }</div>
                </> : <>
                    <div>Very long recipe name</div>
                    <div>v100 00/00/0000</div>
                </>
            )}</div>
          </div>
        </>
      })
      //console.log(growPlan)
      secondaryHeaderContent.push({
        key: "yield", content: <>
          <div className="noselect" style={{fontSize:14}}>
            <div>
                {(!isPlaceholder ? <>
                    <div className="Text-SoftTruncate">{foundRecipe !== undefined ? FormatTime(foundRecipe.expected_duration * 1000, "~DDd ~HHh") : "N/A"}</div>
                    <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                        {growPlan.yield_unit_type === "weight" && 
                            <>{growPlan.estimated_yield}kg</>
                        }
                    </div>
                </> : <>
                    <div>100d</div>
                    <div>1000.00kg</div>
                </>
            )}</div>
          </div>
        </>
      })
     
      
      secondaryHeaderContent.push({
        key: "grow_out_raft", content: <>
          <div className="noselect" style={{fontSize:14}}>
             <div>
                {(!isPlaceholder ? <>
                    <div style={{display: "flex", justifyContent: "center"}}><Raft raftType={{plant_sites: numberOfGrowOutPlantSites}}/></div>
                </> : <>
                    <div style={{whiteSpace: "nowrap"}}>Grow Out Raft</div>
                </>
            )}</div>
          </div>
        </> 
      })
    }
  
    let title = isPlaceholder ? <>My Amazing Lettuce is the best</> : growPlan.name
    
      
    let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className="">#GP{growPlan.uid}</div>)

    if (!isPlaceholder) {
      if (growPlan.category === "grow") {
        subtitle = "#G" + growPlan.reference_id
       
      }
  
  
    }
    return (<>
      <RowGroupRow
        key={isPlaceholder ? 0 : growPlan.id}
        title={title}
        titleFontSize={14}
        cardBackgroundColor={"transparent"}
        cardBorderColor={"transparent"}
        showRowSeperator={true}
        showExpandedStatusContainerStyles={false}
        statusContainerPadding={0}
        statusContainerClicked={() => {}}
        minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
        hasFlexCell={true}
        subtitle={<div className="">{subtitle}</div>}
        showStatusIndicator={false}
        isPlaceholderRow={isPlaceholder}
        columnSizesChanged={columnSizesChanged ?? (() => {})}
        columnSizes={columnSizes}
        secondaryContent={secondaryHeaderContent}
        secondaryContentTriggersExpandToggle={true}
        hasIssues={true}
        numberOfIssues={0}
        isExpandable={isMobile}
        isSelectable={!isMobile}
        onSelectedChanged={(state) => {onSelectedChanged(state); isSelected = state}}
        selectedState={isSelected}
        hasAdditionalFunctions={false}
        content={<>
          {(() => {
            if (isMobile && !isPlaceholder) {
              return (<>
                <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                  <Button content={isSelected ? "Unselect" : "Select"} onClick={() => {onSelectedChanged(!isSelected); isSelected = !isSelected}}/>
                  <div className="FlexContent-5">
                    <span>Plant</span>
                    <Badge content="To Do"/>
                  </div>
  
                  <div className="FlexContent-5">
                    <div className="Text-SoftTruncate">{foundRecipe !== undefined ? foundRecipe.name : "Unknown Recipe"}</div>
                    <div>{foundRecipe !== undefined && 
                        <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                            <div className="Text-SoftTruncate">v{foundRecipe.version}</div>
                            <div className="Text-SoftTruncate">{FormatDate(new Date(foundRecipe.modified_on), "MM/dd/yyyy")}</div>
                        </div>
                    }</div>
                  </div>

                  <div className="FlexContent-5">
                    <span>D&Y</span>
                    <span>Stevenson</span>
                  </div>

                  <div className="FlexContent-5">
                      <Raft raftType={{plant_sites: numberOfSeedlingPlantSites}}/>
                  </div>
                  <div className="FlexContent-5">
                      <Raft raftType={{plant_sites: numberOfGrowOutPlantSites}}/>
                  </div>
                </div>
              </>)
            }
        
          })()}
        </>}
      />
    </>)
  }