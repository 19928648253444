import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../../helpers';



const DataStatus_HumidificationCell = ({ bladeId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
  
    const [airRHManagementStatus] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "air_rh_management_mode"))

    const [humidityLoadComponentInfo] 
      = useSelector((state) => selectBladeComponentInfo(state, bladeId, "CanopyAirSpace", ["rhhpid"]), _.isEqual)
    
    const [humidityValveComponentInfo] 
      = useSelector((state) => selectBladeComponentInfo(state, bladeId, "HumiditySupplyValve", ["state"]), _.isEqual)

    let humidifyLoad = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, humidityLoadComponentInfo), _.isEqual)
    let humidifyValveState = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, humidityValveComponentInfo), _.isEqual)



    let humidificationStatus = airRHManagementStatus === "Humidify" ? "On" : "Off"

    
    let dataListItems = [
      {label: "Duty Cycle", value: humidifyLoad.value, suffix: "%", visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 100, status: "normal"}
      }},
      {label: "Valve State", value: parseBool(humidifyValveState.value) ? "Open" : "Closed", suffix: ""},
    ]


    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Humidification</div>
            <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{humidificationStatus}</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            <DropDownButton content={<AdditionalOptions/>} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <DataStatus_DataList items={dataListItems}/>
        </div>
      </div>
    </>)
  }
  
  export default DataStatus_HumidificationCell