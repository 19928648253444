
import './SelectGrows.scss';
import React from 'react'
import _ from 'underscore';

import { useSelector, useDispatch } from 'react-redux'
import { FormatDate } from '../helpers'
import { MaintainLoadAllGrows, selectAllGrowIds, selectAllGrowPlanIdsForGrows, selectAllGrows, selectAllRecipeInfoForGrows, selectGrowById } from '../redux/entities/Grow';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import Button from '../components/Button';
import { useMediaQuery } from 'react-responsive';
import RowGroup from '../components/RowGroup/RowGroup';
import RowGroupRow from '../components/RowGroup/RowGroupRow';
import { InitialLoadAllFacilities, selectAllFacilityIds } from '../redux/entities/Facilities';
import { MaintainRecipeVersions, selectAllRecipes } from '../redux/entities/Recipes';
import { InitialLoadAllGrowPlans, selectAllGrowPlans } from '../redux/entities/GrowPlans';
import CircularGauge from '../components/CircularGauge';


const SelectGrows = ({
  selectedFacility,
  selectedGrowId, selectedGrowIds,
  allowMultiselect, maxSelected,
  onGrowsSelected
}) => {


  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({ maxHeight: 800 }) && isDesktop

  const allFacilityIds = useSelector(selectAllFacilityIds, _.isEqual)
  const activeFacilityIds = selectedFacility !== undefined ? (selectedFacility === "all" ? allFacilityIds : [selectedFacility]) : []
  const activeGrowIds = useSelector(selectAllGrowIds, _.isEqual)
  const activeGrowPlanIds = useSelector((state) => selectAllGrowPlanIdsForGrows(state, activeGrowIds), _.isEqual)
  const activeGrowRecipesInfo = useSelector((state) => selectAllRecipeInfoForGrows(state, activeGrowIds), _.isEqual)

  const allGrows = useSelector(state => selectAllGrows(state))


  const [growsList, SetGrowsList] = React.useState([])
  const [currentSearchInput, SetSearchInput] = React.useState("")

  const [currentSelectedGrowIds, SetSelectedGrowIds] = React.useState([])
  React.useEffect(() => {
    if (!allowMultiselect) {
      if (selectedGrowId !== null)  {
        SetSelectedGrowIds([selectedGrowId])
      }else {
        SetSelectedGrowIds([])
      }
    }else {
      if (selectedGrowIds !== null)  {
        SetSelectedGrowIds([...selectedGrowIds])
      }else {
        SetSelectedGrowIds([])
      }
    }
  }, [selectedGrowId, selectedGrowIds, allowMultiselect])


  React.useEffect(() => {

    let currentGrows = []

    let availableGrows = [...activeGrowIds]


    if (currentSearchInput === "") {
      currentGrows = [...availableGrows]
    } else {
      currentGrows = [...availableGrows]
      /*currentGrows.push(...availableGrows.filter((grow) => {
        if (seed.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1 && currentGrows.indexOf(seed) === -1) {
          return seed
        }
      }))*/
    }



    SetGrowsList(currentGrows)
  }, [activeGrowIds, currentSearchInput])




  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const handleGrowSelectedStateChanged = (growId, state) => {
    let updatedSelectedGrowIds = [...currentSelectedGrowIds]
    if (!allowMultiselect) {
      if (state)  {
        updatedSelectedGrowIds = [growId]
      }else {
        updatedSelectedGrowIds = []
      }
    }else {
      if (state)  {
        if (maxSelected && updatedSelectedGrowIds.length === maxSelected) {
          return false
        }
        updatedSelectedGrowIds.push(growId)
      }else {
        updatedSelectedGrowIds = updatedSelectedGrowIds.filter((gId) => gId != growId)
      }
    }
    if (onGrowsSelected !== undefined)  {
      onGrowsSelected(updatedSelectedGrowIds)
    }
    SetSelectedGrowIds(updatedSelectedGrowIds)
    return true
  }


  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = { status: { label: <></>, setPosition: (bounds, contentWidth) => { return { left: bounds.left, width: bounds.width } } } }
  if (!isMobile) {
    columnHeaders = {
      ...columnHeaders,
      recipe: {label: <>Recipe</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
      score: {label: <>Score</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
    }
  }

  return (
    <>
      <InitialLoadAllFacilities/>
      <MaintainLoadAllGrows facilityIds={activeFacilityIds} />
      <InitialLoadAllGrowPlans growPlanIds={activeGrowPlanIds}/>
      <MaintainRecipeVersions activeRecipes={activeGrowRecipesInfo}/>
      <div id="SelectGrows">
        <RowGroup
          hasGroupHeader={false}
          hasSettings={false}
          hasContainer={false}
          itemsScroll={true}
          isExpandable={false}
          hasColumnHeaders={false}
          columnHeaders={columnHeaders}
          columnSizes={columnSizes}
          hasSubGroups={true}>

          <SelectGrows_GrowRow isPlaceholder={true} columnSizesChanged={(columns) => {
            SetColumnSizes(columns)
          }} />

          {growsList.map((growId) => {
            return (
              <SelectGrows_GrowRow
                key={growId}
                growId={growId}
                selectedGrowIds={currentSelectedGrowIds}
                onSelectedChanged={(state) => { return handleGrowSelectedStateChanged(growId, state) }}
                columnSizes={columnSizes} />
            )
          })}
        </RowGroup>
      </div>
    </>
  )
}

export default SelectGrows


const SelectGrows_GrowRow = ({ growId, isPlaceholder, selectedGrowIds, columnSizes, columnSizesChanged, onSelectedChanged }) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const grow = useSelector((state) => selectGrowById(state, growId))
  const allRecipes = useSelector(selectAllRecipes)
  const allGrowPlans = useSelector(selectAllGrowPlans)
  let activeRecipe = null
  let activeGrowPlan = undefined
  if (!isPlaceholder && grow !== undefined) {
    activeRecipe = allRecipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
    activeGrowPlan = allGrowPlans.find((gP) => gP.id === grow.grow_plan_id)
  }

  let secondaryHeaderContent = []
  
  let havePercentage = false
  let percentage = 0
  if (!isPlaceholder) {
    percentage = grow.totalScore
  }
  const isSelected = !isPlaceholder ? selectedGrowIds.includes(growId) : false


  secondaryHeaderContent.push(
    {key: "recipe", content: <>
      <div className="noselect" style={{fontSize:14}}>
        <div>
            {(!isPlaceholder ? <>
                <div className="Text-SoftTruncate">{activeRecipe !== undefined ? activeRecipe.name : "Unknown Recipe"}</div>
                <div>{activeRecipe !== undefined && 
                    <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                        <div>v{activeRecipe.version}</div>
                        <div>{FormatDate(new Date(activeRecipe.modified_on), "MM/dd/yyyy")}</div>
                    </div>
                }</div>
            </> : <>
                <div>Very long recipe name</div>
                <div>v100 00/00/0000</div>
            </>
        )}</div>
      </div>
    </>}
  )
  secondaryHeaderContent.push(
    {key: "score", content: <>
      <div style={{width:"40px"}}>
        <CircularGauge value={parseInt(percentage)} text={havePercentage ? percentage : "N/A"} unit={""} valueDisplayFontSize={12}/>
      </div>
    </>}
  )
  

  const title = isPlaceholder ? "Placeholder" : activeGrowPlan !== undefined ? activeGrowPlan.name : "Unnamed Plan"
  const subtitle = <div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{isPlaceholder ? "#G12512312" : "#G" + grow.id}</div>

  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : growId}
      title={title}
      subtitle={subtitle}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => { }}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => { })}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={false}
      isSelectable={true}
      onSelectedChanged={(state) => onSelectedChanged(state)}
      selectedState={isSelected}
      hasAdditionalFunctions={false}
      content={<></>} />
  </>)
}