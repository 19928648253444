import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData, IsSessionActive } from '../../pages/Account/Common'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { selectAllGrows } from './Grow'


const processGrowPlanFromAPI = (growPlan) =>  {
  
  return growPlan
}

export const getAllGrowPlans = createAsyncThunk('growPlans/getAllGrowPlans', async ({}, { getState }) => {
  return await FetchPost(APIRepository.GrowPlans.GetAllGrowPlans, {
    ...getAccountSessionData(getState()),
    have_grow_plans: {}
  })  
},
{
  condition: (args, { getState }) => {
    const { growPlans } = getState()
    if (growPlans.loadingAllGrowPlansStatus === 'pending') {
      return false
    }
  },
})

export const getGrowPlanById = createAsyncThunk('growPlans/getGrowPlanById', async ({growPlanId, growPlans}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (growPlanId !== undefined) {
    payload.grow_plan_id = growPlanId
  }
  if (growPlans !== undefined) {
    payload.grow_plans = growPlans
  }
  return await FetchPost(APIRepository.GrowPlans.GetGrowPlanById, payload)  
},
{
  condition: (args, { getState }) => {
    const { growPlans } = getState()
    if (growPlans.loadingGrowPlansStatus === 'pending') {
      return false
    }
  },
})

export const createGrowPlan = createAsyncThunk('growPlans/create', async ({growPlanData, callback}, { getState }) =>  {
  return await FetchPost(APIRepository.GrowPlans.Create, {
    ...getAccountSessionData(getState()),
    grow_plan: growPlanData
  })  
})


export const growPlansSlice = createSlice({
  name: 'growPlans',
  initialState: {
    growPlans:  [

    ],

    loadingGrowPlansStatus: 'idle',
    error: null,
    haveInitialData: false,
    loadedAllGrowPlans: false,
    loadingData: false,
    loadingAllGrowPlansStatus: 'idle'
  },
  reducers: {
    

    growPlanUpdate: (state, action) => {
      let hasChanged = false
      let newGrowPlans = { ...state, growPlans: state.growPlans.map((growPlan, index) => {
        if (growPlan.id !== action.payload.growPlanId) {
          return growPlan
        }
    
        if (growPlan[action.payload.prop] === undefined || growPlan[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...growPlan,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newGrowPlans
      }
    }
  },
  extraReducers: {
    [getAllGrowPlans.pending]: (state) => {
      state.loadingAllGrowPlansStatus = 'pending';
    },
    [getAllGrowPlans.fulfilled]: (state, action) => {
      state.loadingAllGrowPlansStatus = 'fulfilled';
      state.haveInitialData = true
      action.payload.grow_plans.map(function(growPlan){ processGrowPlanFromAPI(growPlan); return growPlan });
      state.growPlans = action.payload.grow_plans;
      state.loadedAllGrowPlans = true
    },
    [getAllGrowPlans.rejected]: (state) => {
      state.loadingAllGrowPlansStatus = 'rejected';
    },

    [getGrowPlanById.pending]: (state) => {
      state.loadingGrowPlansStatus = 'pending';
    },

    [getGrowPlanById.fulfilled]: (state, action) => {
      state.loadingGrowPlansStatus = 'fulfilled';
      if (action.payload.grow_plan !== null) {
        state.growPlans.push(processGrowPlanFromAPI(action.payload.grow_plan))
      }
      if (action.payload.grow_plans !== null) {
        for (let growPlan of action.payload.grow_plans)  {
          state.growPlans.push(processGrowPlanFromAPI(growPlan))
        }
      }
    },

    [getGrowPlanById.rejected]: (state) => {
      state.loadingGrowPlansStatus = 'rejected';
    },

    
    [createGrowPlan.pending]: (state) => {
      state.loadingGrowPlansStatus = 'pending';
    },

    [createGrowPlan.fulfilled]: (state, action) => {
      state.loadingGrowPlansStatus = 'fulfilled';
      if (action.payload.success) {
        state.growPlans = [...state.growPlans, {...action.meta.arg.growPlanData, uid: action.payload.grow_plan_uid, }]
        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(true)
        }
      }else {
        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(false)
        }
      }
    },

    [createGrowPlan.rejected]: (state, action) => {
      state.status = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
  }
  }
})








export const InitialLoadAllGrowPlans = ({growPlanIds = undefined}) => {
  const dispatch = useDispatch()

  const isSessionActive = useSelector(state => IsSessionActive(state))
  const loadedAllGrowPlans = useSelector((state) => state.growPlans.loadedAllGrowPlans)
  const loadingAllGrowPlansStatus = useSelector((state) => state.growPlans.loadingAllGrowPlansStatus)

  const loadingGrowPlansStatus = useSelector((state) => state.growPlans.loadingGrowPlansStatus)
  const allGrowPlans = useSelector(selectAllGrowPlans)
  
  useEffect(() => {
    if (isSessionActive)  {
      if (growPlanIds === undefined)  {
        if (!loadedAllGrowPlans && loadingAllGrowPlansStatus !== "pending")   {
          dispatch(getAllGrowPlans({}))
        }
      }else {
        if (loadingGrowPlansStatus !== "pending") {
          //Check for loaded all blade zones in the facility TODO
          let growPlanIdsToLoad = []
          
          for (let growPlanId of growPlanIds) {
            if (allGrowPlans.find((gP) => gP.id === growPlanId) === undefined)  {
              growPlanIdsToLoad.push(growPlanId)
            }
          }
          if (growPlanIdsToLoad.length > 0) {
            dispatch(getGrowPlanById({growPlans: growPlanIdsToLoad}))
          }
        }
      }
    }
  }, [isSessionActive, loadedAllGrowPlans, loadingAllGrowPlansStatus, growPlanIds, allGrowPlans, loadingGrowPlansStatus])
}





// Action creators are generated for each case reducer function
export const { growPlanUpdate} = growPlansSlice.actions

export default growPlansSlice.reducer

export const selectAllGrowPlans = state => state.growPlans.growPlans
export const selectAllUsableGrowPlans = (state, accountId) => {
  if (accountId === null) {
    return []
  }
  return state.growPlans.growPlans.filter((gP) => gP.created_by_account_id === accountId)
}


export const selectGrowPlanById = (state, growPlanId) =>
  state.growPlans.growPlans.find(growPlan => growPlan.id === growPlanId)