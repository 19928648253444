import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  {Repository as APIRepository, FetchPost} from '../api'
import { getAccountSessionData } from '../../pages/Account/Common'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'underscore';

const processPlantTypeGroupFromAPI = (plantGroup) => {
  return plantGroup  
}
const processPlantTypeFromAPI = (plantType) =>  {
  if (plantType.loaded_plant_varieties === undefined) {
    plantType.loaded_plant_varieties = false
  }
  return plantType
}
const processPlantVarietyFromAPI = (plantVariety) => {
  if (plantVariety.loaded_grow_seeds === undefined) {
    plantVariety.loaded_grow_seeds = false
  }
  return plantVariety
}

const processGrowSeedFromAPI = (growSeed) => {
  return growSeed
}

export const getAllPlantTypes = createAsyncThunk('plantTypes/getAllPlantTypes', async ({}, { getState }) => {
  return await FetchPost(APIRepository.PlantTypes.GetAllPlantTypes, {
    ...getAccountSessionData(getState()),
    have_plant_types: {}
  })  
})

export const getPlantTypeById = createAsyncThunk('plantTypes/getPlantTypeById', async ({plantTypeId, plantTypeIds}, { getState }) => {
  const state = getState()
  let payload = {
    account_id: state.account.id,
    session_token: state.account.sessionToken,
  }
  if (plantTypeId !== undefined) {
    payload.plant_type_id = plantTypeId
  }
  if (plantTypeIds !== undefined) {
    payload.plant_type_ids = plantTypeIds
  }
  return await FetchPost(APIRepository.PlantTypes.GetPlantTypeById, payload)  
})



export const getPlantVarietiesByTypeId = createAsyncThunk('plantTypes/getPlantVarietiesByTypeId', async ({plantTypeId}, { getState }) => {
  const state = getState()
  let payload = {
    account_id: state.account.id,
    session_token: state.account.sessionToken,
    plant_type_id: plantTypeId,
  }
  return await FetchPost(APIRepository.PlantTypes.GetPlantVarietiesByTypeId, payload)  
})



export const getGrowSeedsByPlantVarietyId = createAsyncThunk('growPlans/getGrowSeedsByPlantVarietyId', async ({plantVarietyId}, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    plant_variety_id: plantVarietyId,
  }
  return await FetchPost(APIRepository.PlantTypes.GetGrowSeedsByPlantVarietyId, payload)  
})



export const plantTypesSlice = createSlice({
  name: 'plantTypes',
  initialState: {
    plantTypes:  [],
    plantTypeGroups: [],
    plantVarieties: [],
    growSeeds:  [/*
      {id: 1, name: "Picante Mix", description: "Typical delicious mixture", plant_variety_id: 1},
      {id: 2, name: "Mustard Greens", description: "Tangy but delicious", plant_variety_id: 2},
      {id: 3, name: "Buttttttterhead", description: "Not margerine", plant_variety_id: 3}
    */],

    status: 'idle',
    error: null,
    haveInitialData: false,
    loadedAllPlantTypes: false,
    loadingData: false
  },
  reducers: {
    

    plantTypeUpdate: (state, action) => {
      let hasChanged = false
      let newPlantTypes = { ...state, plantTypes: state.plantTypes.map((plantType, index) => {
        if (plantType.id !== action.payload.plantTypeId) {
          return plantType
        }
    
        if (plantType[action.payload.prop] === undefined || plantType[action.payload.prop] !== action.payload.value)  {
          hasChanged = true
        }
        return {
          ...plantType,
          [action.payload.prop]: action.payload.value
        }
      })}

      if (hasChanged) {
        return newPlantTypes
      }
    }
  },
  extraReducers: {
    [getAllPlantTypes.pending]: (state) => {
      state.status = 'pending';
    },

    [getAllPlantTypes.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      if (action.payload.plant_types)  {
        state.plantTypes = action.payload.plant_types.map(function(plantType){ 
          return processPlantTypeFromAPI(plantType);
        })
        state.plantTypeGroups = action.payload.plant_groups.map(function(plantGroup){ 
          return processPlantTypeGroupFromAPI(plantGroup);
        })
      }
      
      state.loadedAllPlantTypes = true
    },

    [getAllPlantTypes.rejected]: (state) => {
      state.status = 'rejected';
    },


    [getPlantTypeById.pending]: (state) => {
      state.status = 'pending';
    },

    [getPlantTypeById.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      if (action.payload.plant_types)  {
        for (let plantType of action.payload.plant_types) {
          if (state.plantTypes.find((pT) => pT.id === plantType.id) === undefined)  {
            state.plantTypes.push(processPlantTypeFromAPI(plantType))
          }
        }
      }
      if (action.payload.plant_varieties)  {
        for (let variety of action.payload.plant_varieties) {
          if (state.plantVarieties.find((pV) => pV.id === variety.id) === undefined)  {
            state.plantVarieties.push(processPlantVarietyFromAPI(variety))
          }
        }
      }
      state.plantTypes = state.plantTypes.map((plantType) => {
        if (plantType.id !== action.meta.arg.plantTypeId && action.meta.arg.plantTypeIds.includes(plantType.id) === false) {
          return plantType
        }
        return {...plantType, loaded_plant_varieties: true}
      })
    },

    [getPlantTypeById.rejected]: (state) => {
      state.status = 'rejected';
    },



    [getPlantVarietiesByTypeId.pending]: (state) => {
      state.status = 'pending';
    },

    [getPlantVarietiesByTypeId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      if (action.payload.plant_varieties)  {
        for (let variety of action.payload.plant_varieties) {
          if (state.plantVarieties.find((pV) => pV.id === variety.id) === undefined)  {
            state.plantVarieties.push(processPlantVarietyFromAPI(variety))
          }
        }
      }
      state.plantTypes = state.plantTypes.map((plantType) => {
        if (plantType.id !== action.meta.arg.plantTypeId) {
          return plantType
        }
        return {...plantType, loaded_plant_varieties: true}
      })
    },

    [getPlantVarietiesByTypeId.rejected]: (state) => {
      state.status = 'rejected';
    },
    

    [getGrowSeedsByPlantVarietyId.pending]: (state) => {
      state.status = 'pending';
    },

    [getGrowSeedsByPlantVarietyId.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.haveInitialData = true
      if (action.payload.grow_seeds)  {
        for (let growSeed of action.payload.grow_seeds) {
          if (state.growSeeds.find((gS) => gS.id === growSeed.id) === undefined)  {
            state.growSeeds.push(processGrowSeedFromAPI(growSeed))
          }
        }
      }
      state.plantVarieties = state.plantVarieties.map((plantVariety) => {
        if (plantVariety.id !== action.meta.arg.plantVarietyId) {
          return plantVariety
        }
        return {...plantVariety, loaded_grow_seeds: true}
      })
    },

    [getGrowSeedsByPlantVarietyId.rejected]: (state) => {
      state.status = 'rejected';
    },
  }
})





export const MaintainActivePlantTypes = ({ activePlantTypeIds, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allPlantTypes = useSelector((state) => selectAllPlantTypes(state), _.isEqual)
  
  /*Load recipes */
  const validatePlantTypesToLoad = () => {

    let plantTypeIdsToLoad = []
    for (let plantTypeId of activePlantTypeIds) {
        let foundPlantType = allPlantTypes.find((rT) => rT.id === plantTypeId)
        if (foundPlantType === undefined || !foundPlantType.loaded_plant_varieties)  {
          plantTypeIdsToLoad.push(plantTypeId)
        }
    }

    if (plantTypeIdsToLoad.length > 0) {
      console.log("Loading plant types: ", plantTypeIdsToLoad)
      dispatch(getPlantTypeById({plantTypeIds: plantTypeIdsToLoad}))
    }
  }
  useEffect(() => {
    const plantTypesLoadInterval = setInterval(() => {
      validatePlantTypesToLoad()
    }, interval);
    validatePlantTypesToLoad()
    return () => clearInterval(plantTypesLoadInterval);
  }, [allPlantTypes, activePlantTypeIds]);
}



// Action creators are generated for each case reducer function
export const { plantTypeUpdate} = plantTypesSlice.actions

export default plantTypesSlice.reducer

export const selectAllPlantTypes = state => state.plantTypes.plantTypes
export const selectAllPlantTypeGroups = state => state.plantTypes.plantTypeGroups
export const selectAllPlantVarieties = state => state.plantTypes.plantVarieties

export const selectPlantTypeById = (state, plantTypeId) =>
  state.plantTypes.plantTypes.find(plantType => plantType.id === plantTypeId)




export const selectAllGrowSeeds = state => state.plantTypes.growSeeds

export const selectSeedById = (state, seedId) =>
  state.plantTypes.growSeeds.find(gS => gS.id === seedId)
