import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';



const DataStatus_EnergyCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

 
  const [bladeCurrentComponentInfo, bladeWattageComponentInfo, bladeVoltageComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, bladeId, "RackCurrentTransducer", ["current", "w", "v"]), _.isEqual)

  let bladeCurrent = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, bladeCurrentComponentInfo), _.isEqual)
  let bladeWattage = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, bladeWattageComponentInfo), _.isEqual)
  let bladeVoltage = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, bladeVoltageComponentInfo), _.isEqual)

  let dataListItems = [
    {
      label: "Current", value: bladeCurrent.value, suffix: "A", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 20, status: "normal" }
      }
    },
    {
      label: "Wattage", value: bladeWattage.value, suffix: "W", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 5000, status: "normal" }
      }
    },
    {
      label: "Voltage", value: bladeVoltage.value, suffix: "V"
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Energy</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default DataStatus_EnergyCell