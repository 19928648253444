import './AnalogGauge.scss';
import React from 'react';
import useMeasure from '../useMeasure';
import { remapRange } from '../helpers';



const AnalogGauge = ({value, auxText, suffix, min, max, gaugeContent, desiredMin, desiredMax, trendData, applicable, valueDisplayFontSize}) => {

  const [gaugeColors, setGaugeColors] = React.useState({
    good: {min: 90, max:100, color: "rgba(90, 195, 100, 1)"},
    operational: {min: 75, max:90, color: "rgba(0, 255, 0, 1)"},
    critical: {min: 50, max:75, color: "rgba(255, 122, 44, 1)"},
    fail: {min: 0, max:50, color: "rgba(0, 255, 0, 1)"},
    neutral: {color: "rgba(120, 122, 12, 1)"},
  })
  const gaugeCircumference = 45 * 2 * Math.PI;
  const arcLength = gaugeCircumference * 0.5;


  const [containerRef, {width: containerWidth}] = useMeasure()





  let gaugeColor = gaugeColors.neutral.color
  let foundGaugeColor = Object.values(gaugeColors).find((gC) => gC.min <= value && gC.max >= value)
  if (foundGaugeColor !== undefined)  {
    gaugeColor = foundGaugeColor.color
  }
  
  



  const minTickLocation = 50



  const valueMapped = remapRange(value, [min, max], [0, 100])
  const valueArcUsage = arcLength - (valueMapped / 100) * arcLength;


  return (
    <div className="AnalogGauge-Container" ref={containerRef}>
      <div className="AnalogGauge">
        <div className="AnalogGauge-Gauge" style={{height:containerWidth / 2}}>
          <svg className="AnalogGauge-Gauge-Canvas" viewBox={"0 0 100 50"}>

           <path class="AnalogGauge-Gauge-Trail" d="M 50,50 m -45,0 a 45,45 0 1 1 45,45" fill-opacity="0"/>
           <path class="AnalogGauge-Gauge-Actual" d="M 50,50 m -45,0 a 45,45 0 1 1 45,45" fill-opacity="0"  style={{strokeDasharray: arcLength + " " + gaugeCircumference, strokeDashoffset: valueArcUsage}}/>

           {(desiredMin && desiredMax) && <>
            {(() => {

              const desiredMinArcUsage = remapRange(desiredMin, [min, max], [0, 180])
              const desiredMaxArcUsage = remapRange(desiredMax, [min, max], [0, 180])
              return (<>
                
                <path class="AnalogGauge-Gauge-MinRangeTick" d={"M 50,50 m -39,0 l -12,0"} fill-opacity="0" style={{transform: "rotate(" + desiredMinArcUsage.toString() + "deg)"}}/>
                <path class="AnalogGauge-Gauge-MaxRangeTick" d={"M 50,50 m -39,0 l -12,0"} fill-opacity="0" style={{transform: "rotate(" + desiredMaxArcUsage.toString() + "deg)"}}/>
              </>)
            })()}
           </>}
            
          </svg>
          
          <div className="AnalogGauge-Gauge-Content">
            {gaugeContent && gaugeContent}
            {(gaugeContent === null) && <>
              <div className="AnalogGauge-Gauge-StandardContent">{value}{suffix && suffix}</div>
            </>}
          </div>
 
        </div>
      </div>
    </div>
  )
} 

AnalogGauge.defaultProps = {
  suffix: null,
  min: 0,
  max: 100,
  desiredMin: null,
  desiredMax: null,
  applicable: true,
  auxText: false,
  gaugeContent: null,
  valueDisplayFontSize: 24
}


export default AnalogGauge