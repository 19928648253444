import React from 'react';
import { FormatTime, RoundToNearest } from '../../../helpers';
import useMeasure from '../../../useMeasure';
import '../GrowAnalyticsPage.scss';
import _ from 'underscore';




export const GrowAnalyticsPage_ChartPointCrosshair = ({ calculatePositionFromTime, chartAreaHeight, chartAreaWidth, pointerPosition, activeTime, nearestActiveDataPoint }) => {
  if (nearestActiveDataPoint !== null || pointerPosition !== null) {
    const horizontalLineProps = {
      style: {
        width: chartAreaWidth
      }
    }
    const verticalLineProps = {
      style: {
        height: chartAreaHeight
      }
    }
    if (nearestActiveDataPoint !== null) {
      horizontalLineProps.style.top = nearestActiveDataPoint.y
      verticalLineProps.style.left = nearestActiveDataPoint.x
    } else {
      horizontalLineProps.style.top = pointerPosition.top
      verticalLineProps.style.left = pointerPosition.left
    }

    return (<>
      <div className="GrowAnalytics-Charting-MainChart-ActivePointCrosshair-VerticalLine" {...verticalLineProps}></div>
      <div className="GrowAnalytics-Charting-MainChart-ActivePointCrosshair-HorizontalLine" {...horizontalLineProps}></div>
    </>)
  }
}


export const GrowAnalyticsPage_DataLegendTooltip = ({
  isTouchDevice, isPanningChart, pointerPosition,
  chartArea, calculatePositionFromTime,
  dataTypeToggles, activeDataTypeToggles, activeTime, nearestActiveDataPoint, activeDataPoints }) => {

  const [tooltipRef, { width: tooltipWidth, height: tooltipHeight }] = useMeasure()


  const tooltipSpacing = isTouchDevice ? 15 : 5;

  const tooltipContainerProps = { style: {} }
  const tooltipContentProps = { style: {} }
  if ((!isPanningChart || !isTouchDevice) && (nearestActiveDataPoint !== null || pointerPosition !== null)) {
    if (nearestActiveDataPoint !== null) {
      tooltipContainerProps.style.left = nearestActiveDataPoint.x + tooltipSpacing
      tooltipContainerProps.style.top = nearestActiveDataPoint.y + tooltipSpacing
    } else {
      //const position = calculatePositionFromTime(activeTime)
      tooltipContainerProps.style.left = pointerPosition.left + tooltipSpacing
      tooltipContainerProps.style.top = pointerPosition.top + tooltipSpacing
    }


    if (tooltipContainerProps.style.left + tooltipWidth + tooltipSpacing > chartArea.width) {
      tooltipContentProps.style.left = -tooltipWidth - tooltipSpacing
    }
    if (tooltipContainerProps.style.left + tooltipContentProps.style.left < 0) {
      tooltipContainerProps.style.left = 0
      tooltipContentProps.style.left = 0
    }

    if (tooltipContainerProps.style.top + tooltipHeight + tooltipSpacing > chartArea.height) {
      tooltipContentProps.style.top = -tooltipHeight - tooltipSpacing
    }
    if (tooltipContainerProps.style.top + tooltipContentProps.style.top < 0) {
      tooltipContainerProps.style.top = 0
      tooltipContentProps.style.top = 0
    }
  } else {
    tooltipContainerProps.style.visibility = "hidden"
  }





  //console.log(nearestActiveDataPoint)
  return (
    <div className="GrowAnalytics-Charting-MainChart-Tooltip-Wrapper"  {...tooltipContainerProps}>
      <div className="GrowAnalytics-Charting-MainChart-Tooltip-Container">
        <div className="GrowAnalytics-Charting-MainChart-Tooltip-Content" ref={tooltipRef} {...tooltipContentProps}>
          {Object.entries(activeDataTypeToggles).filter(([_, gT]) => gT.active).map(([groupKey, groupInfo]) => {
            const dataTypeGroup = dataTypeToggles[groupKey]
            return (<>
              <div className="FlexContent" style={{ gap: 2 }}>
                <div className="FlexContent FlexContent-Center">
                  <div className="Text-Medium-S10">{dataTypeGroup.label}</div>
                </div>
                <div className="FlexContent" style={{ gap: 2 }}>
                  {groupInfo.keys.map((dataTypeKey) => {
                    const dataTypeInfo = dataTypeGroup.dataTypes[dataTypeKey]
                    if (dataTypeInfo.selected) {
                      let isDataPointActive = false

                      let foundDatapoint = null
                      if (dataTypeInfo.zoneComponent !== undefined && dataTypeInfo.zoneComponent !== null) {
                        if (nearestActiveDataPoint !== null && nearestActiveDataPoint.componentId === dataTypeInfo.zoneComponent.componentInfo.id && nearestActiveDataPoint.identifier === dataTypeInfo.identifier) {
                          isDataPointActive = true
                        }

                        const componentDataPoints = activeDataPoints[dataTypeInfo.zoneComponent.componentInfo.id]
                        if (componentDataPoints !== undefined && componentDataPoints[dataTypeInfo.identifier] !== undefined) {
                          foundDatapoint = componentDataPoints[dataTypeInfo.identifier]
                        }
                      }
                      const pointValue = foundDatapoint !== null ? RoundToNearest(foundDatapoint.value, dataTypeInfo.resolution).toString() + dataTypeInfo.unit : "N/A"
                      return (<>
                        <div className={"GrowAnalytics-Charting-MainChart-Tooltip-DataItem" + (isDataPointActive ? " GrowAnalytics-Charting-MainChart-Tooltip-DataItem-Active" : "")}>
                          <div className="GrowAnalytics-Charting-MainChart-Tooltip-DataColorIndicator" style={{ backgroundColor: dataTypeInfo.color }}></div>
                          <div className="GrowAnalytics-Charting-MainChart-Tooltip-DataItem-Label Text-Medium-S12" style={{ flex: 1 }}>
                            {/*<div className="GrowAnalytics-Charting-MainChart-Tooltip-DataItem-ActiveDataItemIndicator"></div>*/}
                            <div>{dataTypeInfo.label}</div>
                          </div>
                          <div className="GrowAnalytics-Charting-MainChart-Tooltip-DataItem-Value Text-Medium-S12">{pointValue}</div>
                        </div>
                      </>)
                    }
                  })}
                </div>
              </div>
            </>)
          })}
        </div>
      </div>
    </div>
  )
}




export const GrowAnalyticsPage_ActiveTimeTooltip = ({ calculatePositionFromTime, nearestActiveDataPoint, activeTime, chartAreaWidth, chartAreaHeight }) => {
  const [tooltipRef, { width: tooltipWidth }] = useMeasure()
  const tooltipProps = {
    style: {
      top: chartAreaHeight
    }
  }


  let dateDisplayA = "Day 10"
  let dateDisplayB = "00:00:00"
  if (nearestActiveDataPoint !== null || activeTime !== null) {
    let time
    if (nearestActiveDataPoint !== null) {
      time = nearestActiveDataPoint.chartDate
    } else {
      time = activeTime
    }
    dateDisplayA = FormatTime(time, "Day ~D+")
    dateDisplayB = FormatTime(time, "~HH:~MM:~SS")

    const position = calculatePositionFromTime(time)
    let chartPositionX = position
    if (position - (tooltipWidth / 2) < 0) {
      chartPositionX = (tooltipWidth / 2)
    }
    if (position + (tooltipWidth / 2) > chartAreaWidth) {
      chartPositionX = chartAreaWidth - (tooltipWidth / 2)
    }
    tooltipProps.style.left = chartPositionX
  } else {
    tooltipProps.style.visibility = "hidden"
  }


  return (<>
    <div className="GrowAnalytics-Charting-MainChart-ActiveTimeLabel" ref={tooltipRef} {...tooltipProps}>
      <div className="GrowAnalytics-Charting-MainChart-ActiveTimeLabel-Content">
        <span>{dateDisplayA}</span>
        <span>{dateDisplayB}</span>
      </div>
    </div>
  </>)
}




export const GrowAnalyticsPage_OverviewChartMainChartSubselection = ({
  calculatePositionFromTime, calculateTimeFromPosition, overviewChartArea,
  getMainChartVisibleRange, mainChartArea,
  updateMainChartVisibleRange
}) => {

  const mainChartVisibleRange = getMainChartVisibleRange()

  const subselectionRef = React.useRef(null)

  const [draggingPointerId, SetDraggingPointerId] = React.useState(null)
  const [isDragging, SetIsDragging] = React.useState(null)
  const [draggingOriginalDates, SetDraggingOriginalDates] = React.useState(null)
  const [draggingOriginalPosition, SetDraggingOriginalPosition] = React.useState({ x: 0, y: 0 })
  const beginDragStart = (e) => {
    e.stopPropagation()

    SetIsDragging("start")
    SetDraggingOriginalPosition({ top: e.clientY, left: e.clientX })
    if (subselectionRef.current !== undefined && subselectionRef.current.setPointerCapture) {
      SetDraggingPointerId(e.pointerId)
      subselectionRef.current.setPointerCapture(e.pointerId);
    }
  }


  const beginDragEnd = (e) => {
    e.stopPropagation()

    SetIsDragging("end")
    SetDraggingOriginalPosition({ top: e.clientY, left: e.clientX })
    if (subselectionRef.current !== undefined && subselectionRef.current.setPointerCapture) {
      SetDraggingPointerId(e.pointerId)
      subselectionRef.current.setPointerCapture(e.pointerId);
    }
  }

  const beginDragFull = (e) => {
    e.stopPropagation()

    SetIsDragging("full")
    SetDraggingOriginalPosition({ top: e.clientY, left: e.clientX })
    SetDraggingOriginalDates(mainChartVisibleRange)
    if (subselectionRef.current !== undefined && subselectionRef.current.setPointerCapture) {
      SetDraggingPointerId(e.pointerId)
      subselectionRef.current.setPointerCapture(e.pointerId);
    }

  }


  const subselectionPointerMove = (e) => {
    const pointerPosition = { top: e.clientY, left: e.clientX }
    if (isDragging === "start") {
      let newStart = calculateTimeFromPosition(pointerPosition.left - mainChartArea.left)
      if (newStart > mainChartVisibleRange.end) {
        newStart = mainChartVisibleRange.end - 1000 * 60
      }
      updateMainChartVisibleRange({ start: newStart, end: mainChartVisibleRange.end })
    } else if (isDragging === "end") {
      let newEnd = calculateTimeFromPosition(pointerPosition.left - mainChartArea.left)
      if (newEnd < mainChartVisibleRange.start) {
        newEnd = mainChartVisibleRange.start + 1000 * 60
      }
      updateMainChartVisibleRange({ start: mainChartVisibleRange.start, end: newEnd })
    } else if (isDragging === "full") {
      const offsetPosition = { top: pointerPosition.top - draggingOriginalPosition.top, left: pointerPosition.left - draggingOriginalPosition.left }

      const existingStartDatePosition = calculatePositionFromTime(draggingOriginalDates.start)
      const existingEndDatePosition = calculatePositionFromTime(draggingOriginalDates.end)
      const existingDateDelta = draggingOriginalDates.end - draggingOriginalDates.start

      if (existingStartDatePosition + offsetPosition.left < 0) {
        const newStartDate = calculateTimeFromPosition(0)
        updateMainChartVisibleRange({ start: newStartDate, end: newStartDate + existingDateDelta })
      } else if (existingEndDatePosition + offsetPosition.left > mainChartArea.width) {
        const newEndDate = calculateTimeFromPosition(mainChartArea.width)
        updateMainChartVisibleRange({ start: newEndDate - existingDateDelta, end: newEndDate })
      } else {
        updateMainChartVisibleRange({ start: calculateTimeFromPosition(existingStartDatePosition + offsetPosition.left), end: calculateTimeFromPosition(existingEndDatePosition + offsetPosition.left) })
      }
    }
  }
  const subselectionPointerUp = () => {
    if (isDragging) {
      SetIsDragging(null)
      if (subselectionRef.current !== undefined && subselectionRef.current.releasePointerCapture && subselectionRef !== null) {
        subselectionRef.current.releasePointerCapture(draggingPointerId);
      }
    }
  }



  const posX1 = calculatePositionFromTime(mainChartVisibleRange.start)
  const posX2 = calculatePositionFromTime(mainChartVisibleRange.end)

  let contentProps = {
    style: {
      left: posX1 + 1,
      width: posX2 - posX1 - 2,
      height: overviewChartArea.height
    }
  }
  let preStartOverlayProps = {
    style: {
      right: posX1,
      width: posX1,
      height: overviewChartArea.height
    }
  }
  let postEndOverlayProps = {
    style: {
      left: posX2,
      width: overviewChartArea.width - posX2,
      height: overviewChartArea.height
    }
  }


  //const draggersInside = posX2 - posX1 > 20

  return (<>
    <div className="GrowZones-Charts-OverviewChart-Subselection-Wrapper"
      ref={subselectionRef}
      onPointerMove={subselectionPointerMove}
      onPointerUp={subselectionPointerUp}>

      <div className="GrowZones-Charts-OverviewChart-Subselection-Container">
        <div className="GrowZones-Charts-OverviewChart-Subselection-PreStartOverlay" {...preStartOverlayProps}></div>
        <div className={"GrowZones-Charts-OverviewChart-Subselection-Content GrowZones-Charts-OverviewChart-Subselection-Content-Draggable"}
          onPointerDown={beginDragFull}
          {...contentProps}>
          <div className="GrowZones-Charts-OverviewChart-Subselection-LeftGrabber"
            onPointerDown={beginDragStart}>
            <div></div>
          </div>
          <div className="GrowZones-Charts-OverviewChart-Subselection-RightGrabber"
            onPointerDown={beginDragEnd}>
            <div></div>
          </div>
        </div>
        <div className="GrowZones-Charts-OverviewChart-Subselection-PostEndOverlay" {...postEndOverlayProps}></div>
      </div>
    </div>

  </>)

}

