import './GrowRacksPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';


import { useDispatch, useSelector } from 'react-redux';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeActiveIssuesById, selectBladeControlDeviceConnectedState, selectBladeDisplayNameById, selectBladeRuntimeInformation, selectBladeSideTypesById, selectBladeUniqueConfigurationByName, setBladeRuntimeProperty } from '../../../redux/entities/service/Blade';
import CircularGauge from '../../../components/CircularGauge';
import { GridTile } from '../../../components/GridTile';
import NumberInput from '../../../components/input/NumberInput';
import Switch from '../../../components/Switch';
import { RoundToNearest, convertToBool, parseBool } from '../../../helpers';
import Button from '../../../components/Button';
import { Play, PlusAlt, RSSI } from '../../../assets/icons/Icons';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow';
import { useMediaQuery } from 'react-responsive';
import BladeLightsManager from './BladeLightsManager';
import BladeIssuesPopup from './BladeIssuesPopup';
import { selectAllAccountPrivileges } from '../../../redux/entities/Account';
import { TabControl, TabControlTab } from '../../../components/TabControl';
import DropDownInput from '../../../components/input/DropDownInput';
import BladeInstance_StatusContent from './BladeInstance_StatusContent';






const BladeInstance = ({ bladeId }) => {

  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });





  const dispatch = useDispatch()

  const [managingLights, SetManagingLights] = React.useState(false)
  const manageLightsClicked = () => {
    SetManagingLights(true)
  }
  const closeManagingLights = () => {
    SetManagingLights(false)
  }

  const [viewingIssues, SetViewingIssues] = React.useState(false)
  const issuesClicked = () => {
    SetViewingIssues(true)
  }
  const closeViewBladeIssues = () => {
    SetViewingIssues(false)
  }


  const accountPrivileges = useSelector((state) => selectAllAccountPrivileges(state), _.isEqual)
  const accountHasServicingPriviliges = (accountPrivileges["servicing"] !== undefined)

  const bladeConfigurationMaps = useSelector(selectAllBladeConfigurationMaps, _.isEqual)

  const bladeDisplayName = useSelector((state) => selectBladeDisplayNameById(state, bladeId), _.isEqual)
  const isBladeOnline = useSelector((state) => selectBladeControlDeviceConnectedState(state, bladeId), _.isEqual)


  const isRackPaused = false

  const [inServiceModeRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "service_mode"), _.isEqual)
  const inServiceMode = accountHasServicingPriviliges ? parseBool(inServiceModeRuntimeInformation) : false
 
  const auxALiquidSystemActiveConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "AuxALiquidSystemInstalled"), _.isEqual)
  const auxBLiquidSystemActiveConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "AuxBLiquidSystemInstalled"), _.isEqual)
  const isAuxAPumpingSystemActive = parseBool(auxALiquidSystemActiveConfiguration ?? false)
  const isAuxBPumpingSystemActive = parseBool(auxBLiquidSystemActiveConfiguration ?? false)

  const bladeSideTypes = useSelector((state) => selectBladeSideTypesById(state, bladeId), _.isEqual)

  let subtitle = <></>
  if (bladeSideTypes.dual !== null) {
    switch (bladeSideTypes.dual) {
      case "vine_crop":
        subtitle = <>Dual Vine Crop</>
        break
      default:
        subtitle = <></>
        break
    }
  } else {
    let leftSide = <>Nothing</>
    let rightSide = <>Nothing</>
    if (bladeSideTypes.environment) {
      switch (bladeSideTypes.environment) {
        case "standard_nursery":
          leftSide = <>Nursery</>
          break
        case "standard_grow_out_env":
          leftSide = <>Environment</>
          break

        default:
          leftSide = <>Nothing</>
          break
      }
    }
    if (bladeSideTypes.grow) {
      switch (bladeSideTypes.grow) {
        case "standard_grow_out":
          rightSide = <>Grow Boards</>
          break
        case "vine_crop":
          rightSide = <>Vine Crop</>
          break
        default:
          rightSide = <>Nothing</>
          break
      }
    }

    subtitle = <>{<span className="GrowRacksStatus-VerticalRackGroup-Rack-Side">{leftSide}</span>}<span style={{ fontStyle: "normal" }}>|</span><span className="GrowRacksStatus-VerticalRackGroup-Rack-Side">{rightSide}</span></>
  }

  let additionalFunctions = [
    { key: "manage_lights", label: "Manage Lights", callback: manageLightsClicked },
    { key: "rename", label: "Rename Rack" },
    { key: "move", label: "Move Rack" },
    { key: "change_sides", label: "Change Sides" }]

 

  if (accountHasServicingPriviliges) {
    if (!inServiceMode) {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn On Service Mode", status: "Critical", callback: () => {
            dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "service_mode": 1 } }))
          }
        },
      ])
    } else {
      additionalFunctions.push(...[
        {
          key: "activate_service_mode", label: "Turn Off Service Mode", status: "Critical", callback: () => {
            dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "service_mode": 0 } }))
          }
        },
      ])
    }
  }

  additionalFunctions.push(...[
    { key: "delete", label: "Delete", status: "Critical" },
  ])


  let secondaryHeaderContent = null
  if (!isMobile) {
    secondaryHeaderContent = [
      {
        key: "pause_state", content:
          <div className="FlexContent-H-10">
            {isRackPaused &&
              <div className="FlexContent-H-20 FlexContent-Center">
                <span className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-State">Paused 16:34</span>

                <div className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-AddTimeButton"><span>Add Time</span></div>
              </div>
            }
            {/*<div className="FlexContent-H-10">
              {isRackPaused && <>
                <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"}/>}/>
              </>}
              {!isRackPaused &&
                <Button status={"Small-IconAction"} state={false} content={<Pause/>}/>
              }
            </div>*/}
          </div>
      },
      { key: "rssi", content: <RSSI /> }
    ]
  } else {
    secondaryHeaderContent = <><RSSI /></>
  }

  let extendedHeaderContent = null
  if (isMobile && isRackPaused) {
    extendedHeaderContent = {
      content: <>
        <div className="FlexContent-H-10">
          <div className="FlexContent-H-10 FlexContent-Center">
            <div className="GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-AddTimeButton"><span>Paused 16:34</span><PlusAlt /></div>
          </div>
          <div className="FlexContent-H-10">
            <Button status={"Small-IconAction"} state={true} content={<Play fill={"#2E72D2"} />} />
          </div>
        </div>
      </>
    }
  } else {

  }


  const bladeActiveIssues = useSelector((state) => selectBladeActiveIssuesById(state, bladeId), _.isEqual)
 


  const [expandedContentPage, SetExpandedContentPage] = React.useState("status")
  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }

  return (<>
    <RowGroupRow
      key={bladeId}
      title={bladeDisplayName}
      hasFlexCell={true}
      subtitle={<div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{subtitle}</div>}
      showStatusIndicator={true}
      status={isBladeOnline ? "Connected" : "Not Connected"}
      statusColor={isBladeOnline ? "#4DBE3B" : "#f00"}
      hasIssues={true}
      numberOfIssues={Object.entries(bladeActiveIssues).length}
      issuesClicked={issuesClicked}
      customRowPadding={0}
      isExpandable={true}
      hasAdditionalFunctions={true}
      secondaryContent={secondaryHeaderContent}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={additionalFunctions}
      renderContentOnlyWhenOpen={true}
      content={<>
        <div style={{ display: "flex", flexDirection: "column", border: "1px solid #DDE3EB", gap: 0, borderRadius: 4, backgroundColor: "#FFF" }}>
          <div style={{ padding: "12px 20px 0px 20px" }}>
            {!isMobile && <>
              <TabControl
                onTabSelected={tabSelected}
                tabLocation={"flex"}
                tabs={[
                  new TabControlTab("Status", "status", "", expandedContentPage === "status"),
                  new TabControlTab("Configure", "configure", "", expandedContentPage === "configure"),
                ]}
              />
            </>}
            {isMobile && <>
              <DropDownInput
                status="Neutral"
                value={expandedContentPage}
                options={[
                  { key: 'status', label: 'Status' },
                  { key: 'configure', label: 'Configure' },
                ]}
                optionsKey="key"
                onSelectionChange={(action) => {
                  SetExpandedContentPage(action)
                }} />
            </>}
          </div>

          {expandedContentPage === "status" && <>
            <BladeInstance_StatusContent bladeId={bladeId} />
          </>}

          {expandedContentPage === "configure" && <>
            Configure``
          </>}
        </div>
      </>} />
    {managingLights && <BladeLightsManager bladeId={bladeId} closeCallback={closeManagingLights} />}
    {viewingIssues && <BladeIssuesPopup bladeId={bladeId} closeCallback={closeViewBladeIssues} />}
  </>)
}

export default BladeInstance