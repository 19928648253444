import '../GrowZonesPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalOptions, Pause, Play } from '../../../assets/icons/Icons';
import DropDownButton from '../../../components/DropDownButton';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeIdsForBladeZoneUID, selectBladeLiveDataByComponentInfo, selectBladeLowerNurseryLiquidSystemStatus, selectBladeRuntimeInformation, selectBladeUniqueConfigurationByName, selectBladeZoneRuntimeInformation, selectBladeZoneTypeByUID, setBladeRuntimeProperty, setBladeZoneRuntimeProperty } from '../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';


const GrowZone_StatusContent_NurseryLowerWaterCell = ({ zoneUID }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch()

  
  const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  
  const waterReservoirBladeId = (zoneType === "nursery" ? bladeIds["nursery"] : bladeIds["environment"])
  
  const liquidStatus = useSelector((state) => selectBladeLowerNurseryLiquidSystemStatus(state, waterReservoirBladeId), _.isEqual)

  const [liquidControlStatus] = useSelector((state) => selectBladeRuntimeInformation(state, bladeIds["nursery"], "aux_b_liquid_control_status"))
  const isLiquidPaused = liquidControlStatus !== "active"
  const bladeZoneResumingOn = useSelector((state) => selectBladeZoneRuntimeInformation(state, zoneUID, "resume_controls_on"), _.isEqual)


  const reservoirMinConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, waterReservoirBladeId, "AuxBReservoirLowLiquidVolume"), _.isEqual)
  const reservoirMaxConfiguration = useSelector((state) => selectBladeUniqueConfigurationByName(state, waterReservoirBladeId, "AuxBReservoirFullLiquidVolume"), _.isEqual)
  const reservoirMin = reservoirMinConfiguration ?? 0
  const reservoirMax = reservoirMaxConfiguration ?? 20


  const [reservoirVolumeUsageComponentInfo, reservoirVolumeComponentInfo, waterECComponentInfo, waterPHComponentInfo, waterDOComponentInfo, waterORPComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "AuxBWaterReservoir", ["water_level", "water_volume", "ec", "ph", "do", "orp"]), _.isEqual)
  const [waterTemperatureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "AuxBReservoirOWTB", ["t"]), _.isEqual)
  const [liquidPressureComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "AuxBLiquidSystemPressureTransducer", ["pressure"]), _.isEqual)
  const [waterECSetpointComponentInfo, waterPHSetpointComponentInfo] 
  = useSelector((state) => selectBladeComponentInfo(state, waterReservoirBladeId, "NurserySetpointMonitor", ["ec-1-sp", "ph-1-sp"]), _.isEqual)

  let reservoirVolume = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, reservoirVolumeComponentInfo), _.isEqual)
  let reservoirVolumeUsage = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, reservoirVolumeUsageComponentInfo), _.isEqual)
  let liquidPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, liquidPressureComponentInfo), _.isEqual)
  let waterTemperature = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterTemperatureComponentInfo), _.isEqual)
  let waterEC = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterECComponentInfo), _.isEqual)
  let waterECSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterECSetpointComponentInfo), _.isEqual)
  let waterPH = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterPHComponentInfo), _.isEqual)
  let waterPHSetpoint = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterPHSetpointComponentInfo), _.isEqual)
  let waterDO = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterDOComponentInfo), _.isEqual)
  let waterORP = useSelector((state) => selectBladeLiveDataByComponentInfo(state, waterReservoirBladeId, waterORPComponentInfo), _.isEqual)


  let dataListItems = [
    {
      type: "large_bar", label: "Reservoir Volume", value: reservoirVolumeUsage.value, valueToDisplay: reservoirVolume.value, suffix: "L", min: 0, max: 100, status: "normal"
    },
    {
      type: "setpoint_bar", label: "EC", value: waterEC.value, setpoint: waterECSetpoint.value, suffix: "mS/m", range: 500, status: "normal"
    },
    {
      type: "setpoint_bar", label: "pH", value: waterPH.value, setpoint: waterPHSetpoint.value, suffix: "", range: 2, status: "normal"
    },
    {
      label: "Pressure", value: liquidPressure.value, suffix: "psi", visualization: {
        type: "bar", info: { usesRange: true, min: 0, max: 130, status: "normal" }
      }
    },
    {
      label: "Temperature", value: waterTemperature.value, suffix: "°C"
    },
    {
      label: "DO", value: waterDO.value, suffix: "ppm",
    },
    {
      label: "ORP", value: waterORP.value, suffix: "mV",
    },
  ]


  let additionalFunctions = [
    {
      key: "fill_reservoir", label: "Water Fill", callback: () => {
        dispatch(setBladeRuntimeProperty({ waterReservoirBladeId: waterReservoirBladeId, properties: { "aux_b_reservoir_requires_water_fill": 1 } }))
      }
    },
    {
      key: "drain_reservoir", label: "Water Drain", callback: () => {
        dispatch(setBladeRuntimeProperty({ waterReservoirBladeId: waterReservoirBladeId, properties: { "aux_b_reservoir_requires_drain": 1 } }))
      }
    },
    {
      key: "mark_for_dose", label: "Request Dose", callback: () => {
        dispatch(setBladeRuntimeProperty({ waterReservoirBladeId: waterReservoirBladeId, properties: { "aux_b_reservoir_requires_dose": 1 } }))
      }
    },
  ]

  const [liquidSystemDoseLockedOutRuntimeInfo] = useSelector((state) => selectBladeRuntimeInformation(state, waterReservoirBladeId, "aux_b_dose_locked_out"), _.isEqual)
  const isLiquidSystemDoseLockedOut = parseBool(liquidSystemDoseLockedOutRuntimeInfo)
  if (isLiquidSystemDoseLockedOut) {
    additionalFunctions.push(...[
      {
        key: "restore_lockout", label: "Allow Dosing", status: "Critical", callback: () => {
          dispatch(setBladeRuntimeProperty({ waterReservoirBladeId: waterReservoirBladeId, properties: { "aux_b_dose_locked_out": 0 } }))
        }
      },
    ])
  }


  
  const defaultPauseDuration = 6000
  const [pendingBladeToPause, SetPendingBladeToPause] = React.useState(false)
  const pauseLiquidControlClicked = () => {
    //First set the default pause duration if the current pause duration is too short
    const minResumeAt = Math.floor((new Date().getTime() / 1000) + defaultPauseDuration)
    if (bladeZoneResumingOn === undefined || parseFloat(bladeZoneResumingOn) < minResumeAt) {
      dispatch(setBladeZoneRuntimeProperty({
        bladeZoneUID: zoneUID,
        properties: { "resume_controls_on": minResumeAt.toString() },
        callback: (success) => {
          if (success)  {
            SetPendingBladeToPause(true)
          }else {

          }
        }
      }))
    }else {
      SetPendingBladeToPause(true)
    }
  }
  React.useLayoutEffect(() => {
    if (pendingBladeToPause)  {
      SetPendingBladeToPause(false)
      dispatch(setBladeRuntimeProperty({
        bladeId: bladeIds["nursery"],
        properties: { "aux_b_liquid_control_status": "pause" },
        callback: (success) => {
          if (success)  {

          }else {

          }
        }
      }))
    }
  }, [pendingBladeToPause])
  const resumeLiquidControlClicked = () => {
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds["nursery"],
      properties: { "aux_b_liquid_control_status": "resume" },
      callback: (success) => {
        
      }
    }))
  }
 
  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Lower Nursery Liquid</div>
          <div className="DataStatus-Cell-Header-PrimaryContent-StatusChip">{liquidStatus}</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          {isLiquidPaused && <>
            <Button status={"Primary-Inverted"} size={"Small"} content={<Play fill={"#2E72D2"} />} onClick={resumeLiquidControlClicked} disabled={!isLiquidPaused} />
          </>}
          {!isLiquidPaused &&
            <Button status={"Small-IconAction"} size={"Small"} content={<Pause />} onClick={pauseLiquidControlClicked} disabled={isLiquidPaused} />
          }
          <DropDownButton content={<AdditionalOptions />} options={additionalFunctions} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default GrowZone_StatusContent_NurseryLowerWaterCell