
import './GrowPlansPage.scss';

import _ from 'underscore';
import React from 'react'
import {StandardList, StandardListRow} from '../../../components/StandardList/StandardList.js'

import { Routes, Route, Navigate, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { selectAllRecipes, getAllRecipes, getRecipeById, MaintainRecipeVersions } from '../../../redux/entities/Recipes'
import { selectAllGrowPlans, getAllGrowPlans, createGrowPlan, InitialLoadAllGrowPlans, selectAllUsableGrowPlans} from '../../../redux/entities/GrowPlans'
import { RaftTypes } from '../../../model_components/RaftType';
import { FormatDate, FormatTime } from '../../../helpers'

import Badge from '../../../components/Badge.js'
import Button from '../../../components/Button.js'

import {Raft} from '../../../model_components/RaftType';
import {FaTrashAlt} from 'react-icons/fa'
import { getRaftTypeById, MaintainRaftTypes, selectAllRaftTypes } from '../../../redux/entities/RaftTypes.js';
import { useMediaQuery } from 'react-responsive';
import RowGroup from '../../../components/RowGroup/RowGroup.js';
import RowGroupRow from '../../../components/RowGroup/RowGroupRow.js';
import CreateGrowPlanWalkthrough from './CreateGrowPlanWalkthrough/CreateGrowPlanWalkthrough';
import { MaintainActivePlantTypes, selectAllPlantTypes, selectAllPlantVarieties } from '../../../redux/entities/PlantTypes.js';
import { GetActiveAccountId } from '../../Account/Common.js';


const GrowPlansPage = ({pageHeaderHelper, pageHeaderHelperSetup}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  React.useLayoutEffect(() => {
    pageHeaderHelper.current.Reset() 
    pageHeaderHelperSetup()
    pageHeaderHelper.current.SetTitle("My Repository")
    pageHeaderHelper.current.SetRightSideContent(() => {
      return (
        <>
          <div className="ControlBar_Horizontal">
            <div className="ControlBar_Horizontal-Right">
              <Button content={"Create Grow Plan"} onClick={() => {SetCreatingGrowPlan(true)}}/>
            </div>
          </div>
        </>
      )
    })
  }, [])

  const activeAccountId = useSelector((state) => state.account.id, _.isEqual)
  const allGrowPlans = useSelector((state) => selectAllUsableGrowPlans(state, activeAccountId), _.isEqual)


  const [currentSearchInput, SetSearchInput] = React.useState("")
  const onSearchInput = (value) => {
      SetSearchInput(value)
  }


  const [growPlansList, SetGrowPlansList] = React.useState({})
  React.useEffect(() => {
    let currentGrowPlansList = {}

    let currentGrowPlans = []
    

    if (currentSearchInput === "")  {
        currentGrowPlans = [...allGrowPlans]
    }else {
        currentGrowPlans.push(...allGrowPlans.filter((growPlan) => {
        
        if (growPlan.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1 && currentGrowPlans.indexOf(growPlan) === -1)  {
            return true
        }
        return false
    }))
    }
    
    
    for (let growPlan of currentGrowPlans) {
        //Check if the recipe is in working mode
        currentGrowPlansList[growPlan.id] = {
            data: growPlan,
        }
    }
    
    SetGrowPlansList(currentGrowPlansList)
}, [allGrowPlans, currentSearchInput])




  const [activeRecipes, SetActiveRecipes] = React.useState([])
  const [activeRaftTypeIds, SetActiveRaftTypeIds] = React.useState([])
  const [activePlantTypeIds, SetActivePlantTypeIds] = React.useState([])
  
  React.useEffect(() => {
    let currentActiveRecipes = []
    let currentActiveRaftTypes = []
    let currentActivePlantTypes = []
    for (let growPlan of Object.values(growPlansList)) {
      if (currentActiveRecipes.find((r) => r.id === growPlan.data.recipe_id && r.version === growPlan.data.recipe_version) === undefined) {
        currentActiveRecipes.push({
          id: growPlan.data.recipe_id,
          version: growPlan.data.recipe_version
        })
      }

      for (let plantVariant of growPlan.data.plant_variants)  {
        if (currentActiveRaftTypes.find((rT) => rT === plantVariant.seedling_raft_type_id) === undefined)  {
          currentActiveRaftTypes.push(plantVariant.seedling_raft_type_id)
        }

        if (currentActivePlantTypes.find((pT) => pT === plantVariant.plant_type_id) === undefined)  {
          currentActivePlantTypes.push(plantVariant.plant_type_id)
        }
      }
      if (currentActiveRaftTypes.find((rT) => rT === growPlan.data.grow_out_raft_type_id) === undefined)  {
        currentActiveRaftTypes.push(growPlan.data.grow_out_raft_type_id)
      }
    }
    SetActiveRecipes(currentActiveRecipes)
    SetActiveRaftTypeIds(currentActiveRaftTypes)
    SetActivePlantTypeIds(currentActivePlantTypes)
  }, [growPlansList])


  


  
  
  
  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Plan Name</>, setPosition: (bounds, contentWidth) => {return {left: isMobile ? bounds.left - 30 : bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
      plant: {label: <>Plant</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
      recipe: {label: <>Recipe</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
      yield: {label: <>D&Y</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"start"}}},
      grow_out_raft: {label: <>Grow Out</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"center"}}}  
      }
  }


    

  let navigate = useNavigate(); 
  const planClicked = (plan) => {
    navigate("/repo/plan/" + plan.id);
  }

  const [creatingGrowPlan, SetCreatingGrowPlan] = React.useState(false)
  const closeCreateGrowPlanWalkthrough = () =>  {
    SetCreatingGrowPlan(false)
  }
  const growPlanFinalized = () =>  {
    SetCreatingGrowPlan(false)
  }



  return (
    <>
      <InitialLoadAllGrowPlans/>
      <MaintainRecipeVersions activeRecipes={activeRecipes}/>
      <MaintainRaftTypes activeRaftTypeIds={activeRaftTypeIds}/>
      <MaintainActivePlantTypes activePlantTypeIds={activePlantTypeIds}/>
      <div id="GrowPlansPage">
        <RowGroup
            hasGroupHeader={false}
            hasSettings={false}
            itemsScroll={true}
            isExpandable={false}
            hasColumnHeaders={true}
            columnHeaders={columnHeaders}
            columnSizes={columnSizes}
            hasSubGroups={true}>

            <GrowPlans_GrowPlanRow isPlaceholder={true} columnSizesChanged={(columns) => {
                SetColumnSizes(columns)
            }}/>
            {Object.entries(growPlansList).map(([growPlanIdAsString, growPlanInfo]) => {
                let growPlanId = parseInt(growPlanIdAsString)
                //console.log(growPlanInfo)
                return ( 
                  <GrowPlans_GrowPlanRow 
                    key={growPlanId}
                    growPlanKey={growPlanId} 
                    growPlanInfo={growPlanInfo} 
                    columnSizes={columnSizes}/>
                 )
          })}
        </RowGroup>

        {/*<StandardList 
          isSearchable={true}
          isMultiSelectable={true}
          resourcesAttached={false}
          columns={columns} 
          showHeader={true} 
          rows={growPlansList}/>*/}
      </div>
      {creatingGrowPlan && 
        <CreateGrowPlanWalkthrough closeCallback={closeCreateGrowPlanWalkthrough} completeCallback={growPlanFinalized}/>
      }
    </>
  )
} 

export default GrowPlansPage







const GrowPlans_GrowPlanRow = ({growPlanKey, growPlanInfo, isPlaceholder, isSelected, openTask, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const growPlan = isPlaceholder ? null : growPlanInfo.data

  const allRecipes = useSelector((state) => selectAllRecipes(state))
  let foundRecipe = allRecipes.find((r) => growPlan && r.id === growPlan.recipe_id && r.version === growPlan.recipe_version)

  const allRaftTypes = useSelector((state) => selectAllRaftTypes(state))
  let foundSeedlingRaftType = null
  let numberOfSeedlingPlantSites = "N/A"
  let foundGrowOutRaftType = null
  let numberOfGrowOutPlantSites = "N/A"
  if (growPlan)   {
      foundSeedlingRaftType = allRaftTypes.find((rT) => rT.id === growPlan.seedling_raft_type_id)
      foundGrowOutRaftType = allRaftTypes.find((rT) => rT.id === growPlan.grow_out_raft_type_id)

      if (foundSeedlingRaftType !== undefined)    {
          numberOfSeedlingPlantSites = foundSeedlingRaftType.info["number_of_plant_sites"]
      }

      if (foundGrowOutRaftType !== undefined) {

      }
      numberOfGrowOutPlantSites = growPlan.grow_out_raft_usage
  }

  const allPlantTypes = useSelector((state) => selectAllPlantTypes(state))
  const allPlantVarieties = useSelector((state) => selectAllPlantVarieties(state))
  const isMultiplePlantVariants = isPlaceholder ? false : growPlan.plant_variants.length > 1
  let foundPlantType = []
  let foundPlantVariety = []
  if (growPlan && !isMultiplePlantVariants) {
    foundPlantType = allPlantTypes.find((pT) => pT.id === growPlan.plant_variants[0].plant_type_id)
    foundPlantVariety = allPlantVarieties.find((pV) => pV.id === growPlan.plant_variants[0].plant_variety_id)
  }
  //console.log(foundSeedlingRaftType)


  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "plant", content: <>
      <div className="noselect" style={{fontSize:14}}>
          <div>
              {(!isPlaceholder ? <>
                {isMultiplePlantVariants && <>
                  <div className="Text-SoftTruncate">Multiple Variants</div>                
                </>}
                {!isMultiplePlantVariants && <> 
                  <div className="Text-SoftTruncate">{foundPlantType ? foundPlantType.name : "Unknown"}</div>
                  <div className="Text-SoftTruncate">{foundPlantVariety ? foundPlantVariety.name : "Unknown"}</div>
                </>}
              </> : <>
                <div className="Text-SoftTruncate">Multiple Variants</div>
                <div className="Text-SoftTruncate">Bitter Blue</div>
              </>
          )}</div>
      </div>
    </>
    })
    secondaryHeaderContent.push({
      key: "recipe", content: <>
        <div className="noselect" style={{fontSize:14}}>
          <div>
              {(!isPlaceholder ? <>
                  <div className="Text-SoftTruncate">{foundRecipe !== undefined ? foundRecipe.name : "Unknown Recipe"}</div>
                  <div>{foundRecipe !== undefined && 
                      <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                          <div>v{foundRecipe.version}</div>
                          <div>{FormatDate(new Date(foundRecipe.modified_on), "MM/dd/yyyy")}</div>
                      </div>
                  }</div>
              </> : <>
                  <div>Very long recipe name</div>
                  <div>v100 00/00/0000</div>
              </>
          )}</div>
        </div>
      </>
    })
    //console.log(growPlan)
    secondaryHeaderContent.push({
      key: "yield", content: <>
        <div className="noselect" style={{fontSize:14}}>
          <div>
              {(!isPlaceholder ? <>
                  <div className="Text-SoftTruncate">{foundRecipe !== undefined ? FormatTime(foundRecipe.expected_duration * 1000, "~DDd ~HHh") : "N/A"}</div>
                  <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                      {growPlan.yield_unit_type === "weight" && 
                          <>{growPlan.estimated_yield}kg</>
                      }
                  </div>
              </> : <>
                  <div>100d</div>
                  <div>1000.00kg</div>
              </>
          )}</div>
        </div>
      </>
    })
    
    secondaryHeaderContent.push({
      key: "grow_out_raft", content: <>
        <div className="noselect" style={{fontSize:14}}>
           <div>
              {(!isPlaceholder ? <>
                  <div style={{display: "flex", justifyContent: "center"}}><Raft raftType={{plant_sites: numberOfGrowOutPlantSites}}/></div>
              </> : <>
                  <div style={{whiteSpace: "nowrap"}}>Grow Out Raft</div>
              </>
          )}</div>
        </div>
      </> 
    })
  }

  let title = isPlaceholder ? <>My Amazing Lettuce is the best</> : growPlan.name
  let titleAuxContent = <></>
  
  if (!isPlaceholder)  {  
    if (foundRecipe !== undefined && !foundRecipe.is_active_version)  {    
      titleAuxContent = <Badge fontSize={12} content={"update available"}/>
    }
  }
  let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className="">#GP{growPlan.uid}</div>)
  


  if (!isPlaceholder) {
    if (growPlan.category === "grow") {
      subtitle = "#G" + growPlan.reference_id
     
    }


  }
  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : growPlan.id}
      title={title}
      titleFontSize={14}
      titleAuxContent={titleAuxContent}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      subtitle={<div className="">{subtitle}</div>}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      isExpandable={isMobile}
      hasAdditionalFunctions={false}
      content={<>
        {(() => {
          if (isMobile && !isPlaceholder) {
            return (<>
              <div className="FlexContent-20" style={{padding: "10px 40px 20px 40px"}}>
                <div className="FlexContent-5">
                  <span>Plant</span>
                  <Badge content="To Do"/>
                </div>

                <div className="FlexContent-5">
                  <div className="Text-SoftTruncate">{foundRecipe !== undefined ? foundRecipe.name : "Unknown Recipe"}</div>
                  <div>{foundRecipe !== undefined && 
                      <div style={{display:"flex", gap: 5, color:"#4F5562"}}>
                          <div className="Text-SoftTruncate">v{foundRecipe.version}</div>
                          <div className="Text-SoftTruncate">{FormatDate(new Date(foundRecipe.modified_on), "MM/dd/yyyy")}</div>
                      </div>
                  }</div>
                </div>

                <div className="FlexContent-5">
                  <span>D&Y</span>
                  <span>Stevenson</span>
                </div>

                <div className="FlexContent-5">
                    <Raft raftType={{plant_sites: numberOfSeedlingPlantSites}}/>
                </div>
                <div className="FlexContent-5">
                    <Raft raftType={{plant_sites: numberOfGrowOutPlantSites}}/>
                </div>
              </div>
            </>)
          }
      
        })()}
      </>}
    />
  </>)
}