import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import { getLiveDataItem, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeLiveDataByComponentInfo } from '../../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../../components/DataStatus/DataStatus';



const DataStatus_AuxiliaryCell = ({ bladeId }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const [leftDoorSwitchStateComponentInfo] = useSelector((state) => selectBladeComponentInfo(state, bladeId, "LeftDoorSafetySwitch", ["state"]), _.isEqual)
  const [rightDoorSwitchStateComponentInfo] = useSelector((state) => selectBladeComponentInfo(state, bladeId, "RightDoorSafetySwitch", ["state"]), _.isEqual)
  const leftDoorSwitchState = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, leftDoorSwitchStateComponentInfo), _.isEqual)
  const rightDoorSwitchState = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, rightDoorSwitchStateComponentInfo), _.isEqual)

  
  let dataListItems = [
    {
      label: "Left Door State", value: leftDoorSwitchState.value === 1 ? "Closed" : "Open",
    },
    {
      label: "Right Door State", value: rightDoorSwitchState.value === 1 ? "Closed" : "Open",
    },
  ]


  return (<>
    <div className="DataStatus-Cell">
      <div className="DataStatus-Cell-Header-Container">
        <div className="DataStatus-Cell-Header-PrimaryContent">
          <div className="Text-H14">Auxiliary</div>
        </div>
        <div className="DataStatus-Cell-Header-AuxContent">
          <DropDownButton content={<AdditionalOptions />} status={"Neutral"} />
        </div>
      </div>
      <div className="DataStatus-Cell-Content-Container">
        <DataStatus_DataList items={dataListItems} />
      </div>
    </div>
  </>)
}

export default DataStatus_AuxiliaryCell