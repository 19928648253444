import '../GrowZonesPage.scss';
import variables from '../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalOptions, Pause, Play } from '../../../assets/icons/Icons';
import DropDownButton from '../../../components/DropDownButton';
import { getLiveDataItem, getUniqueConfigurationByName, selectAllBladeConfigurationMaps, selectBladeComponentInfo, selectBladeIdsForBladeZoneUID, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, selectBladeUniqueConfigurationByName, selectBladeZoneRuntimeInformation, selectBladeZoneTypeByUID, setBladeRuntimeProperty, setBladeZoneRuntimeProperty } from '../../../redux/entities/service/Blade';
import { DataStatus_DataList } from '../../../components/DataStatus/DataStatus';
import { parseBool } from '../../../helpers';
import Button from '../../../components/Button';



const GrowZone_StatusContent_LightsCell = ({ zoneUID }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const dispatch = useDispatch()
    
    const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
    const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)

    
    const lightsComponentBladeId = (zoneType === "nursery" ? bladeIds["nursery"] : bladeIds["environment"])

    const [lightsControlStatus] = useSelector((state) => selectBladeRuntimeInformation(state, lightsComponentBladeId, "lights_control_status"))
    const isLightingPaused = lightsControlStatus !== "active"
    const bladeZoneResumingOn = useSelector((state) => selectBladeZoneRuntimeInformation(state, zoneUID, "resume_controls_on"), _.isEqual)
  

  
      
    const [totalIntensityComponentInfo,
      totalIntensityZ1ComponentInfo, totalIntensityZ2ComponentInfo, totalIntensityZ3ComponentInfo, totalIntensityZ4ComponentInfo, 
      totalIntensityZ5ComponentInfo, totalIntensityZ6ComponentInfo, totalIntensityZ7ComponentInfo] 
    = useSelector((state) => selectBladeComponentInfo(state, lightsComponentBladeId, "LightingController", ["int-a-all", "int-a-1", "int-a-2", "int-a-3", "int-a-4", "int-a-5", "int-a-6", "int-a-7"]), _.isEqual)

    const totalIntensity = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityComponentInfo), _.isEqual)
    const totalIntensityZ1 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityZ1ComponentInfo), _.isEqual)
    const totalIntensityZ2 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityZ2ComponentInfo), _.isEqual)
    const totalIntensityZ3 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityZ3ComponentInfo), _.isEqual)
    const totalIntensityZ4 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityZ4ComponentInfo), _.isEqual)
    const totalIntensityZ5 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityZ5ComponentInfo), _.isEqual)
    const totalIntensityZ6 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityZ6ComponentInfo), _.isEqual)
    const totalIntensityZ7 = useSelector((state) => selectBladeLiveDataByComponentInfo(state, lightsComponentBladeId, totalIntensityZ7ComponentInfo), _.isEqual)

    let dataListItems = []
    if (zoneType === "nursery") {
      dataListItems.push(...[
        {label: "Total Int (Z1)", value: totalIntensityZ1.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z2)", value: totalIntensityZ2.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z3)", value: totalIntensityZ3.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z4)", value: totalIntensityZ4.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z5)", value: totalIntensityZ5.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z6)", value: totalIntensityZ6.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        {label: "Total Int (Z7)", value: totalIntensityZ7.value, suffix: "umol/ms/s", visualization: {
          type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
        }},
        
      ])
    }else {
      dataListItems.push({label: "Total Int", value: totalIntensity.value, suffix: "umol/ms/s", visualization: {
        type: "bar", info: {usesRange: true, min: 0, max: 500, status: "normal"}
      }})
    }


    

  
  const defaultPauseDuration = 6000
  const [pendingBladeToPause, SetPendingBladeToPause] = React.useState(false)
  const pauseLightingControlClicked = () => {
    //First set the default pause duration if the current pause duration is too short
    const minResumeAt = Math.floor((new Date().getTime() / 1000) + defaultPauseDuration)
    if (bladeZoneResumingOn === undefined || parseFloat(bladeZoneResumingOn) < minResumeAt) {
      dispatch(setBladeZoneRuntimeProperty({
        bladeZoneUID: zoneUID,
        properties: { "resume_controls_on": minResumeAt.toString() },
        callback: (success) => {
          if (success)  {
            SetPendingBladeToPause(true)
          }else {

          }
        }
      }))
    }else {
      SetPendingBladeToPause(true)
    }
  }
  React.useLayoutEffect(() => {
    if (pendingBladeToPause)  {
      SetPendingBladeToPause(false)
      dispatch(setBladeRuntimeProperty({
        bladeId: lightsComponentBladeId,
        properties: { "lights_control_status": "pause" },
        callback: (success) => {
          if (success)  {

          }else {

          }
        }
      }))
    }
  }, [pendingBladeToPause])
  const resumeLightingControlClicked = () => {
    dispatch(setBladeRuntimeProperty({
      bladeId: lightsComponentBladeId,
      properties: { "lights_control_status": "resume" },
      callback: (success) => {
        
      }
    }))
  }

    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">Lighting</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            {isLightingPaused && <>
              <Button status={"Primary-Inverted"} size={"Small"} content={<Play fill={"#2E72D2"} />} onClick={resumeLightingControlClicked} disabled={!isLightingPaused} />
            </>}
            {!isLightingPaused &&
              <Button status={"Small-IconAction"} size={"Small"} content={<Pause />} onClick={pauseLightingControlClicked} disabled={isLightingPaused} />
            }
            <DropDownButton content={<AdditionalOptions/>} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <DataStatus_DataList items={dataListItems}/>
        </div>
      </div>
    </>)
  }
  
  export default GrowZone_StatusContent_LightsCell