
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRaftTypes, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import DropDownInput from '../../../../components/input/DropDownInput';
import { selectAllRecipes } from '../../../../redux/entities/Recipes';
import NumberInput from '../../../../components/input/NumberInput';
  

const CreateGrowPlan_SelectGrowOutRaftTypeWalkthroughStep = ({
        selectedRecipeId,
        selectedRecipeVersion,    
        initialSelectedGrowOutRaftType,
        initialSelectedGrowOutRaftUsage,
        growOutRaftTypeConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const dispatch = useDispatch()
    const allRecipes = useSelector(state => selectAllRecipes(state))
    const allRaftTypes = useSelector(state => selectAllRaftTypes(state))
    /*const haveAllRaftTypes = useSelector(state => state.raftTypes.haveAllRaftTypes)
    React.useEffect(() => {
        if (!haveAllRaftTypes)  {
            dispatch(getAllRaftTypes({}))
        }
    }, [haveAllRaftTypes])*/

    const [selectedGrowOutRaftType, SetSelectedGrowOutRaftType] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedGrowOutRaftType !== undefined)  {
            SetSelectedGrowOutRaftType(initialSelectedGrowOutRaftType)
        }
    }, [initialSelectedGrowOutRaftType])
    const growOutRaftTypeSelected = (growOutRaftType) => {
        SetSelectedGrowOutRaftType(growOutRaftType)
        let activeRaftType = allRaftTypes.find((rT) => rT.id === growOutRaftType)
        SetSelectedGrowOutRaftUsage(activeRaftType.info.number_of_plant_sites)
    }



    const [selectedGrowOutRaftUsage, SetSelectedGrowOutRaftUsage] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedGrowOutRaftUsage !== undefined)  {
            SetSelectedGrowOutRaftUsage(initialSelectedGrowOutRaftUsage)
        }
    }, [initialSelectedGrowOutRaftUsage])




    const backClicked = () => {
        transitionToPreviousStep(["select_recipe", null])
    }
    const nextClicked = () =>   {
        growOutRaftTypeConfirmedCallback({
            growOutRaftType: selectedGrowOutRaftType,
            growOutRaftUsage: selectedGrowOutRaftUsage
        })
        transitionToNextStep(["select_grow_plan_name", null])
    }
 




    let activeRecipe = allRecipes.find((r) => r.id === selectedRecipeId && r.version === selectedRecipeVersion)
    let activeRaftType = allRaftTypes.find((rT) => rT.id === selectedGrowOutRaftType)

    let growOutRaftOptions = []
    let raftUsageType = ""
    if (activeRecipe !== undefined) {
        if (activeRecipe.grow_out_type === "grow_boards")    {
            raftUsageType = "usage_per_raft"
            for (let raft of allRaftTypes.filter((rT) => rT.application_type === "grow_board"))   {
                growOutRaftOptions.push({value: raft.id, label: raft.name})
            }
        }else if (activeRecipe.grow_out_type === "berry_troughs")    {
            raftUsageType = "number_of_plant_sites_per_raft"
            for (let raft of allRaftTypes.filter((rT) => rT.application_type === "grow_bag"))   {
                growOutRaftOptions.push({value: raft.id, label: raft.name})
            }
        }
    }

    


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"} style={{minWidth: isMobile ? 100 : isTablet ? 300 : 500}}>
                    <div className="FlexContent-20" style={{padding: isMobile ? "" : ""}}>
                        <div className="FlexContent-10">
                            <div className="Text-S14">Grow Out Raft Type</div>
                            <DropDownInput placeholder={"Select Raft Type"} options={growOutRaftOptions} value={selectedGrowOutRaftType} onSelectionChange={growOutRaftTypeSelected}/>
                        </div>
                        {raftUsageType === "usage_per_raft" && <>
                            <div className="FlexContent-10">
                                <div className="Text-S14">Raft Usage</div>
                                {selectedGrowOutRaftUsage === null && 
                                    <NumberInput value={0} disabled={true}/>
                                }
                                {selectedGrowOutRaftUsage !== null && activeRaftType !== undefined && 
                                    <NumberInput value={selectedGrowOutRaftUsage} min={1} max={activeRaftType.info.number_of_plant_sites} suffix={"out of " + activeRaftType.info.number_of_plant_sites.toString()}/>
                                }
                            </div>
                        </>}
                        {raftUsageType === "number_of_plant_sites_per_raft" && <>
                            <div className="FlexContent-10">
                                <div className="Text-S14">Raft Usage</div>
                                {selectedGrowOutRaftUsage === null && 
                                    <NumberInput value={0} disabled={true}/>
                                }
                                {selectedGrowOutRaftUsage !== null && activeRaftType !== undefined && 
                                    <NumberInput value={selectedGrowOutRaftUsage} min={1} max={activeRaftType.info.number_of_plant_sites} suffix={"Plant Sites"}/>
                                }
                            </div>
                        </>}
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Next"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={selectedGrowOutRaftType === null}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_SelectGrowOutRaftTypeWalkthroughStep
