import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import { GenericLeaf } from '../../../../assets/icons/Icons';
import { KeypadInput_DisplayField, KeypadInput_Keypad } from '../../../../components/input/KeypadInput';


const HarvestTask_ContainerWeightWalkthroughStep = ({
        allPlantVarieties,
        activeGrow,
        activeGrowPlan,
        harvestingZoneUID,
        containerOption,
        containerContents,
        initialContainerWeight,
        initialContainerPartialWeight,
        partialContentsIndex,
        containerWeightConfirmedCallback,
        containerContentsSubstepCallback,
        containers,
        containersListConfirmedCallback,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["container_contents", null])
    }
    const nextClicked = () => {
        if (containerContents === "mix" && containerOption === "individual")    {
            containerWeightConfirmedCallback({containerWeight: containerWeight * (100 / activeGrowPlan.plant_variants[0].ratio)})
            containerContentsSubstepCallback(1)
            transitionToNextStep(["container_weight_substep", null])
        }else {
            //containerWeightConfirmedCallback({containerWeight: containerWeight})
            containersListConfirmedCallback({containers: [...containers, {
                weight: containerWeight,
                contents: containerContents,
                option: containerOption,
                number_of: 1
            }]})
            transitionToNextStep(["container_list", null])
        }
    }


    const [containerWeight, SetContainerWeight] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialContainerWeight !== null)    {
            SetContainerWeight(initialContainerWeight * (activeGrowPlan.plant_variants[0].ratio / 100))
        }
    }, [initialContainerWeight])


    const isMix = containerContents === "mix"


    const keypadInputFieldRef = React.useRef()
    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    {containerOption === "known_weight" && <>
                        Weight of the container
                    </>}
                    {containerOption === "individual" && <>
                        {isMix && <>
                            {(() => {
                                if (activeGrowPlan !== undefined)   {
                                    let firstPlantVariety = allPlantVarieties.find((pT) => pT.id === activeGrowPlan.plant_variants[0].plant_variety_id)
                                    return <>
                                        How much {firstPlantVariety !== undefined ? firstPlantVariety.name : "of the first plant variant"} did you add
                                    </>
                                }
                            })()}
                        </>}
                        {(!isMix) && <>
                            {(() => {
                                if (activeGrowPlan !== undefined)   {
                                    let foundPlantVariety = allPlantVarieties.find((pT) => pT.id === containerContents)
                                    return <>
                                        How much {foundPlantVariety !== undefined ? foundPlantVariety.name : "of this variant"} did you add
                                    </>
                                }
                            })()}
                        </>}
                    </>}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={isMobile ? "FlexContent-20" : "FlexContent-H-40"} style={{padding: isMobile ? "20px 0px" : "40px 0px", flex: 1}}>
                    <div className="FlexContent-20 FlexContent-Center" style={{flex: 1}}>
                        <KeypadInput_DisplayField
                            value={containerWeight} 
                            resolution={0.1} 
                            maxLength={8} 
                            suffix={"kg"} 
                            active={true} 
                            verticalPadding={14} 
                            inputFieldRefCb={(ref) => {keypadInputFieldRef.current = ref}}/>
                        {containerContents === "mix" && <>
                            <div className="Text-S14">Ratio: {activeGrowPlan.plant_variants[0].ratio}%</div>
                        </>}
                    </div>
                    <div className="FlexContent" style={{flex: 1}}>
                        <KeypadInput_Keypad
                            value={containerWeight} 
                            buttonSize={"flex"}
                            inputFieldRef={keypadInputFieldRef.current}
                            onChange={(value) => {SetContainerWeight(parseFloat(value))}}/>
                    </div>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Next"} onClick={nextClicked} size={"Flex50"} disabled={containerWeight === null || containerWeight === 0}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ContainerWeightWalkthroughStep